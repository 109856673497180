<template>
  <v-container class="pa-0">
    <v-container class="px-0">
      <v-stepper v-model="stepStage" flat>
        <v-stepper-header>
          <span v-for="(item, i) in formStages" :key="i">
            <v-stepper-step :complete="stepStage > item.step" :step="item.step">
              {{ item.name }}
            </v-stepper-step>
            <v-divider />
          </span>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content
            v-for="(itm, i) in formStages"
            :key="i"
            :step="itm.step"
          >
            <v-container>
              <v-form v-model="formValid" v-if="itm.step === 1">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      outlined
                      :label="$t('object.org.orgName')"
                      v-model="orgFormData.name"
                      :rules="formRules.nameRules"
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      outlined
                      v-model="orgFormData.founderName"
                      :rules="formRules.nameRules"
                      :label="$t('object.org.founderName')"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      outlined
                      :rules="formRules.phoneNumberRules"
                      v-model="orgFormData.phoneNo"
                      :label="$t('object.person.phoneNo')"
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      outlined
                      :label="$t('object.person.email')"
                      :rules="formRules.emailRules"
                      v-model="orgFormData.email"
                    />
                  </v-col>
                </v-row>
              </v-form>
              <v-form v-model="formValid" v-if="itm.step === 2">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      outlined
                      :label="$t('object.task.streetNo')"
                      v-model="address.streetNo"
                      :rules="formRules.default"
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      outlined
                      v-model="address.street"
                      :rules="formRules.default"
                      :label="$t('object.task.streetName')"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      outlined
                      :label="$t('object.person.city')"
                      :rules="formRules.default"
                      v-model="address.city"
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      outlined
                      :label="$t('object.person.country')"
                      v-model="address.country"
                    />
                  </v-col>
                </v-row>
              </v-form>
              <v-form v-model="formValid" v-if="itm.step === 3">
                <v-row>
                  <v-col cols="12" md="4">
                    <v-text-field
                      outlined
                      :label="$t('object.org.vat')"
                      v-model="account.vat"
                    />
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      outlined
                      :label="$t('object.org.hrID')"
                      v-model="account.hrID"
                    />
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      outlined
                      :label="$t('object.org.ustID')"
                      v-model="account.ustID"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="4">
                    <v-text-field
                      outlined
                      :label="$t('object.org.taxId')"
                      v-model="account.taxID"
                    />
                  </v-col>
                </v-row>
              </v-form>
              <v-form v-model="formValid" v-if="itm.step === 4">
                <v-text-field
                  outlined
                  :label="$t('object.serviceProvider.bankName')"
                  v-model="account.bankName"
                />
                <v-text-field
                  outlined
                  :label="$t('object.bank.iban')"
                  v-model="account.iban"
                />
                <v-text-field
                  outlined
                  :label="$t('object.bank.bic')"
                  v-model="account.bic"
                />
                <v-text-field
                  outlined
                  :label="$t('object.bank.accNo')"
                  v-model="account.accountNo"
                />
                <v-text-field
                  outlined
                  :label="$t('object.bank.blz')"
                  v-model="account.blz"
                />
              </v-form>
            </v-container>
          </v-stepper-content>

          <v-card-actions>
            <v-btn
              color="primary"
              large
              outlined
              @click="onCancel"
              min-width="100"
            >
              {{ cancelBtnText }}
            </v-btn>
            <v-btn
              color="primary"
              large
              :disabled="!formValid"
              @click="onSubmit"
              :loading="loading"
              min-width="100"
            >
              {{ submitBtnText }}
            </v-btn>
          </v-card-actions>
        </v-stepper-items>
      </v-stepper>
    </v-container>
  </v-container>
</template>

<script lang="ts">
import { formRules } from "@/helpers/component-helper";
import organisationModule from "@/store/modules/organisation-module";
// eslint-disable-next-line prettier/prettier
import { Account, Address, Organisation } from "@taskmanagement/taskapp-model/dist";
import Vue, { nextTick } from "vue";

export default Vue.extend({
  name: "AdminFormComponent",
  data: () => ({
    stepStage: 1,
    formValid: null,
    loading: false,
    orgFormData: new Organisation(),
    address: new Address(),
    account: new Account(),
  }),
  computed: {
    formRules() {
      return formRules;
    },
    organisation() {
      return Object.assign(new Organisation(), {
        ...this.orgFormData,
        accountInfo: { ...this.account },
      });
    },
    formStages() {
      return [
        { name: this.$i18n.t("object.misc.detail"), step: 1 },
        { name: this.$i18n.t("object.person.address"), step: 2 },
        { name: this.$i18n.t("object.org.taxDets"), step: 3 },
        { name: this.$i18n.t("object.misc.bankDetails"), step: 4 },
      ];
    },
    submitBtnText() {
      if (this.stepStage !== this.formStages.length) {
        return this.$i18n.t("site.btn.next");
      }
      return this.$i18n.t("object.btn.submit");
    },
    cancelBtnText() {
      if (this.stepStage !== this.formStages.length) {
        return this.$i18n.t("object.btn.prev");
      }
      return this.$i18n.t("site.btn.cancel");
    },
  },
  methods: {
    clearFormData() {
      nextTick(() => {
        this.address = new Address();
        this.account = new Account();
        this.orgFormData = new Organisation();
      });
    },
    onCancel() {
      this.clearFormData();
      this.$router.back();
    },
    onSubmit() {
      this.loading = true;

      if (this.stepStage !== this.formStages.length) {
        this.stepStage = this.stepStage + 1;
        this.loading = false;
      } else {
        return this.sendToApi().finally(() => {
          this.onCancel();
        });
      }
    },
    sendToApi() {
      return organisationModule.createOrg(this.organisation);
    },
  },
});
</script>

<style></style>
