<template>
  <v-card
    class="fill-height"
    flat
    rounded="lg"
    elevation="1"
    :loading="loading"
  >
    <v-img height="280">
      <v-btn
        absolute
        right
        icon
        color="primary"
        top
        @click="setEdit(type.USERINFO)"
      >
        <v-icon>mdi-pencil-outline</v-icon>
      </v-btn>
      <v-container class="fill-height flex-column align-center pa-4">
        <v-avatar size="130" color="menubg" class="primary--text text-h3 mt-2">
          <strong> {{ userInitials }} </strong>
        </v-avatar>
        <strong class="text-h5 font-weight-bold mt-4">
          {{ fullname }}
        </strong>
        <v-card-subtitle class="pa-0" :class="userRole?.color + '--text'">
          {{ userRole?.value }}
        </v-card-subtitle>

        <statusdisplay-component
          :color="userStatusFormat?.color"
          :text="userStatusFormat?.value"
        />
      </v-container>
    </v-img>

    <v-container v-for="(item, i) in cardDetails" :key="i">
      <v-card-title class="py-0">
        <small class="pa-0">{{ item.title }}</small>
        <v-spacer />
        <v-btn icon color="primary" small @click="setEdit(item.type)">
          <v-icon>mdi-pencil-outline</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />

      <v-list class="pa-0 mt-4" dense>
        <span v-for="(user, i) in item.value" :key="i">
          <v-list-item v-if="item.ref">
            <v-list-item-avatar>
              <v-btn
                small
                fab
                elevation="0"
                v-if="user.mainIcon"
                :href="user.mainHref"
                target="_blank"
              >
                <v-icon color="primary"> {{ user.mainIcon }} </v-icon>
              </v-btn>
            </v-list-item-avatar>

            <v-list-item-content class="pa-0">
              <v-list-item-title class="font-weight-bold">
                {{ user.title }}
              </v-list-item-title>
              <v-list-item-subtitle>{{ user.subtitle }}</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar>
              <v-btn
                small
                fab
                elevation="0"
                v-if="user.subIcon"
                :href="user.subHref"
                target="_blank"
              >
                <v-icon color="primary">{{ user.subIcon }}</v-icon>
              </v-btn>
              <span v-else class="fill-width"></span>
            </v-list-item-avatar>
          </v-list-item>
        </span>
      </v-list>
    </v-container>
    <profileEditDialogComponent
      v-model="editDialog"
      :type="editType"
      :item="user"
      @submit="onEditSave"
    />
  </v-card>
</template>

<script lang="ts">
import { getFullName } from "@/helpers/component-helper";
import { TypeStageFormatter } from "@/model";
import { EnumUserEdit } from "@/model/user-model";
import authModule from "@/store/modules/auth-module";
import formatterModule from "@/store/modules/formatter-module";
import userModule from "@/store/modules/user-module";
import {
  EnumBoolean,
  EnumRoles,
  EnumUserStatus,
  LoginUser,
  User,
} from "@taskmanagement/taskapp-model";
import Vue from "vue";
import statusdisplayComponent from "../statusdisplay-component.vue";
import profileEditDialogComponent from "./profileeditdialog-component.vue";
type TDetail = {
  title: string;
  subtitle: string;
  mainIcon: string;
  subIcon: string;
  mainHref: string;
  subHref: string;
};
type TCdDetail<T = unknown> = {
  title: string;
  type: EnumUserEdit;
  value: TDetail[];
  ref: T;
};
type PropType = { user: User };
type DataType = {
  boolean: typeof EnumBoolean;
  editDialog: boolean;
  editType: EnumUserEdit | null;
  type: typeof EnumUserEdit;
  loading: boolean;
};
type MethodType = {
  setEdit(type: EnumUserEdit): void;
  onEditSave(user: User): void;
};
type ComputeType = {
  userStatusFormat: TypeStageFormatter<EnumUserStatus>;
  userRole: TypeStageFormatter<EnumRoles> | null;
  fullname: string;
  cardDetails: TCdDetail[];
  contactDetails: TDetail[];
  addressDetails: TDetail[];
  currentUser: LoginUser | null;
  userInitials: string;
};
export default Vue.extend<DataType, MethodType, ComputeType, PropType>({
  components: { statusdisplayComponent, profileEditDialogComponent },
  name: "ProfileInfoComponent",
  data: () => ({
    type: EnumUserEdit,
    boolean: EnumBoolean,
    editDialog: false,
    editType: null,
    loading: false,
  }),
  props: {
    user: { type: User },
  },
  watch: {
    editDialog(val) {
      if (!val) this.editType = null;
    },
  },
  computed: {
    userStatusFormat() {
      return formatterModule.userStatus.find(
        (x) => x.enum === this.user?.status
      ) as TypeStageFormatter<EnumUserStatus>;
    },
    userRole() {
      return (
        formatterModule.userRoles.find((x) => x.enum === this.user.role) ?? null
      );
    },
    fullname() {
      return (this.user as User)?.fullName ?? getFullName(this.user) ?? "";
    },
    userInitials() {
      const init = `
      ${this.user.firstName?.charAt(0)}${this.user.lastName?.charAt(0)}`;

      return init.toUpperCase();
    },
    contactDetails() {
      const user = this.user;
      return [
        {
          title: user.phoneNumber ?? "",
          subtitle: this.$i18n.t("object.person.phoneNo").toString(),
          mainIcon: "mdi-phone",
          subIcon: "mdi-message-text",
          mainHref: user.phoneNumber ? `tel:${user.phoneNumber}` : "",
          subHref: user.phoneNumber ? `sms:${user.phoneNumber}` : "",
        },
        {
          title: user.email ?? "",
          subtitle: this.$i18n.t("object.person.email").toString(),
          mainIcon: "mdi-email",
          subIcon: "",
          mainHref: user.email ? `mailto:${user.email}` : "",
          subHref: "", // No secondary action for email
        },
      ];
    },
    addressDetails() {
      const address = this.user.address;
      return [
        {
          title: `${address?.street} ${address?.streetNo}`,
          subtitle:
            !address?.city && !address?.postalCode
              ? ""
              : `${address?.city}, ${address?.postalCode}`,
          mainIcon:
            address?.city && address?.postalCode ? "mdi-map-marker" : "",
          subIcon: "",
          mainHref:
            address?.city && address?.postalCode
              ? // eslint-disable-next-line prettier/prettier
               `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(`${address.streetNo} ${address.street}, ${address?.city} ${address?.postalCode}, ${address.country}`)}`
              : "",
          subHref: "",
        },
      ];
    },
    cardDetails() {
      return [
        {
          title: this.$i18n.t("object.person.contact").toString(),
          ref: this.user,
          type: EnumUserEdit.CONTACT,
          value: this.contactDetails,
        },
        {
          title: this.$i18n.t("object.person.address").toString(),
          ref: this.user.address,
          type: EnumUserEdit.ADDRESS,
          value: this.addressDetails,
        },
      ];
    },
    currentUser() {
      return authModule.activeUser;
    },
  },
  methods: {
    setEdit(type: EnumUserEdit) {
      this.editType = type;
      this.editDialog = true;
    },
    onEditSave(user: User) {
      this.loading = true;
      userModule.updateUser(user).finally(() => (this.loading = false));
    },
  },
});
</script>

<style></style>
