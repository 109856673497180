<template>
  <v-tabs
    class="my-2"
    v-model="currentItem"
    background-color="transparent"
    slider-size="2"
    center-active
    height="50"
    :show-arrows="$vuetify.breakpoint.mobile"
  >
    <v-tab
      class="mr-4 pa-1"
      v-for="(item, i) in list"
      :key="i"
      :tab-value="i"
      @change="tabsChange(item.enum)"
    >
      <v-sheet
        class="fill-height fill-width d-flex align-center justify-center px-10 rounded-t-lg"
        elevation="2"
      >
        <statusdisplay-component :color="item.color" :text="item.value" />
      </v-sheet>
      <v-tabs-slider />
    </v-tab>
  </v-tabs>
</template>

<script lang="ts">
import { TypeStageFormatter } from "@/model";
import { EnumStatus } from "@taskmanagement/taskapp-model";
import Vue from "vue";
import { PropValidator } from "vue/types/options";
import statusdisplayComponent from "./statusdisplay-component.vue";

export default Vue.extend({
  components: { statusdisplayComponent },
  name: "StatusFilterComponent",
  props: {
    items: {
      type: Array,
      required: true,
    } as PropValidator<TypeStageFormatter<EnumStatus>[]>,
  },
  data: () => ({
    currentItem: null,
  }),
  computed: {
    list() {
      return [{ value: this.$i18n.t("object.label.all") }, ...this.items];
    },
  },
  methods: {
    tabsChange(property: EnumStatus | undefined) {
      this.$emit("change", property);
    },
  },
});
</script>
