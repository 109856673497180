<template>
  <v-container class="items-center">
    <apexCharts type="area" :series="series" :options="options" height="300" />
  </v-container>
</template>

<script lang="ts">
import { ApexOptions } from "apexcharts";
import moment from "moment";
import Vue from "vue";
import ApexCharts from "vue-apexcharts";
import { PropValidator } from "vue/types/options";

export default Vue.extend({
  name: "AreaChartComponent",
  components: { apexCharts: ApexCharts },
  props: {
    series: {
      type: Array,
    } as PropValidator<ApexOptions["series"]>,
    categories: {
      type: Array,
    } as PropValidator<ApexOptions["xaxis"]>,
    title: { type: String },
  },
  computed: {
    moments() {
      return moment.months();
    },
    options() {
      return {
        chart: {
          height: 120,
          type: "area",
          toolbar: {
            show: true,
            tools: {
              zoom: true,
              pan: false,
              reset: false,
              zoomin: true,
              zoomout: true,
              download: false,
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        title: {
          text: this.title,
          align: "left",
          style: {
            fontSize: "22",
          },
        },
        xaxis: {
          type: "category",
        },
        stroke: {
          curve: "smooth",
        },
      } as ApexOptions;
    },
  },
});
</script>

<style></style>
