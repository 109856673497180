<template>
  <v-container app>
    <v-overlay opacity="1" v-model="isError" color="mainbg">
      <errorNotFoundComponent />
    </v-overlay>
  </v-container>
</template>

<script lang="ts">
import ErrorNotFoundComponent from "@/components/subcomponent/helper/errornotfound-component.vue";
import Vue from "vue";

export default Vue.extend({
  name: "ErrorView",
  components: { errorNotFoundComponent: ErrorNotFoundComponent },
  computed: {
    isError() {
      return this.$store.state.appError;
    },
  },
});
</script>

<style></style>
