import { EnumOrgForm } from "@/model";
import { ROUTE_ADMIN } from "@/model/routes";
import { i18n } from "@/plugins/i18n";
import router from "@/plugins/router";
import { AccountService } from "@/services/account-service";
import { AddressService } from "@/services/address-service";
import organisationModule from "@/store/modules/organisation-module";
import { Organisation } from "@taskmanagement/taskapp-model";
import Vue from "vue";

export function updateOrganization(org: Organisation, type?: EnumOrgForm) {
  if (type) {
    if (type === EnumOrgForm.ADDRESS) {
      new AddressService().createOrUpdate(org?.address)?.then((res) => {
        if (res) organisationModule.updateOrg({ ...org, address: res });
      });
    }
    if (type === EnumOrgForm.TAX) {
      new AccountService().createOrUpdate(org.accountInfo)?.then((res) => {
        if (res) organisationModule.updateOrg({ ...org, accountInfo: res });
      });
    }
    if (type === EnumOrgForm.BANK) {
      new AccountService().createOrUpdate(org.accountInfo)?.then((res) => {
        if (res) organisationModule.updateOrg({ ...org, accountInfo: res });
      });
    }
  }
  if (!type || type === EnumOrgForm.DETAIL) {
    organisationModule.updateOrg(org);
  }
}

export function forceOrgCreation() {
  router.push({ name: ROUTE_ADMIN });
  Vue.$toast.info(i18n.t("object.org.orgNeededText").toString());
}
