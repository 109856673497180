<template>
  <span>
    <previewHeaderComponent :title="$t('object.org.name')" @back="goBack" />
    <adminFormComponent />
  </span>
</template>

<script lang="ts">
import Vue from "vue";
import AdminFormComponent from "../subcomponent/admin/adminform-component.vue";
import PreviewHeaderComponent from "../subcomponent/previewheader-component.vue";

export default Vue.extend({
  name: "AdminEntry",
  components: {
    adminFormComponent: AdminFormComponent,
    previewHeaderComponent: PreviewHeaderComponent,
  },
  methods: {
    goBack() {
      this.$router.back();
    },
  },
});
</script>

<style></style>
