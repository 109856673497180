<template>
  <v-container v-if="userCreated">
    <userCreatedComponent
      :title="$t('object.user.successTitle')"
      :subtitle="$t('object.user.successSubtitle')"
      btnColor="primary"
      :btnText="$t('object.user.successbtn')"
      :maxWidth="500"
      @click="onUserCreated"
    />
  </v-container>
  <v-container v-else>
    <previewheader-component
      :title="$t('object.user.createUser')"
      @back="$router.back()"
    />
    <v-container class="mt-4">
      <v-row>
        <v-col cols="12" md="3">
          <v-card class="pa-2" rounded="xl">
            <v-timeline class="pa-4 text-start" dense>
              <span v-for="(n, i) in stepStages" :key="i">
                <v-timeline-item
                  fill-dot
                  right
                  :small="step !== n.step"
                  :color="(step >= n.step && 'primary') || 'grey'"
                >
                  <template v-slot:icon>
                    <strong class="white--text">{{ n.step }}</strong>
                  </template>

                  <strong class="text-no-wrap">{{ n.title }}</strong>
                </v-timeline-item>
              </span>
            </v-timeline>
          </v-card>
        </v-col>

        <v-col cols="12" md="9" v-if="step == 1">
          <v-card class="pa-4" rounded="xl">
            <v-card-title class="py-2">
              <strong>{{ $t("object.misc.user.usrRole") }}</strong>
            </v-card-title>
            <v-card-subtitle class="text-start">
              {{ $t("object.misc.user.selChoose") }}
            </v-card-subtitle>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="6" v-for="(itm, i) in enumRoles" :key="i">
                  <v-card
                    :outlined="itm.role !== formItem.role"
                    :elevation="itm.role === formItem.role ? 10 : null"
                    @click="setRole(itm.role)"
                    hover
                    rounded="xxl"
                    max-height="400"
                    min-height="110"
                  >
                    <userRoleComponent
                      :iconColor="itm.role == formItem.role ? 'primary' : null"
                      :avatarBgColor="
                        itm.role == formItem.role ? 'mainbg' : 'grey'
                      "
                      :value="itm"
                      :checked="itm.role === formItem.role"
                      :showCheck="itm.role === formItem.role"
                    />
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="mt-10">
              <v-btn
                color="primary"
                @click="step = 2"
                :min-width="btnMinWidth"
                :disabled="!formItem.role"
              >
                {{ $t("object.btn.continue") }}
              </v-btn>

              <v-btn :min-width="btnMinWidth" outlined @click="back">
                {{ $t("object.label.back") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>

        <v-col cols="12" md="9" v-if="step == 2">
          <v-card class="pa-4" rounded="xl">
            <v-card-title class="py-2">
              <strong>{{ $t("object.misc.useDetails") }}</strong>
            </v-card-title>
            <v-card-subtitle class="text-start">
              {{ $t("object.misc.entrDetails") }}
            </v-card-subtitle>
            <v-card-text>
              <v-form v-model="formValid" ref="form">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      outlined
                      :label="$t('object.person.firstName')"
                      v-model="formItem.firstName"
                      :rules="formRules.nameRules"
                      required
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      spellcheck
                      outlined
                      v-model="formItem.lastName"
                      :rules="formRules.nameRules"
                      :label="$t('object.person.lastName')"
                      required
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      outlined
                      required
                      hide-spin-buttons
                      :label="$t('object.person.phoneNo')"
                      placeholder="+490123456789"
                      :rules="formRules.phoneNumberRules"
                      v-model="formItem.phoneNumber"
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      outlined
                      v-model="formItem.email"
                      :label="$t('object.person.email')"
                      required
                      :rules="formRules.emailRules"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      outlined
                      required
                      hide-spin-buttons
                      v-model="formItem.username"
                      :label="$t('site.access.username')"
                      :rules="formRules.nameRules"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-btn
                color="primary"
                :min-width="btnMinWidth"
                @click="nexStagetwo"
                :disabled="!formValid"
              >
                {{ $t("object.btn.continue") }}
              </v-btn>
              <v-btn
                outlined
                color="primary"
                :min-width="btnMinWidth"
                @click="back"
              >
                {{ $t("object.label.back") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>

        <v-col cols="12" md="9" v-if="step == 3">
          <v-card class="pa-4" rounded="xl">
            <v-card-title class="text-start">
              <strong>{{ $t("object.misc.reviewAndCreate") }}</strong>
            </v-card-title>
            <v-card-subtitle class="text-start">
              {{ $t("object.misc.userReview") }}
            </v-card-subtitle>

            <v-card class="pa-0" outlined max-width="900">
              <v-container class="px-10">
                <v-row
                  v-for="(item, i) in userDetail"
                  :key="i"
                  justify="center"
                  align="center"
                  align-content="center"
                  dense
                >
                  <v-col :cols="5" align="start">
                    {{ item.title }}
                  </v-col>
                  <v-col :cols="5" align="start">
                    <strong> {{ item.value }} </strong>
                  </v-col>
                  <v-col :cols="2" align="start" v-if="item.condition">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" color="error"
                          >mdi-alert-circle</v-icon
                        >
                      </template>
                      <span>
                        {{ $t("object.auth.userExists", [item.title]) }}
                      </span>
                    </v-tooltip>
                  </v-col>

                  <v-divider class="mx-2 grey lighten-5" />
                </v-row>
              </v-container>
              <v-checkbox
                v-model="userNotification"
                dense
                :label="$t('object.user.confirmNotification')"
              />
            </v-card>

            <v-card-actions class="mt-4 pt-6">
              <v-row justify="start" class="pa-4">
                <v-col cols="12" md="2">
                  <v-btn
                    color="primary"
                    @click="onSave"
                    block
                    :loading="btnLoading"
                    :disabled="!userNotification"
                  >
                    {{ $t("site.btn.save") }}
                  </v-btn>
                </v-col>
                <v-col cols="12" md="2">
                  <v-btn
                    outlined
                    color="primary"
                    :loading="btnLoading"
                    block
                    @click="back"
                  >
                    {{ $t("object.label.back") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script lang="ts">
import { formRules } from "@/helpers/component-helper";
import { InformationType } from "@/model";
import { ROUTE_USERS } from "@/model/routes";
import { UserService } from "@/services/user-service";
import formatterModule from "@/store/modules/formatter-module";
import { EnumRoles, User } from "@taskmanagement/taskapp-model";
import Vue from "vue";
import previewheaderComponent from "../previewheader-component.vue";
import UserCreatedComponent from "./usercreated-component.vue";
import UserRoleComponent from "./userrole-component.vue";

type UserError = Partial<{ [P in keyof User]: boolean }>;

export default Vue.extend({
  name: "NewUsersComponent",
  components: {
    previewheaderComponent,
    userRoleComponent: UserRoleComponent,

    userCreatedComponent: UserCreatedComponent,
  },
  data: () => ({
    step: 1,
    formItem: new User(),
    error: null as null | UserError,
    formValid: null as boolean | null,
    btnMinWidth: 120,
    userCreated: false,
    btnLoading: false,
    userNotification: false,
    defaultUsername: null as null | string,
  }),
  computed: {
    formRules() {
      return formRules;
    },
    enumRoles() {
      return formatterModule.userRoles.map((x) => ({ role: x.enum, ...x }));
    },
    stepStages() {
      return [
        { title: this.$i18n.t("object.misc.user.setRole"), step: 1 },
        { title: this.$i18n.t("object.misc.useDetails"), step: 2 },
        { title: this.$i18n.t("object.misc.reviewAndCreate"), step: 3 },
      ];
    },
    userDetail() {
      const usr = this.formItem;
      return [
        {
          title: this.$i18n.t("object.person.firstName"),
          value: usr.firstName,
        },

        {
          title: this.$i18n.t("object.person.lastName"),
          value: usr.lastName,
        },
        {
          title: "Username",
          value: usr.username,
          condition: this.error?.username,
        },
        {
          title: this.$i18n.t("object.person.email"),
          value: usr.email,
          condition: this.error?.email,
        },
        {
          title: this.$i18n.t("object.person.phoneNo"),
          value: usr.phoneNumber,
          condition: this.error?.phoneNumber,
        },
        {
          title: this.$i18n.t("object.misc.user.usrAccRole"),
          value:
            formatterModule.userRoles
              .find((x) => x.enum == usr.role)
              ?.value.toString() ?? null,
        },
      ] as InformationType[];
    },
  },
  watch: {
    formItem(val) {
      if (val?.firstName && val?.lastName) {
        this.formItem.username =
          `${val?.firstName}.${val?.lastName}`.toLowerCase();
      }
    },
  },
  methods: {
    updateUsername(text: string) {
      this.formItem.username = text;
    },
    validateField() {
      (this.$refs?.form as Element & { validate(): void })?.validate();
    },
    back() {
      if (this.step == 1) {
        this.$router.back();
      } else {
        this.step--;
      }
    },
    setRole(item: EnumRoles) {
      this.$set(this.formItem, "role", item);
    },
    nexStagetwo() {
      new UserService()
        .validateUser(this.formItem)
        .then((valid) => {
          if (valid) {
            this.error = valid;
          }
        })
        .then(() => (this.step = 3));
    },
    onSave() {
      if (this.formItem) {
        this.btnLoading = true;
        new UserService().create(this.formItem).then((res) => {
          if (res) {
            this.userCreated = true;
            this.btnLoading = false;
            setTimeout(() => {
              this.$router.push({ name: ROUTE_USERS });
            }, 5000);
          }
        });
      }
    },
    onUserCreated() {
      this.$router.push({ name: ROUTE_USERS });
    },
  },
});
</script>

<style></style>
