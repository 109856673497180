import {
  Identification,
  STATISTICS_ROUTE,
  STATISTICS_SUMMARY_ROUTE,
  StatisticsDesc,
  SummaryStatistics,
} from "@taskmanagement/taskapp-model";
import { BaseService } from "./base-service";

const url = STATISTICS_ROUTE;
export class StatisticsService extends BaseService<
  Identification & StatisticsDesc
> {
  constructor() {
    super(url);
  }
  getSummary() {
    return this.axios
      .get(STATISTICS_SUMMARY_ROUTE)
      .then((res) => res.data as SummaryStatistics);
  }
}
