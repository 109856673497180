<template>
  <v-container class="px-0">
    <statisticsComponent />
  </v-container>
</template>

<script lang="ts">
import Vue from "vue";
import StatisticsComponent from "../subcomponent/statistics/statistics-component.vue";

export default Vue.extend({
  name: "StatisticsComponent",
  components: { statisticsComponent: StatisticsComponent },
  data: () => ({
    tabs: 0,
  }),
  computed: {},
});
</script>

<style></style>
