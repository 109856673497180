<template>
  <span>
    <invoiceComponent />
  </span>
</template>

<script lang="ts">
import Vue from "vue";
import InvoiceComponent from "../components/invoice/invoice-component.vue";
import invoiceModule from "../store/modules/invoice-module";
import serviceproviderModule from "../store/modules/serviceprovider-module";

export default Vue.extend({
  name: "InvoiceView",
  components: {
    invoiceComponent: InvoiceComponent,
  },
  computed: {
    loading: {
      get() {
        return this.$store.state.isLoading;
      },
      set(value) {
        this.$store.commit("setLoading", value);
      },
    },
  },
  mounted() {
    this.getAPIContent();
  },
  methods: {
    getAPIContent() {
      this.$store.state.isLoading = true;
      serviceproviderModule
        .retrieveServiceProviderList()
        .then(() => invoiceModule.retrieveInvoiceList())
        .finally(() => (this.$store.state.isLoading = false));
    },
  },
});
</script>

<style></style>
