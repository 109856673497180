import orderserviceModule from "@/store/modules/orderservice-module";
import {
  Allocation,
  EnumStatus,
  EnumTaskStage,
} from "@taskmanagement/taskapp-model";

export function declineOrderRequest(allocation: Allocation) {
  allocation = {
    ...allocation,
    stage: EnumTaskStage.declined,
    decision: EnumStatus.declined,
  };
  return orderserviceModule.updateService(allocation);
}
export function CancelOrderAfterAccept(allocation: Allocation) {
  allocation = {
    ...allocation,
    decision: EnumStatus.declined,
  };
  return orderserviceModule.updateService(allocation);
}
export function acceptOrderRequest(allocation: Allocation) {
  allocation = {
    ...allocation,
    stage: EnumTaskStage.accepted,
    decision: EnumStatus.accepted,
  };
  return orderserviceModule.updateService(allocation);
}

export function orderComplete(allocation: Allocation) {
  allocation = {
    ...allocation,
    stage: EnumTaskStage.done,
  };
  return orderserviceModule.updateService(allocation);
}
