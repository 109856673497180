import {
  Organisation,
  ORGANISATION_ROUTE,
} from "@taskmanagement/taskapp-model";
import { BaseService } from "./base-service";

export class OrganisationService extends BaseService<Organisation> {
  constructor() {
    super(ORGANISATION_ROUTE);
  }
}
