import { handleGlobalError, handleGlobalUpdate } from "@/helpers/errors";
import { i18n } from "@/plugins/i18n";
import {
  INVOICE_ALL_ROUTES,
  INVOICE_DETAIL_ROUTE,
  INVOICE_GEN_ROUTES,
  INVOICE_ROUTES,
  INVOICE_SEND_ROUTES,
  Invoice,
  ServiceProvider,
} from "@taskmanagement/taskapp-model";
import { BaseService } from "./base-service";

export type TypeAllInvoice = {
  invoiceList: Invoice[];
  pending: ServiceProvider[];
};

const url = INVOICE_ROUTES;
export class InvoiceService extends BaseService<Invoice> {
  constructor() {
    super(url);
  }

  sendInvoice(data: Invoice) {
    return this.axios
      .post(INVOICE_SEND_ROUTES, data)
      .then((res) => res.data as Invoice)
      .then((data) => {
        handleGlobalUpdate(data, i18n.t("object.invoice.msg.sentInv"));
        return data;
      });
  }

  generateInvoice(data: { html: string }) {
    return this.axios
      .post(INVOICE_GEN_ROUTES, data, { responseType: "arraybuffer" })
      .then((res) => res.data);
  }

  getAllInvoices(query?: typeof this.params) {
    return this.axios
      .get(INVOICE_ALL_ROUTES, { params: query })
      .then((res) => res.data as TypeAllInvoice);
  }

  getInvoiceByInvNumber(data: Invoice) {
    return this.axios
      .get(INVOICE_DETAIL_ROUTE, {
        params: { invoiceNumber: data.invoiceNumber },
      })
      .then((res) => res.data as Invoice)
      .catch((err) => handleGlobalError(err));
  }
}
