<template>
  <v-container>
    <v-progress-circular
      v-if="!hideCircle"
      :rotate="-90"
      :size="diameter"
      :width="width"
      :value="timePercentage"
      :color="timePercentage > 50 ? 'primary' : 'error'"
    >
      <strong>{{ prettyTime }}</strong>
    </v-progress-circular>
    <strong
      :class="timePercentage > 50 ? 'primary' : 'error' + '--text'"
      v-if="hideCircle"
    >
      {{ prettyTime }}
    </strong>
  </v-container>
</template>

<script lang="ts">
import { formatTime } from "@/helpers/component-helper";
import { allowedTime } from "@taskmanagement/taskapp-model";
import moment, { Moment } from "moment";
import Vue from "vue";

type DataType = {
  isRunning: boolean;
  minutes: number;
  secondes: number;
  // eslint-disable-next-line no-undef
  timer: unknown | null;
  time: number;
};
type ComputedType = {
  prettyTime: string;
  elasped: boolean;
  allowedTime: Moment;
  timePercentage: number;
};
type PropType = {
  value: string;
  color: string;
  hideCircle: boolean;
  width: number;
  diameter: number;
};
type MethodType = {
  start(): void;
  stop(): void;
  reset(): void;
  formatTime(time: string): string;
};

export default Vue.extend<DataType, MethodType, ComputedType, PropType>({
  name: "CountDownTimerComponent",
  data: () => ({
    isRunning: false,
    minutes: 0,
    secondes: 0,
    // eslint-disable-next-line no-undef
    timer: null as unknown | null,
    time: 0,
  }),
  computed: {
    prettyTime() {
      let time = this.time / 60;
      let minutes = parseInt(time.toString());
      let secondes = Math.round((time - minutes) * 60);
      return `${minutes}:${secondes < 10 ? "0" : ""}${secondes}`;
    },
    elasped() {
      return moment(this.value).isBefore(moment());
    },
    allowedTime() {
      const expiryTime = moment(this.value).add(allowedTime, "minutes");
      return expiryTime;
    },
    timePercentage() {
      // eslint-disable-next-line prettier/prettier
      const completedTime = ((allowedTime-(this.time / 60)) / allowedTime) * 100;
      return 100 - completedTime;
    },
  },
  props: {
    value: { type: String, required: true },
    color: { type: String, default: "error" },
    hideCircle: { type: Boolean },
    width: { type: Number, default: 10 },
    diameter: { type: Number, default: 80 },
  },
  mounted() {
    this.start();
  },
  methods: {
    start() {
      // Calculate initial time remaining based on value and current time
      const now = moment();
      const allowedTime = moment(this.allowedTime);
      const diffSeconds = allowedTime.diff(now, "seconds");

      // Check if the value has already passed
      if (diffSeconds <= 0) {
        this.time = 0;
        this.isRunning = false;
        return;
      }

      this.time = diffSeconds;

      // Start the countdown timer
      this.isRunning = true;
      if (!this.timer) {
        this.timer = setInterval(() => {
          if (this.time > 0) {
            this.time--;
          } else {
            // eslint-disable-next-line no-undef
            clearInterval(this.timer as number);
            this.reset();
          }
        }, 1000);
      }
    },
    stop() {
      this.isRunning = false;
      // eslint-disable-next-line no-undef
      clearInterval(this.timer as number);
      this.timer = null;
    },
    reset() {
      this.stop();
      this.time = 0;
      this.secondes = 0;
      this.minutes = 0;
    },
    formatTime(time: string) {
      return formatTime(time, "DD-MM-YYYY HH:mm", this.$route.params.locale);
    },
  },
  beforeDestroy() {
    if (this.timer) clearInterval(this.timer as number);
    this.time = 0;
    this.minutes = 0;
  },
});
</script>

<style></style>
