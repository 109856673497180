import store from "@/store";
import authModule from "@/store/modules/auth-module";
import categoryModule from "@/store/modules/category-module";
import organisationModule from "@/store/modules/organisation-module";
import { EnumRoles, User } from "@taskmanagement/taskapp-model";
import { forceOrgCreation } from "./organisation-helper";

export function getMinimumAllowedRoles(role: EnumRoles) {
  return Object.values(EnumRoles).filter((x) => Number(x) <= role);
}

export function userHasAccess(role: EnumRoles) {
  const userRole = authModule.role;
  if (!role || !userRole) return false;
  return getMinimumAllowedRoles(role).includes(userRole);
}

export function isUserOrHigherRoleHasAccess(role: EnumRoles, user: User) {
  const userRole = authModule.role;
  if (!role || !userRole) return false;
  return userHasAccess(role) || authModule.activeUser?.id === user.id;
}
export function navToOrgCreateQuery() {
  return organisationModule.getOrganisation().then((org) => {
    if (!org?.[0] && org?.length === 0) {
      if (authModule.isLoggedIn) {
        if (authModule.role !== EnumRoles.AGENT) {
          forceOrgCreation();
        }
      }
      return org;
    }
  });
}
export function loadDataOnLogIn() {
  store.state.isLoading = true;
  return Promise.all([
    navToOrgCreateQuery(),
    categoryModule.LoadAllCategories(),
  ]).then(() => {
    store.state.isLoading = false;
  });
}
