<template>
  <accessAuthComponent />
</template>

<script lang="ts">
import localAxios from "@/helpers/axios-helper";
import Vue from "vue";
import AccessAuthComponent from "../subcomponent/access/access-component.vue";

export default Vue.extend({
  name: "AccessComponent",
  components: {
    accessAuthComponent: AccessAuthComponent,
  },
  data: () => ({}),
  mounted() {
    this.getParams();
  },
  methods: {
    getParams() {
      localAxios
        .post("/api/auth/verify", { token: this.$route.query.token })
        .then((res) => res.data);
    },
  },
});
</script>

<style></style>
