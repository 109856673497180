<template>
  <v-container>
    <invoiceCreatingComponent />
  </v-container>
</template>

<script lang="ts">
import InvoiceCreatingComponent from "@/components/subcomponent/invoice/invoicecreating-component.vue";
import invoiceModule from "@/store/modules/invoice-module";
import serviceproviderModule from "@/store/modules/serviceprovider-module";
import Vue from "vue";

export default Vue.extend({
  name: "InvoiceEntryView",
  components: { invoiceCreatingComponent: InvoiceCreatingComponent },
  mounted() {
    this.getAPIContent();
  },
  methods: {
    getAPIContent() {
      this.$store.state.isLoading = true;
      serviceproviderModule
        .retrieveServiceProviderList()
        .then(() => invoiceModule.retrieveInvoiceList())
        .finally(() => (this.$store.state.isLoading = false));
    },
  },
});
</script>

<style></style>
