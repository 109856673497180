<template>
  <span>
    <v-container>
      <headerCardComponent
        :items="userStatus"
        :title="$t('object.label.users')"
        @new="createNew"
      />
    </v-container>

    <v-container>
      <statusFilterComponent :items="userRoles" @change="changeRoles" />

      <v-data-table
        :items="userList"
        :headers="headers"
        @click:row="goToShowMore"
      >
        <template v-slot:[`item.fullName`]="{ item }">
          <v-icon left>mdi-account-circle-outline</v-icon>
          {{ item.fullName }}
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <statusdisplay-component
            :color="getUserStatus(item)?.color"
            :text="getUserStatus(item)?.value"
            outlined
            :width="200"
          />
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn color="transparent" small fab text @click="goToShowMore(item)">
            <v-icon color="primary"> mdi-dots-horizontal </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-container>
  </span>
</template>

<script lang="ts">
import { QueryParams } from "@/model";
import { ROUTE_USERDETAIL, ROUTE_USERS_NEW } from "@/model/routes";
import formatterModule from "@/store/modules/formatter-module";
import userModule from "@/store/modules/user-module";
import { EnumRoles, User } from "@taskmanagement/taskapp-model";
import moment from "moment";
import Vue from "vue";
import { DataTableHeader } from "vuetify";
import HeaderCardComponent from "../headercard-component.vue";
import StatusdisplayComponent from "../statusdisplay-component.vue";
import StatusFilterComponent from "../statusfilter-component.vue";

export default Vue.extend({
  name: "UsersComponent",
  components: {
    headerCardComponent: HeaderCardComponent,
    statusFilterComponent: StatusFilterComponent,
    StatusdisplayComponent,
  },
  computed: {
    userRoles() {
      return userModule.userRoles;
    },
    userActive() {
      return userModule.activity;
    },
    userStatus() {
      return userModule.userStatus;
    },
    userList() {
      const roleList = userModule.userRoles;
      return userModule.userList.map((x) => ({
        ...x,
        role: roleList.find((z) => z.enum === x.role)?.value,
        createdAt: moment(x.createdAt).locale(this.$i18n.locale).format("LLL"),
      }));
    },
    headers() {
      return [
        { text: "", value: "picture", width: 50, sortable: false },
        { text: this.$t("object.person.fullName"), value: "fullName" },
        {
          text: this.$t("object.allocation.decision"),
          value: "status",
        },
        { text: this.$t("object.person.role"), value: "role" },
        { text: this.$t("object.misc.joinedDate"), value: "createdAt" },
        {
          text: "",
          value: "actions",
          align: "end",
          sortable: false,
        },
      ] as DataTableHeader[];
    },
  },
  methods: {
    changeRoles(role: EnumRoles) {
      this.onUserFilter({ role: role });
    },
    onUserFilter(param?: QueryParams<User>) {
      userModule.getUserList(param);
    },
    getUserStatus(user: User) {
      return formatterModule.activeStatus?.find((x) => x.enum === user.active);
    },
    goToShowMore(user: User) {
      this.$router.push({
        name: ROUTE_USERDETAIL,
        params: { username: user.username },
      });
    },
    createNew() {
      this.$router.push({ name: ROUTE_USERS_NEW });
    },
    getUserRole(user: User) {
      return userModule.userRoles.find((x) => x.enum === user.role) ?? null;
    },
  },
});
</script>

<style></style>
