<template>
  <v-card>
    <v-container>
      <v-card flat class="pa-4 mt-6">
        <v-form v-model="formValid">
          <v-row>
            <v-col cols="12" md="8" class="px-4">
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    outlined
                    color="primary"
                    v-model="formItems.requesterFirstName"
                    :disabled="disabled"
                    :label="$t('object.person.firstName')"
                    required
                    :rules="formRules.nameRules"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    outlined
                    color="primary"
                    :disabled="disabled"
                    :label="$t('object.person.lastName')"
                    required
                    v-model="formItems.requesterLastName"
                  />
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" md="4">
              <v-row>
                <v-col cols="12">
                  <v-select
                    v-model="formItems.category"
                    :items="taskTitleLists"
                    item-text="name"
                    :rules="formRules.default"
                    return-object
                    outlined
                    color="primary"
                    :disabled="disabled"
                    :label="$t('object.task.description')"
                    required
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="8" class="px-4">
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    outlined
                    color="primary"
                    required
                    hide-spin-buttons
                    :disabled="disabled"
                    :label="$t('object.person.phoneNo')"
                    placeholder="+490123456789"
                    :rules="formRules.phoneNumberRules"
                    v-model="formItems.requesterPhoneNo"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    outlined
                    color="primary"
                    v-model="formItems.requesterEmail"
                    :disabled="disabled"
                    :label="$t('object.person.email')"
                    required
                    :rules="formRules.emailRules"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="4">
              <v-select
                v-model="formItems.subCategory"
                :items="taskSubtitleLists"
                item-text="name"
                return-object
                outlined
                color="primary"
                :disabled="disableProblemField"
                :rules="formRules.problemRule"
                :label="$t('object.task.problem')"
                required
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="8" class="px-4">
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    outlined
                    color="primary"
                    v-model="formItems.streetNo"
                    :rules="formRules.default"
                    :disabled="disabled"
                    :label="$t('object.task.streetNo')"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    outlined
                    color="primary"
                    :rules="formRules.default"
                    v-model="formItems.street"
                    :disabled="disabled"
                    :label="$t('object.task.streetName')"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="4">
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    type="datetime-local"
                    v-model="formItems.startTime"
                    outlined
                    color="primary"
                    :min="currentDate"
                    :dateMin="currentDate"
                    :label="$t('object.task.startTime')"
                    :rules="formRules.taskTime"
                    :disabled="disabled"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    type="datetime-local"
                    outlined
                    color="primary"
                    :min="formItems.startTime ?? currentDate"
                    v-model="formItems.endTime"
                    :label="$t('object.task.endTime')"
                    :disabled="disabled"
                    clearable
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="8">
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    type="number"
                    v-model="formItems.postalCode"
                    :label="$t('object.person.postalCode')"
                    :hint="$t('object.dialog.numbersOnly')"
                    hide-spin-buttons
                    :disabled="disabled"
                    outlined
                    color="primary"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="formItems.city"
                    :label="$t('object.task.city')"
                    :disabled="disabled"
                    outlined
                    color="primary"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="4">
              <v-combobox
                v-model="formItems.allocatedTo"
                :hint="`${formItems.allocatedTo?.firstName} ${formItems.allocatedTo?.lastName}`"
                :label="$t('object.task.allocatedTo')"
                :rules="formRules.allocatedTo"
                :disabled="disabled"
                :items="serviceProviderList"
                outlined
                color="primary"
                class="hide-icon"
                return-object
                item-text="firstName"
                required
              >
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ allocatedToText(item) }}
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-combobox>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-container>
    <v-card-actions>
      <v-row align-content="end" justify="start" class="pa-4">
        <v-col cols="12" md="2" align-self="start">
          <v-btn
            color="primary"
            class="rounded-lg"
            outlined
            block
            @click="onclose"
            width="120"
            style="text-transform: none"
          >
            {{ $t("site.btn.cancel") }}
          </v-btn>
        </v-col>
        <v-col cols="12" md="2">
          <v-btn
            color="primary"
            block
            @click="onSubmit"
            class="rounded-lg"
            :disabled="disableSummit"
            width="120"
            style="text-transform: none"
          >
            {{ $t("site.btn.save") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import {
  EnumBoolean,
  MainCategory,
  Person,
  ServiceProvider,
  SubCategory,
  Task,
} from "@taskmanagement/taskapp-model";
import Vue from "vue";
import { FormRules, formRules } from "../../../helpers/component-helper";

import categoryModule from "@/store/modules/category-module";
import moment from "moment";
import { TranslateResult } from "vue-i18n";
import serviceproviderModule from "../../../store/modules/serviceprovider-module";

type PropType = {
  value: Task;
  today: string;
  disabled: boolean;
  maxWidth: number;
  persistent: boolean;
};
type DataType = {
  boolean: EnumBoolean;
  formValid: boolean | null;
  currentDate: Date | string | null;
};
type MethodType = {
  onclose(): void;
  onSubmit(): void;
  triggerRefresh(): void;
  toggleEditMode(): void;
  triggerData(): void;
  allocatedToText(item: Person): string;
};
type ComputedType = {
  formItems: Task;
  disabledBtn: boolean;
  formRules: FormRules;
  serviceProviderList: ServiceProvider[];
  taskTitleLists: MainCategory[];
  headerText: string | TranslateResult;
  taskSubtitleLists: SubCategory[];
  disableProblemField: boolean;
  disableSummit: boolean;
};

export default Vue.extend<DataType, MethodType, ComputedType, PropType>({
  name: "TaskFormComponent",
  data: () => ({
    valid: null as boolean | null,
    boolean: EnumBoolean.false,
    formValid: true,
    currentDate: moment().format("YYYY-MM-DDThh:mm"),
  }),
  props: {
    value: { type: Object, required: true },
    disabled: { type: Boolean },
    today: { type: String },
    maxWidth: { type: Number, default: 750 },
    persistent: { type: Boolean, default: true },
  },
  computed: {
    formItems: {
      get() {
        return this.value;
      },
      set(val) {
        val.endTime =
          val.endTime === "" ? (null as unknown as undefined) : val.endTime;
        this.$emit("input", val);
      },
    },
    headerText() {
      if (!this.formItems.id) {
        return this.$i18n.t("object.task.newTask").toString();
      }
      return this.$i18n.t("object.task.editTask").toString();
    },
    taskTitleLists() {
      return categoryModule.mainCategories;
    },
    taskSubtitleLists() {
      return categoryModule.subCategories.filter(
        (x) => x.parent?.id === this.formItems.category?.id
      );
    },
    serviceProviderList() {
      return serviceproviderModule.serviceProviderList;
    },
    disabledBtn() {
      return false;
    },
    formRules() {
      return formRules;
    },
    disableProblemField() {
      if (this.disabled) return this.disabled;
      if (!this.formItems.category || this.formItems.title == "Andere") {
        return this.disabled || true;
      }
      return false;
    },
    disableSummit() {
      const items = this.formItems;
      if (items.category && items.allocatedTo && items.street) {
        return !this.formValid || this.disabled;
      }
      return true;
    },
  },
  filters: {
    fixPhoneNo: (value: string) => {
      return value.trim().toUpperCase();
    },
  },
  methods: {
    allocatedToText(item) {
      return `${item?.firstName} ${item?.lastName}`;
    },
    onclose() {
      const task = new Task();
      this.formItems = task;
      this.triggerRefresh();
      this.$emit("onClose");
    },
    onSubmit() {
      this.$emit("onSubmit", this.formItems);
    },
    toggleEditMode() {
      this.$emit("toogleEdit");
    },
    triggerData() {
      this.$emit("input", this.formItems);
    },
    triggerRefresh() {
      this.$emit("reload");
    },
  },
});
</script>
