<template>
  <v-container
    fluid
    class="pt-2 pb-0 px-8"
    style="
      width: 794px;
      height: 1023px;
      padding-left: 300px;
      padding-right: 20px;
      font-family: Arial, sans-serif;
    "
  >
    <meta charset="UTF-8" />
    <v-card
      flat
      style="padding-left: 5%; padding-right: 5%"
      class="fill-height align-end mb-12 overflow-auto"
    >
      <v-row no-gutters class="pa-0" dense justify="end">
        <v-spacer></v-spacer>
        <v-col align-self="end">
          <v-container
            style="
              padding-right: 0%;
              display: flex;
              flex-direction: row;
              align-self: flex-end;
              align-items: end;
              justify-content: end;
            "
          >
            <v-img
              alt=""
              src="../../../assets/worker-svg.svg"
              max-height="72"
              max-width="72"
            />
          </v-container>
        </v-col>
      </v-row>

      <span class="fill-width" style="width: 100%">
        <v-row justify="space-between" no-gutters dense>
          <v-col
            cols="8"
            align-self="start"
            style="justify-self: start; text-align: left"
          >
            <span class="mb-4">
              <small
                style="
                  align-self: flex-start;
                  text-align: left;
                  font-size: 0.6rem;
                "
              >
                {{
                  `${companyData.name} - ${companyData.address?.street} - ${companyData.address?.postalCode} - ${companyData.address?.city}`
                }}
              </small>
            </span>
            <v-container class="px-0" style="padding-left: 0">
              <span v-for="(item, i) in recipientData" :key="i">
                <span
                  style="display: flex; flex-direction: row"
                  v-if="item.value"
                >
                  <small style="align-self: right">
                    {{ item.title }}
                  </small>
                  <small style="align-self: right">
                    {{ item.value }}
                  </small>
                </span>
              </span>
            </v-container>
          </v-col>
          <v-col cols="4" align-self="end" style="justify-self: end">
            <span
              v-for="(item, i) in invoiceDetails"
              :key="i"
              style="
                display: flex;
                flex-direction: row;
                justify-content: space-between;
              "
            >
              <small style="align-self: right">{{ item.title }}</small>
              <small style="align-self: right">{{ item.value }}</small>
            </span>
          </v-col>
        </v-row>

        <v-container
          style="
            margin-top: 80px;
            padding-top: 30px;
            padding-left: 0;
            padding-right: 0;
          "
          class="pa-0"
        >
          <v-container
            class="align-start text-start px-0 d-flex flex-column"
            style="padding-left: 0; text-align: start"
          >
            <p>
              <strong style="color: #0070c0">
                {{ invoiceText.invoiceNo }}
              </strong>
            </p>
            <p style="padding-bottom: 10px">
              <small>{{ invoiceText.greeting }}</small>
            </p>
            <p>
              <small>
                {{ invoiceText.text }}
              </small>
            </p>
          </v-container>

          <v-simple-table
            light
            dense
            style="border-radius: 0; margin-bottom: 10px"
            class="pa-0 mb-2 #0070c0"
          >
            <template v-slot:default>
              <thead style="background-color: #0070c0">
                <tr>
                  <th
                    v-for="(row, i) in tableHeaders"
                    :key="i"
                    style="color: white; text-align: start"
                  >
                    <strong style="white-space: nowrap">{{ row.text }}</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, i) in items" :key="i">
                  <td>{{ i + 1 }}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.customerName }}</td>
                  <td>{{ item.date }}</td>
                  <td class="text-right" style="text-align: right">
                    {{ item.price }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-divider style="background-color: #0070c0" />
          <!-- Totals Section -->
          <v-row justify="space-between" no-gutters dense>
            <v-spacer></v-spacer>
            <v-col cols="6" align-self="end" style="justify-self: end">
              <span v-for="(item, i) in totalData" :key="i">
                <span
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    margin-bottom: 5px;
                    margin-top: 5px;
                  "
                >
                  <strong
                    v-if="item.format"
                    style="align-self: right"
                    :style="item.colored ? 'color: #0070c0' : ''"
                  >
                    {{ item.title }}
                  </strong>
                  <small
                    style="align-self: right"
                    v-else
                    :style="item.colored ? 'color: #0070c0' : ''"
                  >
                    {{ item.title }}
                  </small>
                  <strong
                    v-if="item.format"
                    style="align-self: right"
                    :style="item.colored ? 'color: #0070c0' : ''"
                  >
                    {{ item.value }}
                  </strong>

                  <small
                    style="align-self: right"
                    v-else
                    :style="item.colored ? 'color: #0070c0' : ''"
                  >
                    {{ item.value }}
                  </small>
                </span>
              </span>
            </v-col>
          </v-row>
        </v-container>

        <!-- Footer Section -->
        <v-container
          class="text-start pb-8 px-0"
          style="
            text-align: start;
            display: flex;
            flex-direction: column;
            gap: 10px;
          "
        >
          <p>
            <small>
              {{ invoiceText.closingText }}
            </small>
          </p>

          <p>
            <small>
              {{ invoiceText.closingParagraph }}
            </small>
          </p>
          <span>
            <p style="padding: 0; margin: 0">
              <small> {{ invoiceText.endText }} </small>
            </p>
          </span>
          <span>
            <p style="padding: 0; margin: 0">
              <small> {{ invoiceText.salutation }} </small>
            </p>
            <p style="padding: 0; margin: 0">
              <small> {{ invoiceText.invoiceName }} </small>
            </p>
          </span>
        </v-container>
      </span>
    </v-card>

    <v-footer
      style="
        position: absolute;
        margin-top: 20%;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 120px;
        align-items: center;
      "
      fixed
      padlesss
      class="fill-width mt-12 pa-0"
      height="4000"
    >
      <v-container
        class="pa-0 fill-width fill-height align-center"
        fluid
        style="padding: 0%"
      >
        <v-row
          align-content="center"
          style="
            width: 100%;
            height: fit-content;
            padding: 2px;
            align-items: center;
          "
        >
          <v-col
            :cols="12 / footerData.length"
            v-for="(arr, i) in footerData"
            :key="i"
            align-self="center"
          >
            <span v-for="(item, i) in arr" :key="i">
              <span
                style="
                  position: relative;
                  display: flex;
                  flex-direction: row;
                  justify-content: left;
                "
                v-if="item.value"
              >
                <small v-if="item.title" style="text-align: left"
                  >{{ item.title }}
                </small>
                <small style="text-align: start">
                  {{ item.value }}
                </small>
              </span>
            </span>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </v-container>
</template>

<script lang="ts">
import { formatAmount } from "@/helpers/component-helper";
import organisationModule from "@/store/modules/organisation-module";
import {
  Invoice,
  Organisation,
  ServiceProvider,
} from "@taskmanagement/taskapp-model";
import moment from "moment";
import Vue from "vue";
import { DataTableHeader } from "vuetify";

type TDetail<T = string | null> = {
  title: T;
  value: T;
  format?: boolean;
  colored?: boolean;
};
type Item = {
  id: number;
  name: string;
  technicianPercent?: number;
  companyPercent?: number;
  customerName: string;
  date: Date | string;
  price: number;
};

type DataType = { blue: string };

type MethodType = {
  formatCurrency(amount: number): string;
  textChecker(title: string, text: string | undefined | null): string | null;
};

type ComputeType = {
  items: Item[];
  subtotal: number;
  companyRate: number;
  tax: number;
  total: number;
  recipient: ServiceProvider;
  tableHeaders: DataTableHeader[];
  recipientFullName: string;
  technicanAmount: number;
  invoiceDetails: TDetail[];
  footerData: TDetail[][];
  companyData: Organisation;
  totalData: TDetail[];
  recipientData: TDetail[];
  invoiceText: {
    invoiceNo: string;
    greeting: string;
    text: string;
    closingText: string;
    closingParagraph: string;
    endText: string;
    salutation: string;
    invoiceName: string;
  };
};

type PropType = {
  value: Invoice;
};

export default Vue.extend<DataType, MethodType, ComputeType, PropType>({
  name: "InvoicePdfLayoutComponent",
  props: {
    value: { type: Object },
  },
  data: () => ({
    blue: "#0070c0",
  }),
  computed: {
    items() {
      // const sp = this.value.serviceProvider;
      return this.value?.tasks?.map((x, i) => ({
        id: i + 1,
        name: x.ticketNumber,
        customerName: `${x.requesterFirstName} ${x.requesterLastName}`,
        date: moment(x.createdAt).locale("de").format("DD-MM-YYYY"),
        price: x.price,
      })) as Item[];
    },
    subtotal() {
      return (
        this.value?.tasks?.reduce((acc, x) => acc + Number(x.price), 0) ?? 0
      );
    },
    invoiceText() {
      return {
        invoiceNo: `Rechnung Nr. ${this.value?.invoiceNumber}`,
        greeting: `Sehr geehrte Damen und Herren,`,
        text: `vielen Dank für Ihren Auftrag und das damit verbundene
                Vertrauen! Hiermit stelle ich Ihnen die folgenden Leistungen in
                Rechnung:`,
        closingText: `Zahlungsbedingungen: Zahlung innerhalb von 3 Tagen ab
              Rechnungseingang ohne Abzüge.`,
        closingParagraph: `              Bitte überweisen Sie den Rechnungsbetrag unter Angabe der
              Rechnungsnummer auf das unten angegebene Konto.`,
        endText: `Der Rechnungsbetrag ist sofort fällig.`,
        salutation: `Mit freundlichen Grüßen`,
        invoiceName: `Abdusalam Abdullatif`,
      };
    },
    tax(): number {
      return (this.subtotal * this.companyRate) / 100;
    },
    technicanAmount() {
      const spRate = Number(
        this.value.serviceProvider?.technicianProfile.percentage
      );
      return (this.subtotal * spRate) / 100;
    },
    companyRate() {
      const spPercent =
        this.value.serviceProvider?.technicianProfile.percentage;
      return 100 - Number(spPercent);
    },
    total(): number {
      return Number(this.subtotal) - Number(this.technicanAmount);
    },
    recipient() {
      return this.value.serviceProvider;
    },
    recipientFullName() {
      const sp = this.recipient;
      return sp?.fullName ?? `${sp?.firstName} ${sp?.lastName}`;
    },
    tableHeaders() {
      return [
        { text: "Pos.", value: "name" },
        { text: "Auftragsnummer", value: "description" },
        { text: "Kunden", value: "date" },
        { text: "Umsatz", value: "date" },
        // { text: "% Techniker", value: "date" },
        // { text: "% Vermittlung", value: "date" },
        { text: "Gesamtpreis", value: "total" },
      ];
    },
    invoiceDetails() {
      const invoice = this.value;
      return [
        { title: "Rechnungsnr.:", value: invoice?.invoiceNumber ?? null },
        { title: "Kundennr.:", value: "000012" },
        { title: "Kunden-USt-IdNr.:", value: "DE1234567" },
        {
          title: "Datum:",
          value: invoice?.invoiceDate?.toString() ?? null,
        },
        {
          title: "Zahlung Faellig:",
          value: invoice?.paymentDue?.toString() ?? null,
        },
      ];
    },
    footerData() {
      const companyDetails = this.companyData;
      const address = this.companyData.address;
      const account = this.companyData.accountInfo;
      return [
        [
          { title: null, value: companyDetails.name ?? null },
          { title: null, value: `${address?.streetNo}` },
          {
            title: null,
            value: `${address?.postalCode ?? null} ${address?.city}`,
          },
          { title: null, value: address?.country ?? null },
        ],
        [
          {
            title: null,
            value: this.textChecker("Tel: ", this.companyData.phoneNo),
          },
          {
            title: null,
            value: this.textChecker("E-mail: ", this.companyData.email),
          },
          {
            title: null,
            value: this.companyData.address?.website ?? null,
          },
        ],
        [
          { title: null, value: address?.city ?? null },
          {
            title: null,
            value: this.textChecker("HR-Nr.: ", account?.hrID),
          },
          {
            title: null,
            value: this.textChecker("USt.-ID: ", account?.ustID),
          },
          {
            title: null,
            value: this.textChecker("Steuer-Nr.: ", account?.taxID),
          },
          {
            title: null,
            value: this.textChecker("Geschäftsführung: ", companyDetails.name),
          },
        ],
        [
          { title: null, value: account?.bankName ?? null },
          { title: "Konto: ", value: account?.accountNo ?? null },
          { title: "BLZ: ", value: account?.blz ?? null },
          { title: "IBAN: ", value: account?.iban ?? null },
          { title: "BIC: ", value: account?.bic ?? null },
        ],
      ];
    },
    companyData() {
      return organisationModule.data;
    },
    totalData() {
      return [
        {
          title: "Zwischenumme (netto)",
          value: this.formatCurrency(this.subtotal),
          colored: true,
        },
        {
          title: `Techniker (${this.value?.serviceProvider?.technicianProfile.percentage}%)`,
          value: "-" + this.formatCurrency(this.technicanAmount),
        },
        {
          title: `Vermittlung (${this.companyRate}%)`,
          value: this.formatCurrency(this.tax),
        },
        {
          title: `Gesamtbetrag`,
          value: this.formatCurrency(this.total),
          format: true,
          colored: true,
        },
      ];
    },
    recipientData() {
      const sp = this.recipient;
      return [
        { title: null, value: this.recipientFullName },
        { title: null, value: sp?.address.street ?? null },
        { title: null, value: `${sp?.address.postalCode} ${sp?.address.city}` },
        { title: null, value: sp?.address.country ?? null },
      ];
    },
  },
  methods: {
    formatCurrency(amount: number | string): string {
      return formatAmount(parseInt(amount as string), "de");
    },
    textChecker(title: string, text: string | undefined | null) {
      return text ? title + text : null;
    },
  },
});
</script>
