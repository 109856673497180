<template>
  <span>
    <v-card
      color="transparent"
      flat
      tile
      class="align-start justify-start rounded-lg"
    >
      <previewHeaderComponent
        class="mb-2"
        showDelete
        showEdit
        :title="$t('object.task.orderName')"
        @back="removePreview"
        @edit="editTask"
        @delete="deleteTask"
      />
      <orderDetailsComponent :order="task" />
    </v-card>
  </span>
</template>

<script lang="ts">
import {
  formatAmount,
  getFullName,
  headerEnumFormatter,
} from "@/helpers/component-helper";
import { TypeStageFormatter } from "@/model";
import { ROUTE_INVOICEDETAILS } from "@/model/routes";
import taskModule from "@/store/modules/task-module";
import Vue from "vue";
import { formatTime } from "../../../helpers/component-helper";

import PreviewHeaderComponent from "../previewheader-component.vue";
// eslint-disable-next-line prettier/prettier
import { Allocation, EnumStatus, EnumTaskStage, Invoice, Person, ServiceProvider, Task, } from "@taskmanagement/taskapp-model";
import OrderDetailsComponent from "./orderdetails-component.vue";

type ComputedType = {
  step: number;
  task: Task;
  progress: number;
  getTaskStage: TypeStageFormatter<EnumTaskStage> | null;
  getTaskStatus: TypeStageFormatter<EnumStatus> | null;
  serviceProvider: ServiceProvider;
  warningTask: boolean;
  invoice: Invoice;
  allocationList: Allocation[] | null;
};
type MethodType = {
  removePreview(): void;
  deleteTask(): void;
  editTask(): void;
  formatAmount(value: string | number): string;
  formatTime(time: string | Date): string;
  getFullName(sp: Person): string;
  allocationDesc(
    stage: EnumTaskStage
  ): TypeStageFormatter<EnumTaskStage> | null;
  goToInvoice(invoice: Invoice): void;
};
type DataType = {
  stages: typeof EnumTaskStage;
  status: typeof EnumStatus;
  spEdit: boolean;
};
type PropType = {
  taskStageDesc: Required<TypeStageFormatter<EnumTaskStage>>[];
  taskStatusDesc: Required<TypeStageFormatter<EnumStatus>>[];
  maxWidth: number;
  serviceProviderList: ServiceProvider[];
};

export default Vue.extend<DataType, MethodType, ComputedType, PropType>({
  name: "TaskPreviewComponent",
  components: {
    orderDetailsComponent: OrderDetailsComponent,
    previewHeaderComponent: PreviewHeaderComponent,
  },
  props: {
    taskStageDesc: { type: Array },
    maxWidth: { type: Number, default: 1200 },
    taskStatusDesc: { type: Array },
    serviceProviderList: { type: Array },
  },
  data: () => ({
    stages: EnumTaskStage,
    status: EnumStatus,
    spEdit: false,
  }),
  computed: {
    step() {
      return this.task?.stage;
    },
    task() {
      return taskModule.task;
    },
    progress() {
      const totalStages = this.taskStageDesc.length;
      const currentIndex = this.taskStageDesc?.findIndex(
        (stage) => stage.enum === this.step
      );
      if (currentIndex === -1) {
        return 0;
      }
      return ((currentIndex + 1) / totalStages) * 100;
    },
    getTaskStage() {
      return headerEnumFormatter(this.step, this.taskStageDesc);
    },
    getTaskStatus() {
      return headerEnumFormatter(this.task?.status, this.taskStatusDesc);
    },
    serviceProvider() {
      return this.task?.allocatedTo;
    },
    invoice() {
      return this.task.invoice ?? new Invoice();
    },
    warningTask() {
      return this.task.stage < this.stages.created;
    },
    allocationList() {
      return this.task.allocation ?? null;
    },
  },
  methods: {
    removePreview() {
      this.invoice = new Invoice();
      this.$emit("back");
    },
    deleteTask() {
      this.$emit("delete");
    },
    editTask() {
      this.$emit("edit");
    },
    formatTime(time: string) {
      return formatTime(time, undefined, this.$route.params.locale);
    },
    getFullName(sp) {
      return getFullName(sp) ?? "";
    },
    formatAmount(value: string | number) {
      return formatAmount(parseInt(value as string), this.$route.params.locale);
    },
    allocationDesc(stage: EnumTaskStage) {
      return headerEnumFormatter(stage, this.taskStageDesc);
    },
    goToInvoice(invoice: Invoice) {
      if (invoice && invoice.invoiceNumber) {
        this.$router.push({
          name: ROUTE_INVOICEDETAILS,
          params: { invoiceNumber: invoice.invoiceNumber },
        });
      }
    },
  },
});
</script>

<style></style>
