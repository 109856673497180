<template>
  <v-card rounded="xxl" color="transparent" flat>
    <v-list-item three-line>
      <v-list-item-avatar rounded="full" size="50" :color="avatarBgColor" left>
        <v-icon :color="iconColor">{{ value.icon }}</v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title class="text-h5 mb-0" :class="iconColor + '--text'">
          <strong> {{ value.value }} </strong>
        </v-list-item-title>
        <v-card-subtitle class="pa-0">
          <small style="white-space: normal">
            {{ value.description }}
          </small>
        </v-card-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-checkbox
      right
      :value="checked"
      :color="iconColor"
      readonly
      style="position: absolute; top: 0; right: 0"
    />
  </v-card>
</template>

<script lang="ts">
import { TypeStageFormatter } from "@/model";
import Vue from "vue";

type PropType = {
  value: TypeStageFormatter;
  avatarBgColor: string;
  iconColor: string;
  checked: boolean;
  showCheck: boolean;
};
type DataType = Record<string, string>;
type MethodType = Record<string, string>;
type ComputedType = Record<string, string>;

export default Vue.extend<DataType, MethodType, ComputedType, PropType>({
  name: "UserRoleComponent",
  data: () => ({}),
  props: {
    value: { type: Object },
    avatarBgColor: { type: String },
    iconColor: { type: String },
    checked: { type: Boolean },
    showCheck: { type: Boolean },
  },
});
</script>

<style></style>
