import Vue from "vue";
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from "vue-router";

import AppBarComponent from "../layout/appbar-component.vue";
import BottomNavigation from "../layout/bottomnavigation-component.vue";
import MainComponent from "../layout/main-component.vue";
import NavbarComponent from "../layout/navigation-component.vue";

import AuthView from "../views/auth-view.vue";
import DashboardView from "../views/DashboardView.vue";
import HomeView from "../views/HomeView.vue";
import InvoiceDetailView from "../views/InvoiceDetailView.vue";
import InvoiceView from "../views/InvoiceView.vue";
import OrderDetailsView from "../views/OrderDetailsView.vue";
import StatisticsView from "../views/statistics-view.vue";
import UsersView from "../views/UserView.vue";

import {
  convertLocaleToUrlLocale,
  defaultLanguage,
  getBrowserMainLanguage,
  getUrlLocaleToLocale,
  i18n,
  loadLanguageAsync,
  storageLangKey,
} from "./i18n";

import AdminEntryComponent from "@/components/admin/adminentry-component.vue";
import UserEntryComponent from "@/components/users/userentry-component.vue";
import { setCookies } from "@/helpers/cookies-helper";
import LoadingComponent from "@/layout/loading-component.vue";
import LocalLoadingComponent from "@/layout/localLoading-component.vue";
import UserLayout from "@/layout/user-layout.vue";
import {
  DEFAULT_ROUTE,
  ROUTE_ADMIN,
  ROUTE_ADMIN_ENTRY,
  ROUTE_AGENT_ORDER,
  ROUTE_AGENT_ORDERDETAIL,
  ROUTE_AUTH,
  ROUTE_HOME,
  ROUTE_INVOICE,
  ROUTE_INVOICE_NEW,
  ROUTE_INVOICEDETAILS,
  ROUTE_LOGIN,
  ROUTE_NEW,
  ROUTE_ORDER,
  ROUTE_ORDERDETAILS,
  ROUTE_STATISTICS,
  ROUTE_USERDETAIL,
  ROUTE_USERS,
  ROUTE_USERS_NEW,
} from "@/model/routes";
import AccessView from "@/views/AccessView.vue";
import AdminView from "@/views/AdminView.vue";
import AgentOrderDetailView from "@/views/AgentOrderDetailView.vue";
import AgentOrderView from "@/views/AgentOrderView.vue";
import ErrorView from "@/views/ErrorView.vue";
import InvoiceEntryView from "@/views/InvoiceEntryView.vue";
import RouteView from "@/views/RouteView.vue";
import UserDetails from "@/views/UserDetails.vue";
import { EnumRoles } from "@taskmanagement/taskapp-model";
import { catClient } from "../helpers/logging";
import { appName } from "../model/config";
import authModule from "../store/modules/auth-module";

const titlePrefix = appName + "-";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "",
    component: AuthView,
  },
  {
    path: "/:locale",
    components: {
      default: MainComponent,
      navigation: NavbarComponent,
      appbar: AppBarComponent,
      loader: LoadingComponent,
      bottomnav: BottomNavigation,
      localLoader: LocalLoadingComponent,
      error: ErrorView,
    },
    children: [
      {
        path: "",
        name: "",
        redirect: {
          name: DEFAULT_ROUTE,
        },
      },
      {
        path: ROUTE_LOGIN,
        name: ROUTE_LOGIN,
        component: AuthView,
        meta: {
          doLogin: true,
          nav: {
            icon: "mdi-login",
            title: () => i18n.t("site.route.login"),
          },
        },
      },
      {
        path: ROUTE_HOME,
        name: ROUTE_HOME,
        component: HomeView,
        meta: {
          isHidden: true,
          requireLogin: false,
          nav: {
            icon: "mdi-home",
            title: () => i18n.t("site.route.home"),
          },
        },
      },
      {
        path: ROUTE_AUTH,
        name: ROUTE_AUTH,
        component: AccessView,
        meta: {
          isHidden: true,
          requireLogin: false,
          nav: {
            title: () => i18n.t("site.route.home"),
          },
        },
      },
      {
        path: ROUTE_ORDER,
        components: { default: RouteView },
        children: [
          {
            path: "",
            name: ROUTE_ORDER,
            component: DashboardView,
            meta: {
              requireLogin: true,
              roles: allowedRouteAccess(EnumRoles.SUPPORT),
              nav: {
                icon: "mdi-view-dashboard-outline",
                desc: i18n.t("object.home.orderDesc").toString(),
                title: () => i18n.t("object.task.listName"),
              },
            },
          },
          {
            path: ROUTE_AGENT_ORDER.substring(ROUTE_ORDER.length),
            components: { default: RouteView },
            children: [
              {
                path: "",
                name: ROUTE_AGENT_ORDER,
                component: AgentOrderView,
                meta: {
                  requireLogin: true,
                  roles: [EnumRoles.AGENT],
                  nav: {
                    icon: "mdi-view-dashboard-outline",
                    title: () => i18n.t("object.task.listName"),
                    desc: i18n.t("object.home.orderDesc").toString(),
                  },
                },
              },
              {
                path: `:ticketNumber`,
                name: ROUTE_AGENT_ORDERDETAIL,
                component: AgentOrderDetailView,
                meta: {
                  isHidden: true,
                  requireLogin: true,
                  roles: [EnumRoles.AGENT],
                  nav: {
                    title: () => i18n.t("object.task.orderName"),
                  },
                },
              },
            ],
          },
          {
            path: `:ticketNumber`,
            name: ROUTE_ORDERDETAILS,
            component: OrderDetailsView,
            meta: {
              isHidden: true,
              requireLogin: true,
              roles: allowedRouteAccess(EnumRoles.SUPPORT),
              nav: {
                title: () => i18n.t("object.task.orderName"),
              },
            },
          },
        ],
      },
      {
        path: ROUTE_STATISTICS,
        name: ROUTE_STATISTICS,
        component: StatisticsView,
        meta: {
          roles: allowedRouteAccess(EnumRoles.ADMIN),
          requireLogin: true,
          nav: {
            icon: "fa-line-chart",
            desc: i18n.t("object.home.statisticsDesc"),
            title: () => i18n.t("site.route.statistics"),
          },
        },
      },
      {
        path: ROUTE_INVOICE,
        components: { default: RouteView },
        children: [
          {
            path: "",
            name: ROUTE_INVOICE,
            component: InvoiceView,
            meta: {
              roles: allowedRouteAccess(EnumRoles.SUPPORT),
              requireLogin: true,
              nav: {
                icon: "fa-file-alt",
                desc: i18n.t("object.home.invoiceDesc"),
                title: () => i18n.t("site.route.invoice"),
              },
            },
          },
          {
            path: ROUTE_NEW,
            name: ROUTE_INVOICE_NEW,
            component: InvoiceEntryView,
            meta: {
              requireLogin: true,
              isHidden: true,
              nav: {
                title: () => i18n.t("site.route.invoice"),
              },
            },
          },
          {
            path: `:invoiceNumber`,
            name: ROUTE_INVOICEDETAILS,
            component: InvoiceDetailView,
            meta: {
              requireLogin: true,
              isHidden: true,
              nav: {
                title: () => i18n.t("site.route.invoice"),
              },
            },
          },
        ],
      },
      {
        path: ROUTE_USERS,
        components: { default: UserLayout },
        children: [
          {
            path: "",
            name: ROUTE_USERS,
            component: UsersView,
            meta: {
              requireLogin: true,
              roles: allowedRouteAccess(EnumRoles.MANAGER),
              nav: {
                icon: "fa-users-cog",
                title: () => i18n.t("object.user.users.name"),
              },
            },
          },
          {
            path: ROUTE_NEW,
            name: ROUTE_USERS_NEW,
            component: UserEntryComponent,
            meta: {
              requireLogin: true,
              isHidden: true,
              nav: {
                title: () => "New User",
              },
            },
          },
          {
            path: ":username",
            name: ROUTE_USERDETAIL,
            component: UserDetails,
            meta: {
              requireLogin: true,
              isHidden: true,
              nav: {
                title: () => "User Detail",
              },
            },
          },
        ],
      },
      {
        path: ROUTE_ADMIN,
        components: { default: RouteView },
        children: [
          {
            path: "",
            name: ROUTE_ADMIN,
            component: AdminView,
            meta: {
              roles: allowedRouteAccess(EnumRoles.ADMIN),
              requireLogin: true,
              nav: {
                icon: "fa-sitemap",
                desc: i18n.t("object.home.workerDesc"),
                title: () => i18n.t("site.route.admin"),
              },
            },
          },
          {
            path: ROUTE_ADMIN_ENTRY.substring(ROUTE_ADMIN.length),
            name: ROUTE_ADMIN_ENTRY,
            component: AdminEntryComponent,
            meta: {
              roles: allowedRouteAccess(EnumRoles.ADMIN),
              isHidden: true,
              requireLogin: true,
              nav: {
                icon: "fa-sitemap",
                title: () => "Register Org",
              },
            },
          },
        ],
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;

router.beforeEach((to, _from, next) => {
  const localeFromRoute =
    to.params.locale ?? convertLocaleToUrlLocale(getBrowserMainLanguage());

  const locale = getUrlLocaleToLocale(localeFromRoute) ?? defaultLanguage;

  setCookies(storageLangKey, locale);

  if (i18n.locale !== locale) {
    loadLanguageAsync(locale)
      .then(() => executeRouterBeforeEach(locale, to, localeFromRoute, next))
      .catch((error) =>
        catClient.error("Error before route execution" + error)
      );
  } else {
    executeRouterBeforeEach(locale, to, localeFromRoute, next);
  }
});

router.afterEach((to) => {
  // UseNext tick to handle router history correctly
  Vue.nextTick(() => {
    // eslint-disable-next-line prettier/prettier
    document.title = to.meta?.title ?? (to.meta?.nav?.title() ? titlePrefix + to.meta?.nav?.title() : DEFAULT_ROUTE);
  });
});

// eslint-disable-next-line prettier/prettier
function executeRouterBeforeEach(locale: string, to: Route, localeFromRoute: string, next: NavigationGuardNext<Vue>): void {
  const params = to.params;
  const query = to.query;
  // except token from Locale check
  if (
    !to.meta?.useNoLocaleCheck &&
    convertLocaleToUrlLocale(locale) !== to.params.locale
  ) {
    if (to.params.locale !== undefined) {
      Vue.$toast.error(
        `The selected language ${localeFromRoute} is not supported`
      );
    }
    next({
      name: to.name ?? DEFAULT_ROUTE,
      params: { locale: convertLocaleToUrlLocale(locale) },
    });
  } else if (to.matched.some((record) => record.meta.doLogin)) {
    if (authModule.isLoggedIn) {
      next({
        name: DEFAULT_ROUTE,
        params,
        query,
      });
    }
  } else if (to.matched.some((record) => record.meta.requireLogin)) {
    if (!authModule.isLoggedIn) {
      next({
        name: ROUTE_LOGIN,
        params: { ...to.params },
        query: {
          ...to.query,
          redirect_url: to.fullPath,
        },
      });
    } else if (to.meta?.roles && to.meta?.roles.length > 0) {
      const hasRoles = !to.meta?.roles.some(
        (role: EnumRoles) => authModule.role === role
      );

      if (hasRoles) {
        Vue.$toast(`You do not have enough rights to access this route`);
        next({ name: DEFAULT_ROUTE, params, query });
      }
    } else {
      next();
    }
  } else {
    if (
      to.matched.length === 0 ||
      to.matched.some(
        (x) =>
          x.parent !== undefined &&
          x.components.default === undefined &&
          x.redirect === undefined
      )
    ) {
      catClient.error(`Route ${to.path} not found!`, null);
      Vue.$toast.error(`The given site ${to.params.pathMatch ?? to.path}`);
      next({ name: DEFAULT_ROUTE });
    }
  }
  next();
}

export function isDefaultLanguageInRoute(): boolean {
  return (
    router.currentRoute.params.locale ==
    convertLocaleToUrlLocale(defaultLanguage)
  );
}

export function allowedRouteAccess(minAllowedRole: EnumRoles): EnumRoles[] {
  return Object.values(EnumRoles).filter(
    (y) => Number(y) <= minAllowedRole
  ) as EnumRoles[];
}

export function removeAccessFrom(roles: EnumRoles[]) {
  return Object.values(EnumRoles).filter((y) => !roles.includes(Number(y)));
}

export function selectedRoles(roles: EnumRoles[]) {
  return [...roles, EnumRoles.SUPERUSER];
}
