<template>
  <v-card flat class="form-background pa-4 fill-height">
    <v-card max-width="600" min-height="500">
      <loginFormComponent />
    </v-card>
  </v-card>
</template>

<script lang="ts">
import Vue from "vue";
import LoginFormComponent from "../subcomponent/auth/loginform-component.vue";
// import SignupFormComponent from "./subcomponent/signupform-component.vue";

export default Vue.extend({
  name: "LoginComponent",
  components: {
    loginFormComponent: LoginFormComponent,
  },
});
</script>

<style scoped>
.form-background {
  background-image: url("@/assets/gradientbg.jpg"); /* Path to your image */
  background-size: cover; /* Ensures the image covers the entire area */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents repeating */
}
</style>
