<template>
  <agentOrderComponent />
</template>

<script lang="ts">
import allocationModule from "@/store/modules/allocation-module";
import taskModule from "@/store/modules/task-module";
import Vue from "vue";
import AgentOrderComponent from "../../subcomponent/order/agent/agentorder-component.vue";

export default Vue.extend({
  name: "AgentOrderComponent",
  components: {
    agentOrderComponent: AgentOrderComponent,
  },
  mounted() {
    this.loadOrderData();
  },
  methods: {
    loadOrderData() {
      this.$store.state.loading = true;
      taskModule.retriveTaskList().then(allocationModule.retrieveList);
    },
  },
});
</script>

<style></style>
