import {
  ServiceProvider,
  SummaryStatistics,
  TotalBaseStatistics,
} from "@taskmanagement/taskapp-model";
import {
  Action,
  Module,
  Mutation,
  VuexModule,
  getModule,
} from "vuex-module-decorators";
import store from "..";
import { StatisticsService } from "../../services/statistics-service";

@Module({
  name: "statistics",
  store,
  dynamic: true,
})
class StatisticsModule extends VuexModule {
  serviceProvider: TotalBaseStatistics<ServiceProvider["technicianProfile"]> =
    {};
  totals: TotalBaseStatistics<ServiceProvider["technicianProfile"]>["total"] =
    {};
  items: TotalBaseStatistics<ServiceProvider["technicianProfile"]>["items"] =
    [];

  summary: SummaryStatistics = new SummaryStatistics();

  get technician() {
    return this.summary.serviceProvider;
  }

  get order() {
    return this.summary.order;
  }
  get user() {
    return this.summary.user;
  }

  @Action({ commit: "getServiceProvider" })
  retrieveServiceProviderStat() {
    return new StatisticsService().getAll();
  }

  @Action({ commit: "setSummary" })
  getSummary() {
    return new StatisticsService().getSummary();
  }

  @Mutation
  getServiceProvider(
    serviceProvider: TotalBaseStatistics<ServiceProvider["technicianProfile"]>
  ) {
    this.serviceProvider = serviceProvider;
    this.totals = serviceProvider.total;
    this.items = serviceProvider.items;
  }

  @Mutation
  setSummary(summary: SummaryStatistics) {
    if (summary) {
      this.summary = summary;
    }
  }
}

export default getModule(StatisticsModule);
