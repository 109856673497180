<template>
  <v-container>
    <v-row>
      <v-col></v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "UserDescriptionComponent",
  props: {
    user: { type: Object },
  },
});
</script>

<style></style>
