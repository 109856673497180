<template>
  <v-container class="pa-0">
    <v-card
      class="fill-width align-start"
      :outlined="outlined"
      :height="height"
      :flat="flat"
    >
      <v-card-title class="my-0 pb-0">
        <strong>{{ title }}</strong>
        <v-spacer />
        <v-btn text color="primary" @click="onChange" v-if="!hideEdit">
          {{ $t("object.label.edit") }}
        </v-btn>
      </v-card-title>
      <v-divider class="mx-2" v-if="!hideEdit" />
      <v-container class="px-10">
        <v-row
          v-for="(item, i) in items"
          :key="i"
          justify="center"
          align="center"
          align-content="center"
          :dense="dense"
        >
          <v-col :cols="titleCols" align="start" v-if="item.condition ?? true">
            {{ item.title }}
          </v-col>
          <v-col :cols="valueCols" align="start" v-if="item.condition ?? true">
            <strong> {{ item.value }} </strong>
          </v-col>

          <v-divider class="mx-2 grey lighten-5" />
        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>

<script lang="ts">
import { InformationType } from "@/model";
import Vue from "vue";

type PropType = {
  title: string | null;
  items: InformationType[];
  hideEdit: boolean;
  titleCols: number;
  valueCols: number;
  flat: boolean;
  dense: boolean;
  outlined: boolean;
  height: string | number;
};
type MethodType = { onChange(): void };
type DataType = Record<string, string>;
type ComputedType = Record<string, string>;

export default Vue.extend<DataType, MethodType, ComputedType, PropType>({
  name: "DetailCardComponent",
  props: {
    title: { type: String },
    items: { type: Array },
    hideEdit: { type: Boolean },
    titleCols: { type: Number, default: 6 },
    valueCols: { type: Number, default: 6 },
    flat: { type: Boolean },
    dense: { type: Boolean },
    outlined: { type: Boolean },
    height: { type: String },
  },
  methods: {
    onChange() {
      this.$emit("change");
    },
  },
});
</script>

<style></style>
