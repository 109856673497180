<template>
  <span>
    <adminOverviewComponent v-if="orgData?.id" />
    <v-card v-else class="fill-height" flat>
      <v-img
        src="../../assets/formempty.jpeg"
        contain
        max-width="520"
        max-height="520"
      />
      <v-card-text>
        {{ $t("object.label.orgDataEmpty") }}
      </v-card-text>
      <v-btn color="primary" @click="openForm">
        <v-icon left>fa-cloud-upload</v-icon>
        {{ $t("object.org.enterOrg") }}
      </v-btn>
    </v-card>
  </span>
</template>

<script lang="ts">
import { ROUTE_ADMIN_ENTRY } from "@/model/routes";
import organisationModule from "@/store/modules/organisation-module";
// eslint-disable-next-line prettier/prettier
import Vue from "vue";
import AdminOverviewComponent from "../subcomponent/admin/adminoverview-component.vue";

export default Vue.extend({
  name: "AdminComponent",
  data: () => ({
    edit: null as boolean | null,
    editType: null as string | null,
  }),
  components: {
    adminOverviewComponent: AdminOverviewComponent,
  },
  computed: {
    orgData() {
      return organisationModule.data;
    },
  },
  methods: {
    openForm() {
      this.$router.push({ name: ROUTE_ADMIN_ENTRY });
    },
  },
});
</script>

<style></style>
