<template>
  <v-container class="transparent" flat>
    <newUsersComponent />
  </v-container>
</template>

<script lang="ts">
import Vue from "vue";
import NewUsersComponent from "../subcomponent/users/newusers-component.vue";

export default Vue.extend({
  name: "UserEntryComponent",
  components: {
    newUsersComponent: NewUsersComponent,
  },
});
</script>

<style></style>
