import localAxios from "@/helpers/axios-helper";
import {
  Allocation,
  CONFIRM_SP_DECISION_ROUTE,
  DECISION_ROUTE,
} from "@taskmanagement/taskapp-model";
type DecisionType = { current: Allocation; pendings: Allocation[] };
const URL = DECISION_ROUTE;
export class ApprovalService {
  private axios = localAxios;
  getApproval(ticketNumber: string, id: string) {
    return this.axios
      .get(URL, {
        params: { ticketNumber, id },
      })
      .then((value) => value.data as DecisionType);
  }

  updateApproval(value: Allocation) {
    return this.axios
      .post(`${CONFIRM_SP_DECISION_ROUTE}`, value)
      .then((val) => val.data);
  }
}
