import { CategoryService } from "@/services/category-service";
import {
  Categories,
  MainCategory,
  SubCategory,
} from "@taskmanagement/taskapp-model";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import store from "..";

@Module({
  name: "category",
  store,
  dynamic: true,
})
class CategoryModule extends VuexModule {
  allCategoryList: Categories[] = [];
  mainCategories: MainCategory[] = [];
  subCategories: SubCategory[] = [];

  @Action
  LoadAllCategories() {
    return Promise.all([
      this.getAllCategories(),
      this.getMainCategoryList(),
      this.getSubCategoryList(),
    ]);
  }

  @Action({ commit: "setAllCategories" })
  getAllCategories() {
    return new CategoryService().getAll();
  }

  @Action({ commit: "setMainCategoryList" })
  getMainCategoryList() {
    return new CategoryService().getAllMainCategory().then((res) => {
      if (res) return res;
    });
  }

  @Action({ commit: "setSubCategoryList" })
  getSubCategoryList() {
    return new CategoryService().getAllSubCategory().then((res) => {
      if (res) return res;
    });
  }

  @Mutation
  setAllCategories(categoryList: Categories[]) {
    this.allCategoryList.length = 0;
    this.allCategoryList = categoryList;
  }

  @Mutation
  setMainCategoryList(categoryList: MainCategory[]) {
    this.mainCategories.length = 0;
    this.mainCategories = categoryList;
  }
  @Mutation
  setSubCategoryList(categoryList: SubCategory[]) {
    this.subCategories.length = 0;
    this.subCategories = categoryList;
  }
}

export default getModule(CategoryModule);
