import { loadDataOnLogIn } from "@/helpers/auth-helpers";
import { redirectToRedirectUrl } from "@/helpers/router-helpers";
import {
  LoginUser,
  TokenResponse,
  User,
  authAccessTokenName,
  authRecordName,
} from "@taskmanagement/taskapp-model";
import {
  Action,
  Module,
  Mutation,
  VuexModule,
  getModule,
} from "vuex-module-decorators";
import store from "..";
import {
  deleteCookie,
  getCookies,
  setCookies,
} from "../../helpers/cookies-helper";
import AuthService from "../../services/auth-service";

@Module({
  name: "auth",
  store,
  dynamic: true,
})
class AuthModule extends VuexModule {
  private token = getCookies(authAccessTokenName) ?? null;
  private loggedInUser = getCookies(authRecordName) ?? null;

  get isLoggedIn() {
    return this.token !== null;
  }

  get jwtToken(): string {
    if (this.token) return this.token;
    return "";
  }

  get activeUser(): LoginUser | null {
    if (this.loggedInUser) return JSON.parse(this.loggedInUser);
    return null;
  }

  get role(): User["role"] {
    return this.activeUser?.role;
  }

  get permissions() {
    return this.activeUser?.permissions ?? null;
  }

  @Action
  loginUser(user: LoginUser) {
    return AuthService.loginUser(user).then((res) => {
      if (res && res.token) {
        this.context.commit("setToken", res);
        return res;
      }
      return;
    });
  }

  @Action({ commit: "destroyToken" })
  logoutUser() {
    return Promise.resolve(true).catch((error) => Promise.reject(error));
  }

  @Mutation
  setToken(
    record: TokenResponse & {
      loginUser: LoginUser & { iat: number; exp: number };
    }
  ) {
    if (record.token) {
      const expires = new Date(record.loginUser.exp * 1000);
      if (!getCookies(authAccessTokenName) || !getCookies(authRecordName)) {
        setCookies(authAccessTokenName, record.token, { expires });
        setCookies(authRecordName, JSON.stringify(record.loginUser), {
          expires,
        });
        this.token = record.token;
        this.loggedInUser = JSON.stringify(record.loginUser);
        redirectToRedirectUrl();
        loadDataOnLogIn();
      }
    }
  }

  @Mutation
  destroyToken() {
    deleteCookie(authAccessTokenName);
    deleteCookie(authRecordName);
    window.location.reload();
    this.token = null;
    this.loggedInUser = null;
  }
}

export default getModule(AuthModule);
