export const ROUTE_NEW = "new";
export const ROUTE_HOME = "home";
export const ROUTE_ADMIN = "admin";
export const ROUTE_AUTH = "auth";
export const ROUTE_ADMIN_ENTRY = ROUTE_ADMIN + "entry";
export const ROUTE_DASHBOARD = "dashboard";
export const ROUTE_ORDER = "order";
export const ROUTE_AGENT_ORDER = ROUTE_ORDER + "ag";
export const ROUTE_AGENT_ORDERDETAIL = "agentdetail";
export const ROUTE_REQUESTER = "requester";
export const ROUTE_APPROVAL = "approval";
export const ROUTE_LOGIN = "login";
export const ROUTE_ORDERDETAILS = "orderdetail";
export const ROUTE_INVOICEDETAILS = "invoicedetail";
export const ROUTE_STATISTICS = "statistics";
export const ROUTE_INVOICE = "invoice";
export const ROUTE_INVOICE_NEW = ROUTE_INVOICE + ROUTE_NEW;
export const ROUTE_WORKER_PROFILE = "serviceproviderprofile";
export const DEFAULT_ROUTE = ROUTE_HOME;

export const ROUTE_USERDETAIL = "userdetails";
export const ROUTE_USERS = "users";
export const ROUTE_USERS_NEW = ROUTE_USERS + ROUTE_NEW;
