import {
  Categories,
  CATEGORIES_ROUTE,
  MAIN_CATEGORIES_ROUTE,
  MainCategory,
  SUB_CATEGORIES_ROUTE,
  SubCategory,
} from "@taskmanagement/taskapp-model";
import { BaseService } from "./base-service";

const url = CATEGORIES_ROUTE;
export class CategoryService extends BaseService<Categories> {
  constructor() {
    super(url);
  }

  getAllMainCategory() {
    return this.axios
      .get(MAIN_CATEGORIES_ROUTE)
      .then((res) => res.data as MainCategory[]);
  }

  getAllSubCategory() {
    return this.axios
      .get(SUB_CATEGORIES_ROUTE)
      .then((res) => res.data as SubCategory[]);
  }
}
