<template>
  <v-container class="py-0">
    <v-card-text outlined class="error--text text-start pa-0">
      <v-icon color="warning"> mdi-alert-circle </v-icon>
      {{ $t("object.misc.detailsRestrict") }}
    </v-card-text>
    <v-container class="align-center justify-center my-auto">
      <v-divider class="py-2" />

      <v-row dense v-for="(client, i) in clientInformation" :key="i">
        <v-col cols="6" align="start">
          {{ client.title }}
        </v-col>
        <v-col cols="6" align="end">
          <strong> {{ client.value }} </strong>
        </v-col>
      </v-row>

      <v-card-subtitle class="error--text mt-auto"> </v-card-subtitle>
    </v-container>
  </v-container>
</template>

<script lang="ts">
import { tastTimeDisplay } from "@/helpers/component-helper";
import { setAcceptTask, setDeclineTask } from "@/helpers/decision-helpers";
import { DefaultVueType, InformationType } from "@/model";
import { Allocation } from "@taskmanagement/taskapp-model";

import Vue from "vue";

type PropType = { value: Allocation };
type MethodType = {
  accept(): void;
  decline(): void;
  setLoading(val: boolean): void;
};
type DataType = DefaultVueType;
type ComputedType = { clientInformation: InformationType[] };

export default Vue.extend<DataType, MethodType, ComputedType, PropType>({
  name: "ApprovalFirstStageComponent",

  props: {
    value: { type: Object },
  },
  computed: {
    clientInformation() {
      const task = this.value?.task;
      return [
        {
          title: this.$i18n.t("object.task.title"),
          value: task?.category?.name,
        },
        {
          title: this.$i18n.t("object.task.description"),
          value: task?.subCategory?.name,
        },
        {
          title: this.$i18n.t("object.task.appTime"),
          value: tastTimeDisplay(task, this.$route) as string,
        },
        {
          title: this.$i18n.t("object.person.postalCode"),
          value: task.postalCode,
        },
        {
          title: this.$i18n.t("object.person.city"),
          value: task.city,
        },
      ];
    },
  },
  methods: {
    accept() {
      const alloc = this.value;
      this.setLoading(true);
      setAcceptTask(alloc).finally(() => this.setLoading(false));
    },
    decline() {
      this.setLoading(true);
      setDeclineTask(this.value).finally(() => this.setLoading(false));
    },
    setLoading(val) {
      this.$emit("load", val);
    },
  },
});
</script>
