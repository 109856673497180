<template>
  <router-view />
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "RouteView",
});
</script>

<style></style>
