<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="4">
        <v-container class="pa-0">
          <v-card outlined>
            <v-row dense no-gutters>
              <v-col cols="4" v-if="orderStage == stage.viewed">
                <countDownTimerComponent
                  v-model="allocation.viewTime"
                  width="12"
                />
              </v-col>
              <v-col :cols="orderStage == stage.viewed && 8" align-self="start">
                <v-card-text class="text-start" v-if="timeDisplayText.value">
                  {{ timeDisplayText.text }}:
                  <v-chip label outlined>
                    <v-icon left small>mdi-clock-outline</v-icon>
                    <strong>{{ timeDisplayText.value }}</strong>
                  </v-chip>
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>

          <!-- <v-expansion-panels
            accordion
            v-if="orderData.comment && orderStage === stage.accepted"
          >
            <v-expansion-panel v-for="(item, i) in expansibleItems" :key="i">
              <v-expansion-panel-header v-if="item.header">
                {{ item.header }}
              </v-expansion-panel-header>
              <v-expansion-panel-content v-if="item.text">
                <v-card-text class="text-start pa-0">
                  {{ item.text }}
                </v-card-text>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels> -->
        </v-container>
      </v-col>
      <v-col cols="12" md="8">
        <orderServiceComponent :allocation="allocation" v-if="allocation" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import CountDownTimerComponent from "@/components/subcomponent/countdowntimer-component.vue";
import OrderServiceComponent from "@/components/subcomponent/order/agent/orderservice-component.vue";
import orderserviceModule from "@/store/modules/orderservice-module";
import { EnumTaskStage } from "@taskmanagement/taskapp-model";
import moment from "moment";
import Vue from "vue";

export default Vue.extend({
  name: "OrderServiceComponent",
  components: {
    orderServiceComponent: OrderServiceComponent,
    countDownTimerComponent: CountDownTimerComponent,
  },
  data: () => ({
    stage: EnumTaskStage,
  }),
  computed: {
    allocation() {
      return orderserviceModule.orderRequest;
    },
    orderData() {
      return orderserviceModule.orderRequest.task;
    },
    orderStage() {
      return orderserviceModule.orderRequest.stage;
    },
    timeDisplayText() {
      const order = orderserviceModule.orderRequest;
      const step = order.stage;
      switch (step) {
        case EnumTaskStage.viewed:
          return {
            text: this.$t("object.allocation.viewedOn"),
            value: order.viewTime ? moment(order.viewTime).format("LLL") : null,
          };
        case EnumTaskStage.accepted:
          return {
            text: this.$t("object.allocation.acceptedOn"),
            value: order.acceptTime
              ? moment(order.acceptTime).format("LLL")
              : null,
          };
        case EnumTaskStage.done:
          return {
            text: this.$t("object.misc.completedOn"),
            value: order.completedTime
              ? moment(order.completedTime).format("LLL")
              : null,
          };
        default:
          return {
            text: "",
            value: null,
          };
      }
    },
    expansibleItems() {
      // const order = orderserviceModule.orderRequest;
      return [
        {
          header: null, //this.$t("object.misc.comment"),
          text: null,
        },
      ];
    },
  },
});
</script>

<style></style>
