<template>
  <homeComponent />
</template>

<script lang="ts">
import Vue from "vue";
import HomeComponent from "../components/home/home-component.vue";

export default Vue.extend({
  name: "HomeView",

  components: {
    homeComponent: HomeComponent,
  },
});
</script>
