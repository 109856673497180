<template>
  <adminComponent />
</template>

<script lang="ts">
import AdminComponent from "@/components/admin/admin-component.vue";
import Vue from "vue";

export default Vue.extend({
  name: "AdminView",
  components: {
    adminComponent: AdminComponent,
  },
});
</script>

<style></style>
