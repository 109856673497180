<template>
  <span>
    <invoicedetail-component />
    <invoicePreviewComponent
      v-if="false"
      :invoiceStages="invoiceStages"
      v-model="invoice"
    />
  </span>
</template>

<script lang="ts">
import formatterModule from "@/store/modules/formatter-module";
import invoiceModule from "@/store/modules/invoice-module";
import Vue from "vue";
import InvoicedetailComponent from "../subcomponent/invoice/invoicedetail-component.vue";
import InvoicePreviewComponent from "../subcomponent/invoice/invoicepreview-component.vue";

export default Vue.extend({
  name: "InvoiceDetailsComponent",
  components: {
    invoicePreviewComponent: InvoicePreviewComponent,
    InvoicedetailComponent,
  },
  computed: {
    invoice() {
      return invoiceModule.invoice;
    },
    invoiceStages() {
      return formatterModule.invoiceStages;
    },
    inEdit() {
      return this.$route.query.mode === "edit";
    },
  },
  mounted() {
    this.getInvoiceData();
  },
  methods: {
    getInvoiceData() {
      const invoiceNumber = this.$route.params.invoiceNumber;
      if (!invoiceNumber) {
        this.$router.back();
        return;
      }
      invoiceModule.retrieveInvoiceByInvNumber(invoiceNumber);
    },
  },
});
</script>

<style></style>
