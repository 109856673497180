import { Identification } from "@taskmanagement/taskapp-model/dist/model/base-model";
// eslint-disable-next-line prettier/prettier
import localAxios from "@/helpers/axios-helper";
import { QueryParams } from "@/model";
import store from "@/store";
import {
  handleGlobalCreate,
  handleGlobalError,
  handleGlobalUpdate,
} from "../helpers/errors";

export abstract class BaseService<T extends Identification> {
  private url: string;
  public axios = localAxios;
  protected params?: QueryParams<T>;

  constructor(url: string) {
    this.url = url;
  }

  getAll(params?: QueryParams<T>): Promise<T[] | void> {
    return this.axios
      .get(this.url, { params })
      .then((value) => {
        return value.data as T[];
      })
      .catch((err) => handleGlobalError(err));
  }

  getById(id: number) {
    return this.axios
      .get(`${this.url}/${id}`)
      .then((value) => value.data as T)
      .catch((err) => handleGlobalError(err));
  }

  getOne(data: T): Promise<T | void> {
    store.state.isLoading = true;
    return this.axios
      .get(`${this.url}/${data.id}`)
      .then((value) => value.data as T)
      .finally(() => {
        store.state.isLoading = false;
      })
      .catch((err) => handleGlobalError(err));
  }

  create(data: T): Promise<T | void> {
    store.state.isLoading = true;
    return this.axios
      .post(this.url, data)
      .then((value) => handleGlobalCreate(value.data))
      .finally(() => {
        store.state.isLoading = false;
      })
      .catch((err) => handleGlobalError(err));
  }

  update(data: Partial<T>): Promise<T | void> {
    return this.axios
      .put(`${this.url}/${data.id}`, data)
      .then((value) => handleGlobalUpdate<T>(value.data))
      .catch((err) => handleGlobalError(err));
  }

  createOrUpdate(data: T | undefined): Promise<void | T> | undefined {
    if (data) {
      if (data.id) return this.update(data);
      return this.create(data);
    }
    throw new Error("Bad Request");
  }

  delete(data: T): Promise<T | void> {
    store.state.isLoading = true;
    return this.axios
      .delete(`${this.url}/${data.id}`)
      .then((value) => value.data as T)
      .finally(() => {
        store.state.isLoading = false;
      })
      .catch((err) => handleGlobalError(err));
  }
}
