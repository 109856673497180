<template>
  <v-card flat color="transparent">
    <v-card-title class="pa-0">
      <v-btn text icon @click="back" color="primary" v-if="!hideBack" left>
        <v-icon large>mdi-arrow-left</v-icon>
      </v-btn>
      <strong class="text-h4" style="font-weight: 600"> {{ title }} </strong>

      <v-spacer />

      <v-card rounded="pill" flat>
        <v-card-actions class="pa-0">
          <v-btn fab text small left v-if="showEdit" @click="edit">
            <v-icon color="primary">mdi-pencil-outline</v-icon>
          </v-btn>
          <v-btn fab text small v-if="showDelete" @click="del">
            <v-icon color="error">mdi-trash-can-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-card-title>
  </v-card>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "PreviewHeaderComponent",
  props: {
    showEdit: { type: Boolean },
    showDelete: { type: Boolean },
    title: { type: String },
    hideBack: { type: Boolean },
  },
  methods: {
    back() {
      this.$emit("back");
    },
    edit() {
      this.$emit("edit");
    },
    del() {
      this.$emit("delete");
    },
  },
});
</script>

<style></style>
