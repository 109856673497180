<template>
  <access-component />
</template>

<script lang="ts">
import accessComponent from "@/components/access/access-component.vue";
import Vue from "vue";

export default Vue.extend({
  components: { accessComponent },
  name: "AccessView",
});
</script>

<style></style>
