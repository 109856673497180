import { OrganisationService } from "@/services/organisation-service";
import { Organisation } from "@taskmanagement/taskapp-model";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import store from "..";

@Module({
  name: "organisation",
  store,
  dynamic: true,
})
class OrganisationModule extends VuexModule {
  data = new Organisation();
  get address() {
    return this.data?.address ?? null;
  }
  get account() {
    return this.data?.accountInfo ?? null;
  }

  @Action({ commit: "setOrganisation" })
  getOrganisation() {
    return new OrganisationService()
      .getAll()
      .then((org) => {
        return org;
      })
      .catch((err) => console.error(err));
  }

  @Action({ commit: "setOrgData" })
  createOrg(data: Organisation) {
    return new OrganisationService()
      .create(data)
      .catch((err) => console.error(err));
  }

  @Action({ commit: "setOrgData" })
  updateOrg(data: Partial<Organisation>) {
    return new OrganisationService()
      .update(data)
      .then((res) => {
        if (res) return res;
      })
      .catch((err) => console.error(err));
  }

  @Mutation
  setOrganisation(res: Organisation[]) {
    if (res) {
      this.data = res[0];
    }
  }

  @Mutation
  setOrgData(data: Organisation) {
    if (data) this.data = data;
  }
}

export default getModule(OrganisationModule);
