<template>
  <v-card flat class="fill-height">
    <v-card-title>
      <strong>{{ $t("site.route.invoice") }}</strong>
      <v-spacer />
      <v-chip label color="primary" outlined v-if="serviceProvider?.id">
        {{ $t("object.serviceProvider.spPercent") }}
        <strong>
          {{ serviceProvider?.technicianProfile?.percentage ?? 0 }}%
        </strong>
      </v-chip>
    </v-card-title>
    <v-card-subtitle class="text-start">
      <small>
        {{ $t("object.invoice.formSubtitle") }}
      </small>
    </v-card-subtitle>
    <v-container>
      <v-row dense>
        <v-col cols="6">
          <v-combobox
            :items="spList"
            :item-text="spFullName"
            v-model="serviceProvider"
            :label="$t('object.serviceProvider.selectSP')"
            :placeholder="$t('object.serviceProvider.selectSP')"
            return-object
            outlined
            dense
            hide-details
            full-width
            @change="spChange"
            :loading="spLoader"
            width="300"
          />
        </v-col>
        <v-col cols="6"> </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-form v-model="formValid">
        <v-row dense>
          <v-col cols="12" md="6">
            <v-text-field
              outlined
              dense
              :rules="formRules.nameRules"
              :label="$t('object.person.firstName')"
              :disabled="!!serviceProvider.id"
              v-model="serviceProvider.firstName"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              outlined
              :rules="formRules.nameRules"
              :disabled="!!serviceProvider.id"
              dense
              :label="$t('object.person.lastName')"
              v-model="serviceProvider.lastName"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" md="6">
            <v-text-field
              outlined
              :disabled="!!serviceProvider.id"
              dense
              :label="$t('object.person.email')"
              v-model="serviceProvider.email"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              outlined
              :disabled="!!serviceProvider.id"
              dense
              :label="$t('object.person.phoneNo')"
              v-model="serviceProvider.phoneNumber"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" md="2">
            <v-text-field
              outlined
              :disabled="!!serviceProvider.id"
              dense
              :label="$t('object.task.streetNo')"
              v-model="address.streetNo"
            />
          </v-col>
          <v-col cols="12" md="5">
            <v-text-field
              outlined
              :disabled="!!serviceProvider.id"
              dense
              :label="$t('object.task.streetName')"
              v-model="address.street"
            />
          </v-col>
          <v-col cols="12" md="5">
            <v-text-field
              outlined
              dense
              :label="$t('object.person.city')"
              v-model="address.city"
              :disabled="!!serviceProvider.id"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" md="6">
            <v-text-field
              outlined
              dense
              :label="$t('object.person.postalCode')"
              v-model="address.postalCode"
              :disabled="!!serviceProvider.id"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              outlined
              dense
              :label="$t('object.person.country')"
              :disabled="!!serviceProvider.id"
              v-model="address.country"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" md="6">
            <v-text-field
              outlined
              dense
              type="date"
              :label="$t('object.invoice.dateIssued')"
              :rules="formRules.default"
              v-model="formItems.invoiceDate"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              outlined
              dense
              :label="$t('object.invoice.dueDate')"
              :rules="formRules.default"
              type="date"
              v-model="formItems.paymentDue"
            />
          </v-col>
        </v-row>

        <v-card outlined>
          <v-data-table
            :items="selectedOrderLists"
            :headers="headers"
            dense
            hide-default-footer
            disable-sort
          >
            <template v-slot:[`item.client`]="{ item }">
              <span v-if="item.id">
                {{ item.requesterFirstName + " " + item.requesterLastName }}
              </span>
            </template>
            <template v-slot:[`item.serviceCompletionTime`]="{ item }">
              <v-text-field
                hide-details
                readonly
                outlined
                dense
                :value="item.serviceCompletionTime"
              />
            </template>
            <template v-slot:[`item.invoicedAmount`]="{ item }">
              <v-text-field
                hide-details
                type="number"
                outlined
                dense
                hide-spin-buttons
                :value="item.invoicedAmount"
              />
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                depressed
                icon
                x-small
                color="error"
                @click="removeRow(item)"
              >
                <v-icon>mdi-trash-can-outline</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
        <v-card
          class="pa-4"
          flat
          v-if="!isOrderFull"
          :disabled="selectedOrderLists?.length < 1"
        >
          <v-btn color="primary" outlined fab @click="addEmpty" small>
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-card>
      </v-form>
    </v-container>
    <v-card-actions class="px-4">
      <v-spacer />
      <v-btn min-width="80" outlined color="primary" @click="cancel">
        {{ $t("site.btn.cancel") }}
      </v-btn>
      <v-btn
        min-width="80"
        color="primary"
        @click="submit"
        :loading="submiztLoading"
        :disabled="!formValid"
      >
        {{ $t("site.btn.save") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
// eslint-disable-next-line prettier/prettier
import { formRules, FormRules } from "@/helpers/component-helper";
import invoiceModule from "@/store/modules/invoice-module";
import serviceproviderModule from "@/store/modules/serviceprovider-module";
import {
  Address,
  Invoice,
  ServiceProvider,
  Task,
} from "@taskmanagement/taskapp-model";
import moment from "moment";
import Vue from "vue";
import { PropValidator } from "vue/types/options";
import { DataTableHeader } from "vuetify";

type PropType = { selectedOrders: Task[]; value: Invoice };
type DataType = {
  formValid: boolean;
  address: Address;
  serviceProvider: ServiceProvider;
  orders: Task[];
  spLoader: boolean;
  submiztLoading: boolean;
};
type MethodType = {
  addEmpty(): void;
  removeRow(item: Task): void;
  spChange(): void;
  spFullName(item: ServiceProvider): string;
  setFormItems(): void;
  submit(): void;
  cancel(): void;
};
type ComputeType = {
  formItems: Invoice;
  selectedOrderLists: Task[];
  invoice: Invoice;
  emptyOrder: Task & Partial<Task>;
  isOrderFull: boolean;
  spList: ServiceProvider[];
  headers: DataTableHeader[];
  formRules: FormRules;
};

export default Vue.extend<DataType, MethodType, ComputeType, PropType>({
  name: "InvoiceFormComponent",
  data: () => ({
    formValid: false,
    address: new Address(),
    serviceProvider: new ServiceProvider(),
    orders: [],
    spLoader: false,
    submiztLoading: false,
  }),
  props: {
    selectedOrders: { type: Array } as PropValidator<Task[]>,
    value: { type: Invoice },
  },
  computed: {
    formItems: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    selectedOrderLists: {
      get() {
        return this.selectedOrders.map((x) => ({
          ...x,
          createdAt: moment(x.createdAt).format(
            "DD-MM-YYYY HH:mm"
          ) as unknown as Date,
        }));
      },
      set(value) {
        this.formItems.tasks = value;
      },
    },
    invoice() {
      return Object.assign(this.formItems, {
        tasks: this.selectedOrderLists,
        serviceProvider: {
          ...this.serviceProvider,
          address: { ...this.address },
        },
      }) as Invoice;
    },
    emptyOrder() {
      return {
        requesterFirstName: "",
        requesterLastName: " ",
      } as Task;
    },
    isOrderFull() {
      return this.selectedOrderLists?.length >= 8;
    },
    spList() {
      return serviceproviderModule.serviceProviderList;
    },
    headers() {
      return [
        { text: this.$t("object.order.name"), value: "ticketNumber" },
        { text: this.$t("object.misc.customerName"), value: "client" },
        {
          text: this.$t("object.misc.dateName"),
          value: "createdAt",
        },
        { text: this.$t("object.invoice.servicePrice"), value: "price" },
        { text: this.$t("object.task.header.price"), value: "invoicedAmount" },
        { text: "", value: "actions" },
      ] as DataTableHeader[];
    },
    formRules() {
      return formRules;
    },
  },
  watch: {
    serviceProvider(newVal) {
      if (newVal === null) {
        this.address = new Address();
        this.serviceProvider = new ServiceProvider();
      }
    },
  },
  methods: {
    addEmpty() {
      if (!this.isOrderFull) {
        this.selectedOrderLists?.push(this.emptyOrder);
      }
    },
    removeRow(item: Task) {
      this.$emit("remove", item);
    },
    spChange() {
      this.setFormItems();
      if (this.serviceProvider?.id) {
        this.spLoader = true;
        return invoiceModule
          .retrieveInvoiceList({ id: this.serviceProvider?.id })
          .then(() => this.$emit("spChange", this.serviceProvider))
          .finally(() => {
            this.spLoader = false;
          });
      } else {
        this.$emit("spChange", this.serviceProvider);
      }
    },
    spFullName(item) {
      return `${item?.firstName ?? ""} ${item?.lastName ?? ""}`;
    },
    setFormItems() {
      this.formItems = Object.assign(this.formItems, {
        serviceProvider: {
          ...this.serviceProvider,
          address: this.serviceProvider?.address,
        },
      });
      this.address = this.serviceProvider?.address ?? new Address();
    },
    submit() {
      this.$emit("submit", this.invoice);
    },
    cancel() {
      this.$emit("cancel");
      this.serviceProvider = new ServiceProvider();
      this.address = new Address();
    },
  },
});
</script>

<style></style>
