<template>
  <v-card min-height="700" :disabled="isOrderFull">
    <v-card-title>
      <strong>{{ $t("object.task.listName") }}</strong>
      <v-spacer />
      <v-chip label small color="error" outlined v-if="isOrderFull">
        {{ $t("object.invoice.selSubTitle", [8]) }}
      </v-chip>
    </v-card-title>
    <v-card-subtitle class="text-start">
      <small>{{ $t("object.invoice.subtitle") }}</small>
    </v-card-subtitle>
    <v-card flat max-height="600" class="overflow-y-auto">
      <v-container v-for="(itm, i) in items" :key="i">
        <v-card
          class="mb-2 pa-1"
          @click="select(itm)"
          hover
          :outlined="selectedList?.includes(itm)"
          :disabled="selectedList?.includes(itm)"
          min-height="40"
        >
          <v-row>
            <v-col cols="9">
              <viewDisplayItemComponent
                :icon="'mdi-tag-text-outline'"
                :headertext="itm.ticketNumber"
                :title="itm.createdAt"
                :subTitle="
                  itm.price ? `€${itm.price} ` : $t('object.misc.notapplicable')
                "
              />
            </v-col>
            <v-col cols="3" align-self="end">
              <v-chip
                label
                color="success"
                class="items-center"
                width="80"
                small
                v-if="selectedList?.includes(itm)"
              >
                {{ $t("object.misc.added") }}
              </v-chip>
            </v-col>
          </v-row>
        </v-card>
      </v-container>
    </v-card>
  </v-card>
</template>

<script lang="ts">
import { Task } from "@taskmanagement/taskapp-model";
import Vue from "vue";
import { PropValidator } from "vue/types/options";
import ViewDisplayItemComponent from "../order/viewdisplayitem-component.vue";

export default Vue.extend({
  name: "OrderSelectionComponent",
  components: { viewDisplayItemComponent: ViewDisplayItemComponent },
  data: () => ({}),
  computed: {
    isOrderFull() {
      return this.selectedList.length >= 8;
    },
  },
  props: {
    items: { type: Array } as PropValidator<Task[]>,
    selectedList: { type: Array } as PropValidator<Task[]>,
  },
  methods: {
    select(itm: Task) {
      this.$emit("select", itm);
    },
  },
});
</script>

<style></style>
