<template>
  <span>
    <usersComponent />
  </span>
</template>

<script lang="ts">
import Vue from "vue";
import UsersComponent from "../subcomponent/users/users-component.vue";

export default Vue.extend({
  name: "UsersComponent",
  components: {
    usersComponent: UsersComponent,
  },
});
</script>

<style></style>
