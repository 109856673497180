import {
  getUserRolesWithCount,
  getUserStatusWithCount,
} from "@/helpers/user-helper";
import { QueryParams, TypeStageFormatter } from "@/model";
import { UserService } from "@/services/user-service";
import { EnumRoles, EnumUserStatus, User } from "@taskmanagement/taskapp-model";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import store from "..";
import formatterModule from "./formatter-module";

@Module({
  name: "user",
  store,
  dynamic: true,
})
class UserModule extends VuexModule {
  userList: User[] = [];
  user = new User();

  userRoles: TypeStageFormatter<EnumRoles>[] = [];

  userStatus: TypeStageFormatter<EnumUserStatus>[] = [];

  get activity() {
    return this.userList.map((x) => x.active ?? null) ?? null;
  }

  @Action({ commit: "setUserList" })
  getUserList(params?: QueryParams<User>) {
    return new UserService().getAll(params).then((val) => {
      if (val) return val;
    });
  }

  @Action({ commit: "setUser" })
  getUser(params: User["username"]) {
    return new UserService().getByUsername(params).then((res) => {
      if (res) return res;
    });
  }

  @Action({ commit: "setUpdateUser" })
  updateUser(user: User) {
    return new UserService().update(user);
  }

  @Mutation
  setUserList(users: User[]) {
    this.userList.length = 0;
    this.userRoles.length = 0;
    this.userStatus.length = 0;
    this.userList = users ?? [];
    this.userRoles.splice(
      0,
      this.userRoles.length,
      ...getUserRolesWithCount(formatterModule.userRoles, this.userList)
    );
    this.userStatus.splice(
      0,
      this.userStatus.length,
      ...getUserStatusWithCount(formatterModule.userStatus, this.userList)
    );
  }

  @Mutation
  setUser(user: User) {
    this.user = user;
  }

  @Mutation
  setUpdateUser(user: User) {
    if (user) {
      this.user = user;
    }
  }
}

export default getModule(UserModule);
