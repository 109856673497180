<template>
  <v-card outlined>
    <v-container>
      <v-row dense>
        <v-col cols="12" md="8">
          <v-text-field
            dense
            hide-details
            filled
            :label="$t('object.task.header.search')"
            v-model="search"
          />
        </v-col>
        <v-col cols="12" md="4"></v-col>
      </v-row>
    </v-container>
    <v-container class="px-0">
      <v-data-table
        :headers="header"
        :items="items"
        :search="search"
        @click:row="rowClick"
      >
        <template v-slot:[`item.client`]="{ item }">
          <v-chip label outlined>
            <v-icon left>mdi-account-outline</v-icon>
            {{
              `${item?.requesterFirstName ?? ""} ${
                item?.requesterLastName ?? ""
              }`
            }}
          </v-chip>
        </template>
        <template v-slot:[`item.stage`]="{ item }">
          <statusDisplayComponent
            :color="stageFormatter(item)?.color"
            :text="stageFormatter(item)?.value"
            outlined
          />
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            small
            outlined
            color="primary"
            :disabled="!allowAction(item)"
            @click="onReceive(item)"
          >
            More
          </v-btn>
        </template>
      </v-data-table>
    </v-container>
  </v-card>
</template>

<script lang="ts">
import { headerEnumFormatter } from "@/helpers/component-helper";
import { ROUTE_AGENT_ORDERDETAIL } from "@/model/routes";
import allocationModule from "@/store/modules/allocation-module";
import formatterModule from "@/store/modules/formatter-module";
import {
  EnumStatus,
  EnumTaskStage,
  Task,
} from "@taskmanagement/taskapp-model/dist";
import moment from "moment";
import Vue from "vue";
import { DataTableHeader } from "vuetify";
import StatusDisplayComponent from "../../statusdisplay-component.vue";

export default Vue.extend({
  name: "AgentOrderTableComponent",
  components: { statusDisplayComponent: StatusDisplayComponent },
  data: () => ({ search: null }),
  computed: {
    header() {
      return [
        { text: "", value: "alert", width: 50, sortable: false },
        {
          text: this.$t("object.order.name"),
          value: "ticketNumber",
        },
        {
          text: this.$t("object.task.startTime"),
          value: "startTime",
          align: "center",
        },
        {
          text: this.$t("object.task.endTime"),
          value: "endTime",
          align: "center",
        },
        {
          text: this.$t("object.misc.clientName"),
          value: "client",
          align: "center",
        },
        {
          text: this.$t("object.task.header.price"),
          value: "price",
          align: "center",
        },
        {
          text: this.$t("object.allocation.decision"),
          value: "stage",
          align: "center",
        },
        { text: "", value: "actions", sortable: false, align: "end" },
      ] as DataTableHeader[];
    },
    items() {
      return allocationModule.allocationList
        ?.map((y) => ({
          ...y.task,
          allocation: [y],
        }))
        .filter((xx) => !!xx.id)
        .map((x) => ({
          ...x,
          startTime: x.startTime ? moment(x.startTime).format("L LT") : null,
          endTime: x.endTime ? moment(x.endTime).format("L LT") : null,
          price: x.price ? this.$n(x.price) : "-",
        }));
    },
  },
  methods: {
    timeFormat(time: string | Date) {
      return moment(time).format("L LT");
    },
    stageFormatter(item: Task) {
      return headerEnumFormatter(item?.stage, formatterModule.taskStage);
    },
    onReceive(item: Task) {
      if (item && item.id) {
        this.$router.push({
          name: ROUTE_AGENT_ORDERDETAIL,
          params: { ticketNumber: item.ticketNumber },
          query: { rq: item.allocation?.[0].id as string },
        });
      }
    },
    allowAction(item: Task) {
      const allowedStage = item?.stage >= EnumTaskStage.created;
      // eslint-disable-next-line prettier/prettier
      const allowedStatus = (item.status == undefined || item.status == null) || (item.status === EnumStatus.accepted);
      return allowedStage && allowedStatus;
    },
    rowClick(event: Task) {
      if (this.allowAction(event)) {
        this.onReceive(event);
      }
    },
  },
});
</script>

<style></style>
