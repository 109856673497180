<template>
  <v-card class="elevation-2 pa-4" :max-width="maxWidth">
    <v-container class="pa-4">
      <v-row justify="center">
        <v-avatar color="green lighten-4" size="70" class="items-center">
          <v-btn fab color="green" small depressed>
            <v-icon color="white">mdi-check</v-icon>
          </v-btn>
        </v-avatar>
      </v-row>

      <v-row justify="center">
        <v-card-title>
          <strong> {{ title }} </strong>
        </v-card-title>

        <v-card-text class="text-center">
          {{ subtitle }}
        </v-card-text>
      </v-row>

      <v-card-actions class="justify-center pt-8" v-if="!hideBtn">
        <v-btn min-width="120" :color="btnColor" @click="click">
          {{ btnText }}
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "UserCreatedComponent",
  props: {
    title: { type: String },
    subtitle: { type: String },
    btnText: { type: String },
    btnColor: { type: String },
    hideBtn: { type: Boolean },
    maxWidth: { type: Number },
  },
  methods: {
    click() {
      this.$emit("click");
    },
  },
});
</script>

<style></style>
