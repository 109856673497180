<template>
  <v-card color="transparent" flat max-width="1200">
    <v-container class="py-2 pb-4">
      <previewHeaderComponent :title="$t('object.org.name')" hideBack />
      <v-card class="pa-0 transparent" flat>
        <v-card-subtitle class="text-start pa-0">
          {{ $t("site.org.subHeader") }}
        </v-card-subtitle>
      </v-card>
    </v-container>
    <v-row align-content="center">
      <v-col
        cols="12"
        md="6"
        v-for="(item, n) in firstRow"
        :key="n"
        align-self="start"
      >
        <detailCardComponent
          outlined
          :title="item.title"
          :items="item.items"
          :hideEdit="canEdit"
          dense
          @change="onEdit(item.type)"
          height="250"
        />
      </v-col>
    </v-row>
    <v-row align-content="center">
      <v-col
        cols="12"
        md="6"
        align-self="start"
        v-for="(item, n) in secondRow"
        :key="n"
      >
        <detailCardComponent
          flat
          outlined
          :title="item.title"
          :items="item.items"
          :hideEdit="canEdit"
          height="250"
          dense
          @change="onEdit(item.type)"
        />
      </v-col>
    </v-row>
    <orgEditComponent
      v-model="edit"
      :editType="editType"
      :data="organisation"
    />
  </v-card>
</template>

<script lang="ts">
import { userHasAccess } from "@/helpers/auth-helpers";
import { EnumOrgForm, InfoCardType } from "@/model";
import organisationModule from "@/store/modules/organisation-module";
import { EnumRoles } from "@taskmanagement/taskapp-model";
import Vue from "vue";
import DetailCardComponent from "../detail/detailcard-component.vue";
import PreviewHeaderComponent from "../previewheader-component.vue";
import OrgEditComponent from "./org-edit-component.vue";

export default Vue.extend({
  name: "AdminOverviewComponent",
  data: () => ({
    edit: null as boolean | null,
    editType: null as string | null,
  }),
  components: {
    detailCardComponent: DetailCardComponent,
    previewHeaderComponent: PreviewHeaderComponent,
    orgEditComponent: OrgEditComponent,
  },
  computed: {
    firstRow() {
      const data = organisationModule.data;
      const address = data.address;
      return [
        {
          type: EnumOrgForm.DETAIL as string,
          title: this.$i18n.t("object.misc.detailsName"),
          items: [
            { title: this.$i18n.t("object.misc.name"), value: data.name },
            { title: this.$i18n.t("object.person.email"), value: data.email },
            {
              title: this.$i18n.t("object.org.founderName"),
              value: data.founderName,
            },
            {
              title: this.$i18n.t("object.org.initUser"),
              value: data.initialUser?.firstName,
            },
          ].map((x) => ({ ...x, condition: !!x.value })),
        },
        {
          type: EnumOrgForm.ADDRESS as string,
          title: this.$i18n.t("object.person.address"),
          items: [
            {
              title: this.$i18n.t("object.task.streetNo"),
              value: address?.streetNo,
            },
            {
              title: this.$i18n.t("object.task.streetName"),
              value: address?.street,
            },
            {
              title: this.$i18n.t("object.person.postalCode"),
              value: address?.postalCode,
            },
            { title: this.$i18n.t("object.person.city"), value: address?.city },
            {
              title: this.$i18n.t("object.person.country"),
              value: address?.country,
            },
          ].map((x) => ({ ...x, condition: !!x.value })),
        },
      ] as InfoCardType[];
    },
    secondRow() {
      const data = organisationModule.data;
      const account = data.accountInfo;
      return [
        {
          title: this.$i18n.t("object.misc.tax"),
          type: EnumOrgForm.TAX as string,
          items: [
            {
              title: this.$i18n.t("object.org.vat"),
              value: account?.vat?.toString(),
            },
            { title: this.$i18n.t("object.org.hrID"), value: account?.hrID },
            { title: this.$i18n.t("object.org.taxId"), value: account?.taxID },
            { title: this.$i18n.t("object.org.ustID"), value: account?.ustID },
          ].map((x) => ({ ...x, condition: !!x.value })),
        },
        {
          title: this.$i18n.t("object.misc.bankDetails"),
          type: EnumOrgForm.BANK as string,
          items: [
            {
              title: this.$i18n.t("object.serviceProvider.bankName"),
              value: account?.bankName,
            },
            {
              title: this.$i18n.t("object.bank.accNo"),
              value: account?.accountNo,
            },
            { title: this.$i18n.t("object.bank.blz"), value: account?.blz },
            { title: this.$i18n.t("object.bank.iban"), value: account?.iban },
            { title: this.$i18n.t("object.bank.bic"), value: account?.bic },
          ].map((x) => ({ ...x, condition: !!x.value })),
        },
      ] as InfoCardType[];
    },
    organisation() {
      return organisationModule.data;
    },
    canEdit() {
      return !userHasAccess(EnumRoles.ADMIN);
    },
  },
  methods: {
    onEdit(type?: InfoCardType["type"]) {
      this.editType = (type as string | undefined) ?? null;
      this.edit = true;
    },
  },
});
</script>

<style></style>
