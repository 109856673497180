import { OrderServiceService } from "@/services/orderservice-service";
import { Allocation } from "@taskmanagement/taskapp-model";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import store from "..";

@Module({
  name: "orderService",
  store,
  dynamic: true,
})
class OrderServiceModule extends VuexModule {
  orderRequest = new Allocation() ?? null;

  @Action({ commit: "setService" })
  getService(query: { ticketNumber: string; id: string }) {
    const { ticketNumber, id } = query;
    return new OrderServiceService()
      .getOneByTicketNumber(id, ticketNumber)
      .then((res) => {
        if (res) return res;
      });
  }

  @Action({ commit: "setService" })
  updateService(body: Partial<Allocation>) {
    return new OrderServiceService().update(body).then((res) => {
      if (res) return res;
    });
  }

  @Mutation
  setService(order: Allocation) {
    if (order && order.id) {
      this.orderRequest = order;
    }
  }
}

export default getModule(OrderServiceModule);
