<template>
  <invoiceDetailComponent />
</template>

<script lang="ts">
import InvoiceDetailComponent from "@/components/invoice/invoicedetails-component.vue";
import invoiceModule from "@/store/modules/invoice-module";
import Vue from "vue";

export default Vue.extend({
  name: "InvoiceDetailView",
  components: {
    invoiceDetailComponent: InvoiceDetailComponent,
  },
  mounted() {
    this.getInvoiceData();
  },
  methods: {
    getInvoiceData() {
      const invoiceNumber = this.$route.params.invoiceNumber;
      if (!invoiceNumber) {
        this.$router.back();
        return;
      }
      this.$store.state.isLoading = true;
      return invoiceModule
        .retrieveInvoiceByInvNumber(invoiceNumber)
        .finally(() => {
          this.$store.state.isLoading = false;
        })
        .catch((err) => {
          if (err) this.$router.back();
        });
    },
  },
});
</script>

<style></style>
