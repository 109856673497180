<template>
  <v-card>
    <v-row>
      <v-img src="../../../assets/error404.avif" height="500" width="500" />
    </v-row>
    <v-row>
      <v-card-text class="text-center">
        Resource not found... Please reload or contact admin
      </v-card-text>
    </v-row>
  </v-card>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "ErrorNotFoundComponent",
});
</script>

<style></style>
