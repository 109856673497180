<template>
  <v-container>
    <v-card-title class="py-0">
      <strong
        style="
          font-size: 100;
          word-break: normal;
          line-break: normal;
          line-height: normal;
        "
      >
        {{ $t("object.home.title") }}
      </strong>
    </v-card-title>
    <v-card-subtitle class="mt-2 text-start">
      {{ $t("object.home.subtitle") }}
    </v-card-subtitle>
    <v-container flat color="transparent">
      <v-row>
        <v-col v-for="(item, i) in items" :key="i" cols="12" md="6">
          <v-hover close-delay="200" open-delay="250">
            <template v-slot:default="{ hover }">
              <v-card
                height="100"
                :elevation="hover ? 4 : 1"
                rounded="lg"
                class="d-flex align-center justify-center"
                :to="{
                  name: item.route,
                  params: { locale },
                }"
                :ripple="false"
              >
                <v-list-item two-line>
                  <v-list-item-avatar :class="baseColor" size="50">
                    <v-icon nav fab class="pa-4" color="black">
                      {{ item.icon }}
                    </v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content class="text-start">
                    <v-list-item-title class="my-2">
                      <strong>{{ item.title }} </strong>
                    </v-list-item-title>
                    <v-list-item-subtitle style="white-space: normal">
                      {{ item.description }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </template>
          </v-hover>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script lang="ts">
import { calcRouteValues } from "@/helpers/router-helpers";
import { colorMainBackground } from "@/plugins/vuetify";
import Vue from "vue";
import { RouteRecord } from "vue-router";

export default Vue.extend({
  name: "HomeComponent",
  data: () => ({}),
  computed: {
    items() {
      return this.$router
        .getRoutes()
        .filter((x) => calcRouteValues(x as RouteRecord))
        .map((y) => ({
          title: y.meta.nav?.title(),
          description: y.meta.nav?.desc,
          route: y.name,
          icon: y.meta.nav?.icon,
        }));
    },
    baseColor() {
      return colorMainBackground;
    },
    locale() {
      return this.$route.params.locale;
    },
  },
});
</script>
