import {
  User,
  USER_CHECK_USERNAME,
  USER_DETAIL_ROUTE,
  USER_ROUTE,
} from "@taskmanagement/taskapp-model";
import { BaseService } from "./base-service";

export class UserService extends BaseService<User> {
  constructor() {
    super(USER_ROUTE);
  }

  validateUser(user: Partial<User>) {
    return this.axios
      .post(USER_CHECK_USERNAME, user)
      .then(
        (res) =>
          res.data as Partial<{ [P in keyof Required<typeof user>]: boolean }>
      )
      .then((val) => val);
  }

  getByUsername(username: User["username"]): Promise<User> {
    return this.axios
      .get(USER_DETAIL_ROUTE, { params: { username } })
      .then((res) => res.data as User);
  }
}
