<template>
  <v-container class="px-0">
    <previewheader-component hideBack :title="$t('site.route.statistics')" />
    <v-container class="px-0">
      <statisticsSummaryComponent />
    </v-container>

    <!-- <v-tabs color="primary" left background-color="transparent" v-model="tabs">
      <v-tab>{{ $t("object.misc.summary") }}</v-tab>
      <v-tab>{{ $t("object.task.listName") }}</v-tab>
      <v-tab>{{ $t("object.misc.income") }}</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tabs" class="transparent mt-4">
      <v-tab-item>
        <statisticsSummaryComponent />
      </v-tab-item>
      <v-tab-item>
        <statisticsOrderComponent />
      </v-tab-item>
      <v-tab-item>
        <statisticsEarningComponent />
      </v-tab-item>
    </v-tabs-items> -->
  </v-container>
</template>

<script lang="ts">
import Vue from "vue";
import previewheaderComponent from "../previewheader-component.vue";
import StatisticsSummaryComponent from "./statsummary-component.vue";

export default Vue.extend({
  components: {
    previewheaderComponent,
    statisticsSummaryComponent: StatisticsSummaryComponent,
    // statisticsOrderComponent: StatisticsOrderComponent,
    // statisticsEarningComponent: StatisticsEarningComponent,
  },
  name: "StatisticsComponent",
  data: () => ({
    selectedBtn: null as string | null,
    tabs: null,
  }),
  computed: {
    toggleList() {
      return [
        { text: "Order", value: "order" },
        { text: "Earning", value: "earn" },
      ];
    },
  },
  methods: {
    clickBtn(itm: string) {
      this.selectedBtn = itm;
    },
  },
});
</script>

<style></style>
