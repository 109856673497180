<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="8">
        <v-card min-height="700" class="pa-0">
          <invoiceFormComponent
            @spChange="updateSp"
            @remove="remove"
            @submit="submit"
            @cancel="cancel"
            :selectedOrders="selectedOrders"
            v-model="invoice"
          />
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <orderSelectionComponent
          :selectedList="selectedOrders"
          :items="orderList"
          @select="select"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import invoiceModule from "@/store/modules/invoice-module";
import { Invoice, ServiceProvider, Task } from "@taskmanagement/taskapp-model";
import Vue from "vue";

import { ROUTE_INVOICE } from "@/model/routes";
import InvoiceFormComponent from "./invoiceform-component.vue";
import OrderSelectionComponent from "./orderselection-component.vue";

export default Vue.extend({
  name: "InvoiceCreateComponent",
  components: {
    invoiceFormComponent: InvoiceFormComponent,
    orderSelectionComponent: OrderSelectionComponent,
  },
  data: () => ({
    sp: null as null | ServiceProvider,
    selectedOrders: [] as Task[],
    invoice: new Invoice(),
  }),
  watch: {
    sp(val) {
      if (val === null || val === undefined) {
        this.selectedOrders = [];
      }
      if (!val) {
        this.selectedOrders = [];
      }
    },
  },
  computed: {
    orderList() {
      return (
        invoiceModule.serviceProviders.find((x) => x?.id === this.sp?.id)
          ?.orderList ?? ([] as Task[])
      );
    },
  },
  methods: {
    updateSp(item: ServiceProvider | null) {
      this.sp = item;
    },
    select(itm: Task) {
      this.$emit("select", itm);
      this.selectedOrders?.push(itm);
    },
    remove(item: Task) {
      const removeIndex = this.selectedOrders.findIndex(
        (x) => x.id === item.id
      );
      if (removeIndex !== -1) {
        this.selectedOrders.splice(removeIndex, 1);
      }
    },
    submit(inv: Invoice) {
      invoiceModule.createInvAction(inv).then((res) => {
        if (res) this.$router.push({ name: ROUTE_INVOICE });
      });
    },
    cancel() {
      this.sp = null;
      this.invoice = new Invoice();
    },
  },
});
</script>

<style></style>
