<template>
  <span>
    <statisticsComponent />
  </span>
</template>

<script lang="ts">
import Vue from "vue";
import StatisticsComponent from "../components/statistics/statistics-component.vue";
import statisticsModule from "../store/modules/statistics-module";

export default Vue.extend({
  name: "StatisticsView",
  components: {
    statisticsComponent: StatisticsComponent,
  },
  mounted() {
    this.loadStats();
  },
  methods: {
    loadStats() {
      this.$store.state.isLoading = true;
      statisticsModule.getSummary().finally(() => {
        this.$store.state.isLoading = false;
      });
    },
  },
});
</script>

<style></style>
