<template>
  <v-dialog v-model="dialog" max-width="350">
    <v-card>
      <v-container class="pa-0 gap-3">
        <v-card-title class="lightgrey">
          Update Organisation Details
        </v-card-title>
      </v-container>

      <v-container class="pa-4" v-if="editType === formType.DETAIL">
        <v-form v-model="formValid">
          <v-text-field
            outlined
            :label="$t('object.org.orgName')"
            :rules="formRules.default"
            v-model="orgData.name"
          />
          <v-text-field
            outlined
            :label="$t('object.person.email')"
            v-model="orgData.email"
            :type="'email'"
            :rules="formRules.emailRules"
          />
          <v-text-field
            outlined
            :label="$t('object.person.phoneNo')"
            v-model="orgData.phoneNo"
            :type="'tel'"
            :rules="formRules.phoneNumberRules"
          />
          <v-text-field
            outlined
            :label="$t('object.org.founderName')"
            v-model="orgData.founderName"
          />
        </v-form>
      </v-container>

      <v-container class="pa-4" v-if="editType === formType.BANK">
        <v-form v-model="formValid">
          <v-text-field
            outlined
            :label="$t('object.serviceProvider.bankName')"
            v-model="account.bankName"
          />
          <v-text-field
            outlined
            :label="$t('object.bank.iban')"
            v-model="account.iban"
          />
          <v-text-field
            outlined
            :label="$t('object.bank.bic')"
            v-model="account.bic"
          />
          <v-text-field
            outlined
            :label="'Account No.'"
            v-model="account.accountNo"
          />
          <v-text-field
            outlined
            :label="$t('object.bank.blz')"
            v-model="account.blz"
          />
        </v-form>
      </v-container>

      <v-container class="pa-4" v-if="editType === formType.ADDRESS">
        <v-form v-model="formValid">
          <v-text-field
            outlined
            :label="$t('object.task.streetNo')"
            v-model="address.streetNo"
          />
          <v-text-field
            outlined
            :label="$t('object.task.streetName')"
            v-model="address.street"
          />
          <v-text-field
            outlined
            :label="$t('object.person.city')"
            v-model="address.city"
          />
          <v-text-field
            outlined
            :label="$t('object.person.postalCode')"
            v-model="address.postalCode"
          />
          <v-text-field
            outlined
            :label="$t('object.person.country')"
            v-model="address.country"
          />
        </v-form>
      </v-container>

      <v-container class="pa-4" v-if="editType === formType.TAX">
        <v-form v-model="formValid">
          <v-text-field
            outlined
            type="number"
            :min="0"
            :max="100"
            hide-spin-buttons
            :label="$t('object.org.vat')"
            v-model="account.vat"
          />
          <v-text-field
            outlined
            :label="$t('object.org.hrID')"
            v-model="account.hrID"
          />
          <v-text-field
            outlined
            :label="$t('object.org.ustID')"
            v-model="account.ustID"
          />
          <v-text-field
            outlined
            :label="$t('object.org.taxId')"
            v-model="account.taxID"
          />
        </v-form>
      </v-container>

      <v-divider />

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" min-width="85" @click="onCancel"> Cancel </v-btn>
        <v-btn
          color="primary"
          min-width="85"
          outlined
          :loading="loading"
          @click="onSave"
          :disabled="!formValid"
        >
          {{ $t("site.btn.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { FormRules, formRules } from "@/helpers/component-helper";
import { EnumOrgForm } from "@/model";
import organisationModule from "@/store/modules/organisation-module";
import { Account, Address, Organisation } from "@taskmanagement/taskapp-model";
import Vue from "vue";

type PropType = { value: boolean; editType: string; data: Organisation };
type DataType = {
  formValid: boolean | null;
  formType: typeof EnumOrgForm;
  orgData: Organisation;
  account: Account;
  address: Address;
  loading: boolean;
};
type ComputedType = {
  dialog: boolean;
  formRules: FormRules;
  currentType: string;
  organisation: Organisation | null;
};
type MethodType = {
  onSave(): void;
  onCancel(): void;
  clearFormData(): void;
  loadOrgData(): void;
  capitalizeFirstLetter(str: string): string;
};

export default Vue.extend<DataType, MethodType, ComputedType, PropType>({
  name: "OrgEditComponent",
  data: () => ({
    loading: false,
    formValid: null,
    formType: EnumOrgForm,
    orgData: new Organisation(),
    address: new Address(),
    account: new Account(),
  }),
  props: {
    value: { type: Boolean },
    editType: { type: String },
    data: { type: Object },
  },

  computed: {
    dialog: {
      set(value) {
        this.$emit("input", value);
      },
      get() {
        if (this.editType) return this.value;
        return false;
      },
    },
    formRules() {
      return formRules;
    },
    currentType() {
      return this.capitalizeFirstLetter(this.editType);
    },
    organisation() {
      return Object.assign(new Organisation(), {
        ...this.orgData,
        id: this.data?.id,
        address: this.address,
        accountInfo: this.account,
      });
    },
  },
  watch: {
    value(val) {
      if (!val) {
        this.orgData = new Organisation();
        this.address = new Address();
        this.account = new Account();
      }
      if (val) {
        this.loadOrgData();
      }
    },
  },
  methods: {
    clearFormData() {
      this.orgData = new Organisation();
      this.account = new Account();
      this.address = new Address();
    },
    capitalizeFirstLetter(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    onSave() {
      this.loading = true;
      const org = this.organisation;
      console.log(org);
      if (org && org?.id) {
        organisationModule.updateOrg(org).finally(() => {
          this.loading = false;
          this.dialog = false;
          this.clearFormData();
        });
      }
    },
    onCancel() {
      this.dialog = false;
      this.clearFormData();
    },
    loadOrgData() {
      this.$nextTick(() => {
        if (this.data) {
          this.$set(this, "orgData", { ...this.data });
          this.$set(this, "address", { ...this.data.address });
          this.$set(this, "account", { ...this.data.accountInfo });
        }
      });
    },
  },
});
</script>
