<template>
  <span>
    <orderServiceComponent />
  </span>
</template>

<script lang="ts">
import OrderServiceComponent from "@/components/order/agent/orderservice-component.vue";
import { ROUTE_AGENT_ORDER } from "@/model/routes";
import orderserviceModule from "@/store/modules/orderservice-module";
import { EnumTaskStage } from "@taskmanagement/taskapp-model";
import Vue from "vue";

export default Vue.extend({
  components: { orderServiceComponent: OrderServiceComponent },
  name: "AgentOrderDetailView",
  data: () => ({
    stage: EnumTaskStage,
  }),
  computed: {
    orderStage() {
      return orderserviceModule.orderRequest.stage;
    },
    serviceStage() {
      const stage = orderserviceModule.orderRequest.stage;
      return stage >= EnumTaskStage.viewed && stage < EnumTaskStage.done;
    },
  },
  mounted() {
    this.getOrderData();
  },
  methods: {
    getOrderData() {
      const ticketNumber = this.$route.params.ticketNumber;
      const id = this.$route.query.rq as string;

      if (ticketNumber && id) {
        this.$store.state.isLoading = true;
        orderserviceModule
          .getService({ ticketNumber, id })
          .then((res) => {
            if (!res) this.$router.push({ name: ROUTE_AGENT_ORDER });
          })
          .catch((err) => {
            if (err) this.$router.push({ name: ROUTE_AGENT_ORDER });
          })
          .finally(() => {
            this.$store.state.isLoading = false;
          });
      } else {
        this.$router.push({ name: ROUTE_AGENT_ORDER });
      }
    },
  },
});
</script>

<style></style>
