import localAxios from "@/helpers/axios-helper";
import {
  AUTH_ROUTE,
  LOGIN_ROUTE,
  LoginUser,
  SIGNUP_ROUTE,
  TokenResponse,
  User,
} from "@taskmanagement/taskapp-model";
import { AxiosError } from "axios";
import { handleGlobalError } from "../helpers/errors";
import { BaseService } from "./base-service";

class AuthService extends BaseService<LoginUser & { id?: number }> {
  constructor() {
    super(AUTH_ROUTE);
  }
  static createUser(user: User): Promise<string | User | void> {
    return localAxios
      .post(SIGNUP_ROUTE, user)
      .then((res) => res.data as User)
      .catch((error) => handleGlobalError(error));
  }

  static loginUser(user: LoginUser): Promise<TokenResponse | void> {
    return localAxios
      .post(LOGIN_ROUTE, user)
      .then(
        (response: {
          status: number;
          data: TokenResponse & { loginUser: LoginUser };
        }) => {
          if (response.status === 200) return response.data;
          throw false;
        }
      )
      .catch((err: AxiosError) => handleGlobalError(err));
  }

  verifyToken(params?: { token?: string; signup?: boolean }) {
    return this.axios
      .post("/api/auth/verify", {
        token: params?.token,
        signup: params?.token,
      })
      .then((res) => res.data as User);
  }

  completeVerify(body: Partial<User>) {
    return this.axios
      .post("/api/auth/complete", body)
      .then((res) => res.data as true | null);
  }
}

export default AuthService;
