import { TypeStageFormatter } from "@/model";
import { UserService } from "@/services/user-service";
import {
  Allocation,
  Identification,
  Person,
  Task,
  User,
  allowedTime,
} from "@taskmanagement/taskapp-model";
import moment from "moment";
import VueRouter, { Route } from "vue-router";
import { i18n } from "../plugins/i18n";
export const countryCodes = [
  { country: "Austria", code: "+43" },
  { country: "Belgium", code: "+32" },
  { country: "Bulgaria", code: "+359" },
  { country: "Croatia", code: "+385" },
  { country: "Cyprus", code: "+357" },
  { country: "Czech Republic", code: "+420" },
  { country: "Denmark", code: "+45" },
  { country: "Estonia", code: "+372" },
  { country: "Finland", code: "+358" },
  { country: "France", code: "+33" },
  { country: "Germany", code: "+49" },
  { country: "Greece", code: "+30" },
  { country: "Hungary", code: "+36" },
  { country: "Ireland", code: "+353" },
  { country: "Italy", code: "+39" },
  { country: "Latvia", code: "+371" },
  { country: "Lithuania", code: "+370" },
  { country: "Luxembourg", code: "+352" },
  { country: "Malta", code: "+356" },
  { country: "Netherlands", code: "+31" },
  { country: "Poland", code: "+48" },
  { country: "Portugal", code: "+351" },
  { country: "Romania", code: "+40" },
  { country: "Slovakia", code: "+421" },
  { country: "Slovenia", code: "+386" },
  { country: "Spain", code: "+34" },
  { country: "Sweden", code: "+46" },
];
export const tabItemList = [
  { name: i18n.t("object.task.listName"), value: "tasks" },
  {
    name: i18n.t("object.serviceProvider.name"),
    value: "serviceProvider",
  },
  { name: i18n.t("object.requester.name"), value: "requester" },
  { name: i18n.t("object.allocation.name"), value: "allocation" },
];

// eslint-disable-next-line prettier/prettier
export function promiseHandler<T>(promise: Promise<T>, dialog: boolean, loader: boolean) {
  dialog = false;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  loader = true;
  return promise.finally(() => {
    loader = false;
  });
}

export function updateArray<T extends Identification>(
  arr: T[],
  id: T["id"],
  updatedData: Partial<T>
) {
  return arr.map((item) =>
    item.id === id ? { ...item, ...updatedData } : item
  );
}

export function deleteFromArray<T extends Identification>(
  arr: T[],
  deletion: T
) {
  const arrCopy = Array.from(arr);

  const objWithIdIndex = arrCopy.findIndex((obj) => obj.id === deletion.id);
  arrCopy.splice(objWithIdIndex, 1);
  return arrCopy;
}

export function headerPersonFormatter(value: Person) {
  return value.firstName;
}

export function headerEnumFormatter<T>(
  stage: T | undefined,
  taskStageDesc: TypeStageFormatter<T>[]
) {
  const lclStage = taskStageDesc?.find((val) => stage === val.enum);
  if (stage === undefined || taskStageDesc === undefined) return null;
  if (!lclStage) return null;
  return lclStage;
}

export function headerTimeFormatter(value: Date | string | undefined) {
  return moment(value).format("DD-MM-YYYY HH:mm");
}

export function addNewSecondToLast<T>(arr: T[], obj: T[]) {
  if (arr.length < 2) {
    return arr.concat(...obj);
  }
  const index = arr.length - 2;
  const newArray = [
    ...arr.slice(0, index),
    ...obj,
    arr[index],
    ...arr.slice(index + 1),
  ];
  return newArray;
}

export function handleActionAsync<T>(action: T) {
  return new Promise<T>((resolve, reject) => {
    if (action) {
      resolve(action);
    } else {
      reject(action);
    }
  });
}

export function handlePhoneNumber(number: string) {
  const codethree = number.substring(0, 3);
  const codetwo = number.substring(0, 2);
  const myCode = countryCodes.find(
    (x) => codetwo === x.code || codethree === x.code
  );
  if (myCode) {
    const national = number.substring(myCode.code.length);
    return { countryCode: myCode.code, national };
  }
  return {
    countryCode: codetwo,
    national: number.substring(codetwo.length),
  };
}
type Rules = ((v: string) => true | string | Promise<true>)[];
export type FormRules = {
  emailRules: Rules;
  phoneNumberRules: Rules;
  nameRules: Rules;
  requestedBy: Rules;
  allocatedTo: Rules;
  taskTime: Rules;
  description: Rules;
  problemRule: Rules;
  default: Rules;
  validateEmail: Rules;
  validatePhoneNumber: Rules;
  validateUsername: Rules;
};

export const formRules: FormRules = {
  emailRules: [
    (v: string) => !!v || i18n.t("object.formRules.email").toString(),
    (v: string) =>
      /.+@.+\..+/.test(v) || i18n.t("object.formRules.emailForm").toString(),
  ],
  phoneNumberRules: [
    (v) =>
      /^\+\d{1,3}\d{9,}$/.test(v) ||
      i18n.t("object.formRules.countryCode").toString(),
    (v) => !!v || i18n.t("object.formRules.phoNo").toString(), // Check if phone number is provide.toString()d
  ],
  nameRules: [(v) => !!v || i18n.t("object.formRules.name").toString()],
  requestedBy: [
    (v: string) => !!v || i18n.t("object.formRules.requestedBy").toString(),
  ],
  allocatedTo: [
    (v: string) => !!v || i18n.t("object.formRules.allocatedTo").toString(),
  ],
  taskTime: [
    (v: string) => !!v || i18n.t("object.formRules.taskTime").toString(),
  ],
  description: [
    (v: string) => !!v || i18n.t("object.formRules.desc").toString(),
    (v: string) =>
      (v && v.length >= 10) || i18n.t("object.formRules.descRules").toString(),
  ],
  problemRule: [
    (v: string) => !!v || i18n.t("object.formRules.problemRule").toString(),
  ],
  validateEmail: [
    (v: string) => !!v || i18n.t("object.formRules.email").toString(),
    (v: string) =>
      /.+@.+\..+/.test(v) || i18n.t("object.formRules.emailForm").toString(),
    (v: string) =>
      !checkRule("email", { email: v }) ||
      i18n.t("object.formRules.emailValidate").toString(),
  ],
  validatePhoneNumber: [
    (v: string) => !!v || i18n.t("object.formRules.phoNo").toString(),
    (v: string) =>
      /^\+\d{1,3}\d{9,}$/.test(v) ||
      i18n.t("object.formRules.countryCode").toString(),
    (v: string) =>
      !checkRule("phoneNumber", { phoneNumber: v }) ||
      i18n.t("object.formRules.phoneNoValidate").toString(),
  ],
  validateUsername: [
    (v: string) =>
      !checkRule("username", { username: v }) ||
      i18n.t("object.formRules.usernameValidate").toString(),
  ],
  default: [
    (v: string) => !!v || i18n.t("object.formRules.default").toString(),
  ],
};

export function getDateFromString(dateString: string | Date) {
  const date = new Date(dateString).toLocaleDateString();
  const time = new Date(dateString).toLocaleTimeString();
  const hour = new Date(dateString).getHours();
  const minutes = new Date(dateString).getMinutes();
  return { date, time, minutes, hour };
}

function checkRule(key: keyof User, record: Partial<User>) {
  return new UserService().validateUser(record).then((er) => {
    const exists = er[key];
    if (typeof exists === "boolean") {
      return exists ? exists : true;
    }
    return true;
  });
}

/**
 *
 * @param amount number value
 * @param locale
 * @returns {string} new amount in EUR format
 */
export function formatAmount(
  amount: number | string,
  locale?: string,
  options?: Intl.NumberFormatOptions
) {
  amount = typeof amount === "string" ? parseInt(amount) : amount;
  if (locale) {
    return new Intl.NumberFormat(locale, {
      style: "currency",
      currency: "EUR",
      ...options,
    }).format(amount);
  }
  return new Intl.NumberFormat(undefined, {
    style: "currency",
    currency: "EUR",
    ...options,
  }).format(amount);
}

/**
 *
 * @param time String Time format
 * @param format string format type
 * @param locale country locale format
 * @returns {string} time
 */
export function formatTime(time: string, format?: string, locale?: string) {
  if (!time) return time;
  if (locale && format) {
    return moment(time).locale(locale).format(format);
  }
  if (locale) {
    return moment(time).locale(locale).format("LLL");
  }
  if (format) {
    return moment(time).locale("de").format(format);
  }
  return moment(time).locale("de").format("LLL");
}

export function getFullName(person: Person) {
  if (!person) return "";
  if (person.fullName) return person.fullName;
  if (!person?.firstName && !person?.lastName) return "";
  if (person?.firstName && person?.lastName) {
    return `${person?.firstName} ${person?.lastName}`;
  }
  return person?.lastName ?? person?.firstName;
}

export function sumValues(...numbers: (number | undefined)[]) {
  const validNumber = numbers.map((num) => {
    if (num && typeof num !== "number") {
      return parseFloat(num);
    }
    return num;
  }) as number[];
  return validNumber.reduce((acc, num) => acc + num, 0);
}

export const taskMainCategories = [
  "Schädlingsbekämpfung",
  "Rohrreinigung",
  "Sanitär",
  "Elektro",
  "Andere",
];

export const taskSubCategories = [
  { item: "Wespen", parent: "Schädlingsbekämpfung" },
  { item: "Ratten", parent: "Schädlingsbekämpfung" },
  { item: "Mäuse", parent: "Schädlingsbekämpfung" },
  { item: "Bettwanzen", parent: "Schädlingsbekämpfung" },
  { item: "Silberfische", parent: "Schädlingsbekämpfung" },
  { item: "Marder", parent: "Schädlingsbekämpfung" },
  { item: "Schaben", parent: "Schädlingsbekämpfung" },
  { item: "Käfer", parent: "Schädlingsbekämpfung" },
  { item: "Toilette verstopft", parent: "Rohrreinigung" },
  { item: "Küche verstopft", parent: "Rohrreinigung" },
  { item: "Fettabscheider verstopft", parent: "Rohrreinigung" },
  { item: "Rohrleitung verstopft", parent: "Rohrreinigung" },
  { item: "Rohrbruch", parent: "Sanitär" },
  { item: "Wasserleitung defekt", parent: "Sanitär" },
  { item: "Sanitäranlagen defekt", parent: "Sanitär" },
  { item: "Stromausfall", parent: "Elektro" },
  { item: "Herdanschluss", parent: "Elektro" },
];

type TK = { [key: string]: string };
export function gotoPathQuery(route: Route, router: VueRouter, query?: TK) {
  const path = route.name?.toString();
  const param = route.params;
  const currentQuery = route.query;

  const hasChanged = Object.keys(query ?? {}).some(
    (key) => currentQuery[key] !== query?.[key]
  );

  if (hasChanged) {
    router.push({
      name: path,
      params: param,
      query,
    });
  }
}

export function checkTaskTimeOut(allocation: Allocation) {
  const spViewTime = moment(allocation.viewTime);
  const currentTime = moment();
  const timeDifference = currentTime.diff(spViewTime, "minutes");

  return timeDifference > allowedTime;
}

export function tastTimeDisplay(task: Task, route: Route) {
  const startTimeMoment = moment(task?.startTime);
  const endTimeMoment = moment(task?.endTime);
  const locale = route.params.locale;

  const startTime = startTimeMoment
    .locale(locale)
    .format("dddd DD.MM.YYYY HH:mm");
  const endTime = endTimeMoment.locale(locale).format("dddd DD.MM.YYYY HH:mm");

  if (task?.startTime && task?.endTime) {
    // Check if the start and end times are on the same day
    const sameDay = startTimeMoment.isSame(endTimeMoment, "day");

    return sameDay
      ? `${startTime} Uhr bis ${endTimeMoment
          .locale(locale)
          .format("HH:mm")} Uhr`
      : `${startTime} Uhr bis ${endTime} Uhr`;
  } else if (task?.startTime) {
    return `${startTime} Uhr`;
  } else if (task?.endTime) {
    return `bis ${endTime} Uhr`;
  }
  return i18n.t("object.task.noTimeText").toString();
}
