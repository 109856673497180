<template>
  <span>
    <usersComponent />
  </span>
</template>

<script lang="ts">
import UsersComponent from "@/components/users/users-component.vue";
import userModule from "@/store/modules/user-module";
import Vue from "vue";

export default Vue.extend({
  name: "UsersView",
  components: {
    usersComponent: UsersComponent,
  },
  computed: {
    loading: {
      get() {
        return this.$store.state.isLoading;
      },
      set(value) {
        this.$store.commit("setLoading", value);
      },
    },
  },
  mounted() {
    this.loadusers();
  },
  methods: {
    loadusers() {
      this.loading = true;
      userModule.getUserList().finally(() => (this.loading = false));
    },
  },
});
</script>

<style></style>
