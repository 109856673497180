<template>
  <v-container class="pa-0">
    <v-card max-width="800">
      <v-container class="d-flex justify-space-between">
        <v-chip label color="mainbg">
          <v-icon left small color="primary"> mdi-tag-outline </v-icon>
          <strong> {{ allocation?.task?.ticketNumber }} </strong>
        </v-chip>

        <statusDisplayComponent
          :color="orderStageFormat?.color"
          :text="orderStageFormat?.value"
        />
      </v-container>

      <v-container v-if="orderStage < stage.created">
        <approvalFirstStageComponent :value="allocation" />
      </v-container>
      <v-container v-if="withinActionStage">
        <v-window v-model="orderData.stage" touchless>
          <v-window-item :value="stage.viewed">
            <v-container>
              <approvalFirstStageComponent :value="allocation" />
            </v-container>
          </v-window-item>

          <v-window-item :value="stage.accepted">
            <v-container>
              <approvalSecondStageComponent :value="allocation" />
            </v-container>
          </v-window-item>
        </v-window>
      </v-container>
      <v-container v-if="orderStage >= stage.done">
        <approvalSecondStageComponent :value="allocation" />
      </v-container>

      <v-divider></v-divider>

      <v-card-actions v-if="withinActionStage">
        <v-row align-content="center" justify="start">
          <v-col cols="12" md="3" align-self="start">
            <v-btn
              :loading="backBtnLoad"
              block
              large
              @click="backAction"
              min-width="100"
              outlined
              color="primary"
            >
              {{ btnText?.back }}
            </v-btn>
          </v-col>
          <v-col cols="12" md="3">
            <v-btn
              block
              large
              min-width="100"
              :loading="nextBtnLoad"
              color="primary"
              @click="nextBtnAction"
            >
              {{ btnText?.next }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>

    <decisiondialog-component
      v-model="priceDialog"
      :priceOptional="!hasPrice"
      @abort="hidePriceDialog"
      @price="updatePrice"
      @comment="updateComment"
      @submit="submitFinishedRequest"
    />
  </v-container>
</template>

<script lang="ts">
import {
  acceptOrderRequest,
  CancelOrderAfterAccept,
  declineOrderRequest,
  orderComplete,
} from "@/helpers/orderservice-helper";
import formatterModule from "@/store/modules/formatter-module";
import orderserviceModule from "@/store/modules/orderservice-module";
import {
  Allocation,
  EnumStatus,
  EnumTaskStage,
} from "@taskmanagement/taskapp-model";
import moment from "moment";
import Vue from "vue";
import { PropValidator } from "vue/types/options";
import ApprovalFirstStageComponent from "../../decision/approvalfirststage-component.vue";
import ApprovalSecondStageComponent from "../../decision/approvalsecondstage-component.vue";
import DecisiondialogComponent from "../../decision/decisiondialog-component.vue";
import StatusDisplayComponent from "../../statusdisplay-component.vue";

export default Vue.extend({
  components: {
    approvalFirstStageComponent: ApprovalFirstStageComponent,
    statusDisplayComponent: StatusDisplayComponent,
    approvalSecondStageComponent: ApprovalSecondStageComponent,
    DecisiondialogComponent,
  },
  name: "OrderServiceComponent",
  props: {
    allocation: { type: Object } as PropValidator<Allocation>,
  },
  data: () => ({
    stage: EnumTaskStage,
    nextBtnLoad: false,
    backBtnLoad: false,
    priceDialog: false,
    hasPrice: false,
    localUpdate: {
      price: null as string | number | null,
      comment: null as string | null,
    },
  }),

  computed: {
    orderData() {
      return orderserviceModule.orderRequest?.task;
    },
    btnText() {
      const step = orderserviceModule.orderRequest.stage;
      switch (step) {
        case EnumTaskStage.viewed:
          return {
            back: this.$t("object.btn.reject"),
            next: this.$t("site.btn.accept"),
          };
        case EnumTaskStage.accepted:
          return {
            back: this.$t("site.btn.decline"),
            next: this.$t("object.approval.step.header2"),
          };
        default:
          return { back: "", next: "" };
      }
    },

    decidedRequest() {
      const request = orderserviceModule.orderRequest;
      return {
        ...request,
        agentComment: this.localUpdate?.comment,
        task: { ...request.task, price: this.localUpdate?.price },
      } as Allocation;
    },
    hideActions() {
      const order = orderserviceModule.orderRequest;
      const step = order.stage;
      return step > EnumTaskStage.created && step < EnumTaskStage.done;
    },
    orderStage() {
      return orderserviceModule.orderRequest.stage;
    },
    orderStageFormat() {
      const stage = orderserviceModule.orderRequest.stage;
      return formatterModule.taskStage.find((x) => x.enum === stage) ?? null;
    },
    orderTimeOfView() {
      const time = orderserviceModule.orderRequest?.viewTime;
      return time ? moment(time).format("LLL") : null;
    },
    withinActionStage() {
      const order = orderserviceModule.orderRequest;
      const step = order.stage;
      const decision = order.decision;
      // eslint-disable-next-line prettier/prettier
      const actionStages = (step >= EnumTaskStage.created) && (step < EnumTaskStage.done);
      // eslint-disable-next-line prettier/prettier
      const actionStatus = (decision === undefined || decision === null) || (decision === EnumStatus.accepted);
      return actionStages && actionStatus;
    },
  },
  methods: {
    updatePrice(price: number | string) {
      this.localUpdate.price = price;
    },
    updateComment(comment: string) {
      this.localUpdate.comment = comment;
    },
    nextBtnAction() {
      const orderRequest = orderserviceModule.orderRequest;
      const step = orderRequest.stage;
      switch (step) {
        case EnumTaskStage.viewed:
          this.nextBtnLoad = true;
          acceptOrderRequest(orderRequest).finally(() => {
            this.nextBtnLoad = false;
          });
          break;
        case EnumTaskStage.accepted:
          this.$nextTick(() => {
            this.$set(this, "hasPrice", true);
          });
          Promise.resolve()
            .then(this.showPriceDialog)
            .finally(() => {
              this.nextBtnLoad = false;
            });
          break;

        default:
          break;
      }
    },
    backAction() {
      const orderRequest = orderserviceModule.orderRequest;
      const step = orderRequest.stage;
      switch (step) {
        case EnumTaskStage.viewed:
          this.backBtnLoad = true;
          declineOrderRequest(orderRequest).finally(() => {
            this.backBtnLoad = false;
            this.$router.back();
          });
          break;
        case EnumTaskStage.accepted:
          this.$nextTick(() => {
            this.$set(this, "hasPrice", false);
          });
          Promise.resolve()
            .then(this.showPriceDialog)
            .finally(() => {
              this.nextBtnLoad = false;
            });
          break;

        default:
          break;
      }
    },
    submitFinishedRequest() {
      this.$store.state.isLoading = true;
      this.priceDialog = false;
      if (this.hasPrice) {
        return orderComplete(this.decidedRequest).finally(() => {
          this.$store.state.isLoading = false;
          this.priceDialog = false;
          this.$router.back();
        });
      }
      return CancelOrderAfterAccept(this.decidedRequest).finally(() => {
        this.$store.state.isLoading = false;
        this.$router.back();
      });
    },
    showPriceDialog() {
      this.priceDialog = true;
    },
    hidePriceDialog() {
      this.priceDialog = false;
    },
  },
});
</script>

<style></style>
