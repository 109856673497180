import Vue from "vue";
// import { i18n } from "../plugins/i18n";
import { AxiosError } from "axios";
import { TranslateResult } from "vue-i18n";
import { i18n } from "../plugins/i18n";

export function handleGlobalError(error: AxiosError) {
  if (error.response?.status) {
    const apiError = (error.response.data as { message?: string })
      .message as string;
    console.error(apiError);
  }
}

export function handleGlobalCreate<T>(
  data: T | void,
  message?: string | TranslateResult
): T | void {
  if (data) {
    Vue.$toast.success(message ?? i18n.t("site.toast.recordCreated"));
  }
  return data;
}

export function handleGlobalUpdate<T>(
  data: T | void,
  message?: string | TranslateResult
): T | void {
  if (data) {
    Vue.$toast.success(message ?? i18n.t("site.toast.recordUpdated"));
  }
  return data;
}
// export function errorMessage(errorCode: number) {
//   switch (errorCode) {
//     case ERRORCODE.authorization: {
//       return i18n.t("site.error.auth");
//     }
//     case ERRORCODE.password: {
//       return "incorrect password...";
//     }
//     default:
//       return "Error";
//   }
// }
