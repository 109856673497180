<template>
  <v-container class="pa-0">
    <slot name="top"></slot>

    <v-card-title>
      <strong> {{ title }} </strong>
      <v-spacer />
      <v-chip
        small
        label
        color="mainbg"
        class="justify-space-between"
        v-if="ID"
      >
        ID: <strong> {{ ID }} </strong>
      </v-chip>
    </v-card-title>
    <v-card-subtitle class="text-start">
      {{ subtitle }}
    </v-card-subtitle>

    <v-card class="pa-2 pb-12" flat>
      <v-container
        class="items-start py-0"
        v-for="(card, idx) in value"
        :key="idx"
      >
        <v-card-actions
          class="pb-0 mt-2 items-start text-start"
          v-if="card.rowItems"
        >
          <span class="text-subtitle-2 font-weight-bold">
            {{ card.cardTitle }}
          </span>
          <v-spacer />
          <v-btn color="primary" text small v-if="card.editBtn">Edit</v-btn>
        </v-card-actions>
        <v-card outlined v-if="card.rowItems">
          <v-row dense no-gutters>
            <v-col cols="12" md="6" v-for="(row, i) in card.rowItems" :key="i">
              <viewDisplayItemComponent
                :icon="row.icon"
                :title="row.title"
                :subTitle="row.subTitle"
                :headertext="row.headerText"
              />
            </v-col>
          </v-row>
        </v-card>
      </v-container>
      <v-container>
        <slot name="bottom"></slot>
      </v-container>
    </v-card>
  </v-container>
</template>

<script lang="ts">
import { TypeCardOverview } from "@/model";
import Vue from "vue";
import { PropValidator } from "vue/types/options";
import ViewDisplayItemComponent from "../order/viewdisplayitem-component.vue";

export default Vue.extend({
  name: "OrderOverviewComponent",
  components: {
    viewDisplayItemComponent: ViewDisplayItemComponent,
  },
  data: () => ({}),
  props: {
    value: { type: Array } as PropValidator<TypeCardOverview[]>,
    ID: { type: String },
    title: { type: String },
    subtitle: { type: String },
  },
});
</script>

<style></style>
