<template>
  <v-card flat color="transparent" class="pa-0">
    <span v-for="(info, i) in information" :key="i">
      <v-card outlined class="my-4 px-1" v-if="!info.hide">
        <v-card-title class="py-0">
          <strong> {{ info.title }} </strong> <v-spacer />
          <v-btn
            v-if="info?.href"
            fab
            x-small
            icon
            color="primary"
            :href="info.href"
            target="_blank"
          >
            <v-icon>{{ info.icon }}</v-icon>
          </v-btn>
        </v-card-title>

        <v-divider class="py-2" />

        <v-row dense v-for="(client, i) in info.value" :key="i" class="px-4">
          <v-col :cols="6" align="start" v-if="client.title">
            {{ client.title }}
          </v-col>
          <v-col :cols="6" align="end" v-if="client.value">
            <strong> {{ client.value }} </strong>
          </v-col>
        </v-row>
      </v-card>
    </span>
  </v-card>
</template>

<script lang="ts">
import { tastTimeDisplay } from "@/helpers/component-helper";
import { finishTask, setDeclineTask } from "@/helpers/decision-helpers";
import { InformationType } from "@/model";
import { Allocation } from "@taskmanagement/taskapp-model";
import Vue from "vue";
import { TranslateResult } from "vue-i18n";

type Information = {
  title: TranslateResult;
  hide?: boolean;
  icon?: string | null;
  href?: string | null;
  value: InformationType[];
};
type PropType = { value: Allocation };
type MethodType = {
  finish(val: number | string): void;
  cancel(val: string): void;
  showPriceDialog(): void;
  hidePriceDialog(): void;
  setLoading(val: boolean): void;
  showCancelDialog(): void;
  hideCancelDialog(): void;
};
type DataType = { priceDialog: boolean; cancelDialog: boolean | null };
type ComputedType = {
  information: Information[];
};
export default Vue.extend<DataType, MethodType, ComputedType, PropType>({
  name: "ApprovalSecondStageComponent",
  data: () => ({
    priceDialog: false,
    cancelDialog: null,
  }),
  components: {},
  props: {
    value: { type: Object },
  },
  computed: {
    information() {
      const task = this.value?.task;
      const address = `${task.streetNo ?? ""} ${task.street ?? ""} ${
        task.postalCode ?? ""
      } ${task.city ?? ""} ${task.country ?? ""}`;
      return [
        {
          title: this.$i18n.t("object.task.taskInfo"),
          icon: "mdi-map-marker",
          href:
            task?.city && task.postalCode
              ? `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                  address
                )}`
              : null,
          value: [
            { title: this.$i18n.t("object.task.title"), value: task?.title },
            {
              title: this.$i18n.t("object.task.description"),
              value: task?.description,
            },
            {
              title: this.$i18n.t("object.task.appTime"),
              value: tastTimeDisplay(task, this.$route) as string,
            },
            { title: this.$t("object.misc.houseNumber"), value: task.streetNo },
            { title: this.$i18n.t("object.task.street"), value: task.street },
            {
              title: this.$i18n.t("object.person.postalCode"),
              value: task.postalCode,
            },
            {
              title: this.$i18n.t("object.person.city"),
              value: task.city,
            },
          ],
        },
        {
          title: this.$i18n.t("object.misc.clientInfo"),
          icon: "mdi-phone",
          href: task.requesterPhoneNo ? `tel:${task.requesterPhoneNo}` : null,
          value: [
            {
              title: this.$i18n.t("object.person.firstName"),
              value: task?.requesterFirstName,
            },
            {
              title: this.$i18n.t("object.person.lastName"),
              value: task?.requesterLastName,
            },
            {
              title: this.$i18n.t("object.misc.clientNo"),
              value: task?.requesterPhoneNo,
            },
          ],
        },
        {
          title: this.$t("object.task.comment"),
          hide: !task?.comment,
          value: [
            {
              title: task?.comment ?? null,
              value: null,
            },
          ],
        },
      ];
    },
  },
  methods: {
    finish(val) {
      const allocation = this.value;
      allocation.task.price = parseInt(val as string);
      this.setLoading(true);
      finishTask(this.value).finally(() => {
        this.hidePriceDialog();
        this.setLoading(false);
      });
    },
    cancel() {
      this.setLoading(true);
      setDeclineTask(this.value).finally(() => {
        this.hideCancelDialog();
        this.setLoading(false);
      });
    },
    setLoading(val) {
      this.$emit("load", val);
    },
    showPriceDialog() {
      this.priceDialog = true;
    },
    hidePriceDialog() {
      this.priceDialog = false;
    },
    showCancelDialog() {
      this.cancelDialog = true;
    },
    hideCancelDialog() {
      this.cancelDialog = false;
    },
  },
});
</script>
