<template>
  <v-container>
    <agentOrderTableComponent />
  </v-container>
</template>

<script lang="ts">
import Vue from "vue";
import AgentOrderTableComponent from "./agentordertable-component.vue";

export default Vue.extend({
  name: "AgentOrderComponent",
  components: { agentOrderTableComponent: AgentOrderTableComponent },
});
</script>

<style></style>
