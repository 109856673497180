<template>
  <v-dialog v-model="dialog" max-width="350">
    <v-card>
      <v-card-title class="text-h6 grey lighten-2">
        Update User Profile
      </v-card-title>

      <v-container class="pa-4" v-if="type === userType.USERINFO">
        <v-form>
          <v-text-field
            outlined
            :label="$t('object.person.firstName')"
            v-model="user.firstName"
            :disabled="isCurrentUser"
          />
          <v-text-field
            outlined
            :label="$t('object.person.lastName')"
            v-model="user.lastName"
            :disabled="isCurrentUser"
          />
          <v-select
            outlined
            :items="roleList"
            item-text="value"
            item-disabled="1002"
            item-value="enum"
            v-model="user.role"
            v-if="canUpdateRole"
          />
        </v-form>
      </v-container>
      <v-container class="pa-4" v-if="type === userType.CONTACT">
        <v-form>
          <v-text-field
            outlined
            :label="$t('object.person.phoneNo')"
            v-model="user.phoneNumber"
            :rules="formRules.phoneNumberRules"
            :disabled="isCurrentUser"
          />
          <v-text-field
            outlined
            :label="$t('object.person.email')"
            v-model="user.email"
            :disabled="isCurrentUser"
          />
        </v-form>
      </v-container>

      <v-container class="pa-4" v-if="type === userType.ADDRESS">
        <v-form>
          <v-text-field
            outlined
            :label="$t('object.task.streetNo')"
            v-model="address.streetNo"
            :disabled="isCurrentUser"
          />
          <v-text-field
            outlined
            :label="$t('object.task.streetName')"
            v-model="address.street"
            :disabled="isCurrentUser"
          />
          <v-text-field
            outlined
            :label="$t('object.person.postalCode')"
            v-model="address.postalCode"
            :disabled="isCurrentUser"
          />
          <v-text-field
            outlined
            :label="$t('object.person.city')"
            v-model="address.city"
            :disabled="isCurrentUser"
          />
          <v-text-field
            outlined
            :label="$t('object.person.country')"
            v-model="address.country"
            :disabled="isCurrentUser"
          />
        </v-form>
      </v-container>

      <v-divider />

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="onCancel"> Cancel </v-btn>
        <v-btn color="primary" text @click="onSave">
          {{ $t("site.btn.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { formRules } from "@/helpers/component-helper";
import { TypeStageFormatter } from "@/model";
import { EnumUserEdit } from "@/model/user-model";
import { allowedRouteAccess } from "@/plugins/router";
import authModule from "@/store/modules/auth-module";
import formatterModule from "@/store/modules/formatter-module";
import {
  Address,
  EnumRoles,
  LoginUser,
  User,
} from "@taskmanagement/taskapp-model";
import Vue, { nextTick } from "vue";

type PropType = { value: boolean; type: EnumUserEdit; item: User };
type MethodType = {
  setUserOnLoad(): void;
  onCancel(): void;
  onSave(): void;
};
type DataType = {
  user: User;
  userType: typeof EnumUserEdit;
  role: typeof EnumRoles;
};
type ComputeType = {
  dialog: boolean;
  roleList: TypeStageFormatter<EnumRoles>[];
  currentUser: LoginUser;
  isCurrentUser: boolean;
  hideField: boolean;
  currentUserRole: EnumRoles | null;
  canUpdateRole: boolean;
  address: Address;
  formRules: typeof formRules;
};

export default Vue.extend<DataType, MethodType, ComputeType, PropType>({
  name: "ProfileEditDialogComponent",
  data: () => ({
    user: new User(),
    userType: EnumUserEdit,
    role: EnumRoles,
  }),
  props: {
    value: { type: Boolean },
    item: { type: Object },
    type: { type: Number },
  },
  watch: {
    value(val) {
      if (!val) {
        this.user = new User();
      }
      if (val) {
        this.setUserOnLoad();
      }
    },
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    address: {
      get() {
        return this.user.address ?? new Address();
      },
      set(value) {
        this.user.address = Object.assign(new Address(), value);
      },
    },
    roleList() {
      return formatterModule.userRoles;
    },
    formRules() {
      return formRules;
    },
    currentUser() {
      return authModule.activeUser as LoginUser;
    },
    isCurrentUser() {
      return this.item.username == this.currentUser.username;
    },
    currentUserRole() {
      return authModule.role ?? null;
    },
    hideField() {
      return this.user.role ? this.user.role !== EnumRoles.SUPERUSER : false;
    },
    canUpdateRole() {
      if (this.currentUserRole) {
        const hasAccess = allowedRouteAccess(EnumRoles.ADMIN).includes(
          this.currentUserRole
        );
        return hasAccess;
      }
      return false;
    },
  },
  methods: {
    setUserOnLoad() {
      nextTick(() => {
        if (this.item) this.$set(this, "user", { ...this.item });
      });
    },
    onSave() {
      this.user.address = this.address;
      this.$emit("submit", this.user);
      this.user = new User();
      this.dialog = false;
    },
    onCancel() {
      this.user = new User();
      this.dialog = false;
    },
  },
});
</script>

<style></style>
