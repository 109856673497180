import { Allocation } from "@taskmanagement/taskapp-model";
import {
  Action,
  Module,
  Mutation,
  VuexModule,
  getModule,
} from "vuex-module-decorators";
import store from "..";
import { deleteFromArray, updateArray } from "../../helpers/component-helper";
import { AllocationService } from "../../services/allocation-service";

@Module({
  name: "allocation",
  store,
  dynamic: true,
})
class AllocationModule extends VuexModule {
  allocationList = Array<Allocation>();

  @Action({ commit: "retrieveListMutation" })
  retrieveList() {
    return new AllocationService().getAll().catch((err) => console.error(err));
  }

  @Action({ commit: "createMutation" })
  create(allocation: Allocation) {
    return new AllocationService()
      .create(allocation)
      .catch((err) => console.error(err));
  }

  @Action({ commit: "updateMutation" })
  update(allocation: Allocation) {
    return new AllocationService()
      .update(allocation)
      .then((val) => {
        if (val) return allocation;
      })
      .catch((err) => console.error(err));
  }

  @Action({ commit: "deleteMutation" })
  delete(allocation: Allocation) {
    return new AllocationService()
      .delete(allocation)
      .then((val) => {
        if (val) return allocation;
      })
      .catch((err) => console.error(err));
  }

  @Mutation
  retrieveListMutation(data: Allocation[]) {
    if (data) this.allocationList = data;
  }

  @Mutation
  createMutation(data: Allocation | undefined) {
    if (data && data.id) this.allocationList.push(data);
  }

  @Mutation
  updateMutation(data: Allocation | undefined) {
    if (data) {
      this.allocationList = updateArray(this.allocationList, data.id, data);
    }
  }

  @Mutation
  deleteMutation(data: Allocation | undefined) {
    if (data) {
      this.allocationList = deleteFromArray(this.allocationList, data);
    }
  }
}

export default getModule(AllocationModule);
