import { API_URL } from "@/model/config";
import { i18n } from "@/plugins/i18n";
import router from "@/plugins/router";
import authModule from "@/store/modules/auth-module";
import {
  authAccessTokenName,
  headerAcceptLanguage,
} from "@taskmanagement/taskapp-model";
import axios from "axios";
import Vue from "vue";
import { getCookies } from "./cookies-helper";

const token = getCookies(authAccessTokenName);
const localAxios = axios.create({
  baseURL: API_URL,
  headers: {
    Authorization: token ? token : undefined,
    [headerAcceptLanguage]: router?.currentRoute?.params?.locale,
  },
});

localAxios.interceptors.request.use(
  (config) => {
    const updatedToken = getCookies(authAccessTokenName);
    if (updatedToken) {
      config.headers.Authorization = updatedToken;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

localAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      const errorCode = error.response.status;
      const errorStatus = error.response.status;

      if (errorCode === 401 || errorStatus === 400) {
        authModule.logoutUser();
      }
      if (errorCode === 404 || errorStatus === 404) {
        Vue.$toast(i18n.t("object.misc.404text").toString());
      } else {
        Vue.$toast(i18n.t("object.misc.404text").toString());
      }
      return Promise.reject(error);
    }
  }
);

export default localAxios;
