<template>
  <agentOrderComponent />
</template>

<script lang="ts">
import AgentOrderComponent from "@/components/order/agent/agentorder-component.vue";

export default {
  name: "AgentOrdersView",
  components: {
    agentOrderComponent: AgentOrderComponent,
  },
};
</script>

<style></style>
