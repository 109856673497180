<template>
  <v-chip
    label
    :outlined="outlined"
    :width="width"
    :small="small"
    :color="outlined ? color : 'transparent'"
    :text-color="color"
    style="min-width: 85px; justify-content: center"
  >
    <v-icon :color="color" small class="pr-1" v-if="!outlined && color">
      mdi-circle
    </v-icon>
    <v-icon left small v-if="icon">{{ icon }}</v-icon>
    <small style="font-weight: 600">
      {{ text }}
    </small>
  </v-chip>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "StatusDisplayComponent",
  props: {
    color: { type: String },
    textColor: { type: String, default: "white" },
    width: { type: Number, default: 110 },
    outlined: { type: Boolean },
    text: { type: String },
    small: { type: Boolean },
    icon: { type: String },
  },
});
</script>

<style></style>
