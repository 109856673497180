<template>
  <v-card max-width="1000" class="pa-4" outlined>
    <v-card-title class="pa-0">
      <v-spacer />
      <v-avatar
        color="primary lighten-2"
        class="subheading white--text"
        size="24"
        >{{ step }}</v-avatar
      >
    </v-card-title>
    <v-window v-model="step" touchless>
      <v-window-item :value="1">
        <v-card-title class="py-4 justify-space-between">
          <strong>{{ $t("object.auth.verifyHeader") }}</strong>
        </v-card-title>
        <v-card-subtitle class="text-start pb-0 mt-4">
          {{ $t("object.auth.subtitle1") }}
        </v-card-subtitle>
        <v-card-subtitle class="text-start pt-1">
          <strong>{{ $t("object.misc.note") }}:</strong>
          {{ $t("object.auth.subtitle2") }}
        </v-card-subtitle>
        <v-container class="mt-4">
          <v-form v-model="formValid">
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="userData.firstName"
                  color="primary"
                  outlined
                  :rules="formRules.nameRules"
                  :label="$t('object.person.firstName')"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="userData.lastName"
                  :rules="formRules.nameRules"
                  outlined
                  color="primary"
                  :label="$t('object.person.lastName')"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  :rules="formRules.emailRules"
                  v-model="userData.email"
                  outlined
                  color="primary"
                  :label="$t('object.person.email')"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="userData.phoneNumber"
                  outlined
                  :rules="formRules.phoneNumberRules"
                  :label="$t('object.person.phoneNo')"
                  color="primary"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12">
                <v-text-field
                  v-model="userData.companyName"
                  outlined
                  color="primary"
                  :label="$t('object.person.companyName')"
                />
              </v-col>
            </v-row>
          </v-form>

          <v-checkbox v-model="consent" :label="$t('object.auth.concent')" />
        </v-container>
      </v-window-item>
      <v-window-item :value="2">
        <v-card flat class="text-start">
          <v-card-title>{{ $t("object.misc.createPassword") }}</v-card-title>
          <v-card-subtitle>
            {{ $t("object.misc.passwordChoice") }}
          </v-card-subtitle>
        </v-card>
        <v-card max-width="300" class="items-start" outlined>
          <viewdisplayitem-component
            :icon="'mdi-account-outline'"
            :title="userData.username"
            :headertext="$t('site.access.username')"
            outlined
          />
        </v-card>
        <v-container class="items-center">
          <v-form class="my-4" v-model="formValid">
            <v-text-field
              :label="$t('site.access.password')"
              color="primary"
              :append-icon="
                showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
              "
              :type="showPassword ? 'text' : 'password'"
              outlined
              :rules="passwordRule"
              clearable
              v-model="userData.password"
              clear-icon="mdi-close-circle-outline"
              @click:append="showPassword = !showPassword"
            />
            <v-text-field
              :label="$t('site.access.password')"
              color="primary"
              :rules="confirmPasswordRule"
              :append-icon="
                showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
              "
              :type="showPassword ? 'text' : 'password'"
              outlined
              clearable
              clear-icon="mdi-close-circle-outline"
              @click:append="showPassword = !showPassword"
            />
          </v-form>
        </v-container>
      </v-window-item>
    </v-window>

    <v-card-actions>
      <v-row>
        <v-col cols="12" md="3">
          <v-btn
            min-width="200"
            color="primary"
            block
            :disabled="!allowSubmit"
            :loading="btnloader"
            @click="nextBtn"
          >
            {{ step == 1 ? $t("site.btn.next") : $t("site.btn.save") }}
          </v-btn>
        </v-col>
        <v-col cols="12" md="3">
          <v-btn
            min-width="200"
            block
            color="primary"
            outlined
            @click="pervBtn"
          >
            {{ $t("site.btn.cancel") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import { formRules } from "@/helpers/component-helper";
import { ROUTE_HOME } from "@/model/routes";
import AuthService from "@/services/auth-service";
import { User } from "@taskmanagement/taskapp-model";
import Vue from "vue";
import viewdisplayitemComponent from "../order/viewdisplayitem-component.vue";

export default Vue.extend({
  components: { viewdisplayitemComponent },
  name: "AccessAuthComponent",
  data: () => ({
    step: 1,
    userData: new User(),
    showPassword: false,
    showConfirmPassword: false,
    consent: false,
    formValid: false,
    btnloader: false,
  }),
  mounted() {
    this.getParams();
  },
  computed: {
    passwordRule() {
      return [
        (v: string) =>
          (v && v.length >= 8) || this.$t("object.rule.passwordrule.length"),
        (v: string) =>
          /[A-Z]/.test(v) || this.$t("object.rule.passwordrule.upperCase"),
        (v: string) =>
          /[a-z]/.test(v) || this.$t("object.rule.passwordrule.lowerCase"),
        (v: string) =>
          /\d/.test(v) || this.$t("object.rule.passwordrule.number"),
        (v: string) =>
          /[!@#$%^&*(),.?":{}|<>]/.test(v) ||
          this.$t("object.rule.passwordrule.specialChar"),
      ];
    },
    confirmPasswordRule() {
      return [
        (v: string) => !!v || this.$t("object.rule.passwordrule.confirm"),
        (v: string) =>
          v === this.userData.password ||
          this.$t("object.rule.passwordrule.match"),
      ];
    },
    allowSubmit() {
      return this.consent && this.formValid;
    },
    formRules() {
      return formRules;
    },
    partialUser() {
      const data = this.userData;
      return {
        id: data.id,
        firstName: data.firstName,
        lastName: data.lastName,
        companyName: data.companyName,
        email: data.email,
        phoneNumber: data.phoneNumber,
      } as Partial<User>;
    },
  },
  methods: {
    getParams() {
      const params = this.$route.query;
      new AuthService()
        .verifyToken({
          token: params.token as string | undefined,
          signup: params.signup === "true^",
        })
        .then((data) => (this.userData = data));
    },
    onSubmit() {
      this.btnloader = true;
      new AuthService()
        .completeVerify(this.partialUser)
        .then((res) => {
          if (res) this.$router.push({ name: ROUTE_HOME });
        })
        .finally(() => {
          this.btnloader = false;
        });
    },
    onClose() {
      window.close();
    },
    nextBtn() {
      switch (this.step) {
        case 1:
          this.step = 2;
          break;
        case 2:
          this.onSubmit();
          break;

        default:
          break;
      }
    },

    pervBtn() {
      switch (this.step) {
        case 1:
          this.onClose();
          break;
        case 2:
          this.step = 1;
          this.onSubmit();
          break;

        default:
          break;
      }
      //
    },
  },
});
</script>

<style></style>
