<template>
  <span>
    <v-row class="fill-height">
      <v-container
        flat
        class="ma-2 fill-height"
        :max-height="$vuetify.breakpoint.mdAndUp ? 700 : null"
      >
        <v-col cols="12" md="5" class="fill-height" align-self="auto">
          <profileInfoComponent :user="user" class="pa-2" />
        </v-col>
        <v-col
          cols="12"
          md="7"
          class="fill-height"
          v-if="!$vuetify.breakpoint.mobile"
        >
          <v-container class="fill-height d-flex flex-column">
            <v-card
              class="fill-width ma-4 my-8"
              elevation="1"
              style="height: 40%; width: 100%; gap: 10"
              v-if="user.role === type.AGENT"
            >
              <userDescriptionComponent />
            </v-card>
          </v-container>
        </v-col>
      </v-container>
    </v-row>
  </span>
</template>

<script lang="ts">
import { EnumBoolean, EnumRoles, User } from "@taskmanagement/taskapp-model";
import Vue from "vue";
import { PropValidator } from "vue/types/options";
import ProfileInfoComponent from "../subcomponent/profile/profileinfo-component.vue";
import UserDescriptionComponent from "./userdescription-component.vue";

export default Vue.extend({
  components: {
    profileInfoComponent: ProfileInfoComponent,
    userDescriptionComponent: UserDescriptionComponent,
  },
  name: "UserDetailsComponent",
  data: () => ({
    boolean: EnumBoolean,
    type: EnumRoles,
  }),
  props: {
    user: { type: Object } as PropValidator<User>,
  },
});
</script>

<style></style>
