<template>
  <v-list-item>
    <v-list-item-content>
      <v-list-item-subtitle
        class="text-start primary--text"
        v-if="!!title || !!subTitle"
      >
        <v-icon small color="primary">{{ icon }}</v-icon>
        {{ headertext }}
      </v-list-item-subtitle>
      <v-list-item-title class="text-start" style="white-space: normal">
        {{ title }}
        <v-icon v-if="!!subTitle">mdi-circle-small</v-icon>
        {{ subTitle }}
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "ViewDisplayItemComponent",
  props: {
    title: { type: String },
    subTitle: { type: String },
    headertext: { type: String },
    icon: { type: String },
  },
});
</script>

<style></style>
