<template>
  <span>
    <userDetailsComponent :user="user" />
  </span>
</template>

<script lang="ts">
import UserDetailsComponent from "@/components/users/userdetails-component.vue";
import userModule from "@/store/modules/user-module";
import Vue from "vue";

export default Vue.extend({
  name: "UserDetails",
  components: { userDetailsComponent: UserDetailsComponent },
  computed: {
    username() {
      return this.$route.params.username;
    },
    user() {
      return userModule.user;
    },
  },
  mounted() {
    this.getUserData();
  },
  methods: {
    getUserData() {
      this.$store.state.isLoading = true;
      userModule.getUser(this.username).finally(() => {
        this.$store.state.isLoading = false;
      });
    },
  },
});
</script>

<style></style>
