import { ServiceProvider } from "@taskmanagement/taskapp-model";
import {
  Action,
  Module,
  Mutation,
  VuexModule,
  getModule,
} from "vuex-module-decorators";
import store from "..";
import { deleteFromArray } from "../../helpers/component-helper";
import { ServiceProviderService } from "../../services/serviceprovider-service";

@Module({
  name: "serviceProvider",
  store,
  dynamic: true,
})
class ServiceProviderModule extends VuexModule {
  serviceProviderList = Array<ServiceProvider>();
  allServiceProviders = Array<ServiceProvider>();
  serviceProvider = new ServiceProvider() ?? null;

  @Action({ commit: "retrieveSPListMutation" })
  retrieveServiceProviderList() {
    return new ServiceProviderService().getAll();
  }
  @Action({ commit: "retrieveCompleteSPListMutation" })
  retrieveServiceProviderCompleteList() {
    return new ServiceProviderService().getAllWithInActive();
  }

  @Action({ commit: "retrieveSPMutation" })
  retrieveServiceProvider(id: number) {
    return new ServiceProviderService().getById(id);
  }

  @Action({ commit: "createSPMutation" })
  createSP(serviceProvider: ServiceProvider) {
    return new ServiceProviderService().create(serviceProvider).then((val) => {
      if (val) {
        return val;
      }
    });
  }

  @Action({ commit: "updateSPMutation" })
  updateSP(serviceProvider: Partial<ServiceProvider>) {
    return new ServiceProviderService().update(serviceProvider).then((val) => {
      if (val) {
        return serviceProvider;
      }
    });
  }

  @Action({ commit: "deleteSPMutation" })
  deleteSP(serviceProvider: ServiceProvider) {
    return new ServiceProviderService().delete(serviceProvider).then((val) => {
      if (val) {
        return serviceProvider;
      }
      return new ServiceProvider();
    });
  }

  @Mutation
  retrieveSPListMutation(sp: ServiceProvider[]) {
    if (sp) this.serviceProviderList = sp;
  }
  @Mutation
  retrieveCompleteSPListMutation(sp: ServiceProvider[]) {
    if (sp) this.allServiceProviders = sp;
  }

  @Mutation
  retrieveSPMutation(sp: ServiceProvider) {
    if (sp) this.serviceProvider = sp;
  }

  @Mutation
  createSPMutation(sp: ServiceProvider | undefined) {
    if (sp && sp.id) this.serviceProviderList.push(sp);
  }

  @Mutation
  updateSPMutation(sp: Partial<ServiceProvider>) {
    if (sp) {
      sp.id = this.serviceProvider.id;
      this.serviceProvider = {
        ...this.serviceProvider,
        ...sp,
      } as ServiceProvider;
    }
  }

  @Mutation
  deleteSPMutation(sp: ServiceProvider | undefined) {
    if (sp) {
      this.serviceProviderList = deleteFromArray(this.serviceProviderList, sp);
    }
  }
}
export default getModule(ServiceProviderModule);
