import { Allocation, SERVICE_ROUTE } from "@taskmanagement/taskapp-model";
import { BaseService } from "./base-service";

export class OrderServiceService extends BaseService<Allocation> {
  constructor() {
    super(SERVICE_ROUTE);
  }

  getOneByTicketNumber(id: string, ticketNumber: string) {
    return this.axios
      .get(`${SERVICE_ROUTE}/${id}`, {
        params: { ticketNumber },
      })
      .then((res) => res.data as Allocation);
  }
}
