import { TypeStageFormatter } from "@/model";
import { EnumRoles, EnumUserStatus, User } from "@taskmanagement/taskapp-model";

export function getUserRolesWithCount(
  type: TypeStageFormatter<EnumRoles>[],
  userList: User[]
) {
  type.forEach((t) => {
    t.count = 0;
  });

  userList.forEach((user) => {
    const roles = user.role;

    // Check if roles is defined
    if (roles) {
      // Handle case where roles is a single value
      if (!Array.isArray(roles)) {
        const stageType = type.find((typ) => typ.enum === roles);
        if (stageType) {
          stageType.count = (stageType.count ?? 0) + 1;
        }
      } else {
        // Handle case where roles is an array
        roles.forEach((role) => {
          const stageType = type.find((typ) => typ.enum === role);
          if (stageType) {
            stageType.count = (stageType.count ?? 0) + 1;
          }
        });
      }
    }
  });

  return type;
}

export function getUserStatusWithCount(
  type: TypeStageFormatter<EnumUserStatus>[],
  userList: User[]
) {
  // Reset all counts to 0
  type.forEach((t) => {
    t.count = 0;
  });

  // Iterate through the user list
  userList.forEach((user) => {
    const status = user.status;

    // Only proceed if status is defined
    if (status) {
      // Find the matching status in the type array
      const stageType = type.find((typ) => typ.enum === status);
      if (stageType) {
        // Increment the count
        stageType.count = (stageType.count ?? 0) + 1;
      }
    }
  });

  return type;
}
