<template>
  <span>
    <headerCardComponent
      @new="newInvoice"
      :title="$t('object.invoice.invoiceName')"
      :items="invoiceStages"
      :hideBtn="canCreate"
    />

    <v-card
      class="pa-4 transparent align-center"
      tile
      flat
      :max-width="maxWidth"
    >
    </v-card>

    <statusFilterComponent :items="invoiceStages" @change="onFilterTab" />

    <v-card
      flat
      max-height="650"
      class="overflow-y-auto"
      outlined
      style="scrollbar-width: thin"
    >
      <v-data-table
        :items="invoiceList"
        :headers="headers"
        :search="search"
        calculate-widths7
        fixed-header
        :dense="$vuetify.breakpoint.mobile"
        :server-items-length="invoiceList.length"
        @click:row="showMoreItem"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn color="transparent" small fab text @click="showMoreItem(item)">
            <v-icon color="primary"> mdi-dots-horizontal </v-icon>
          </v-btn>
        </template>

        <template v-slot:[`item.alert`]="{ item }">
          <v-tooltip nudge-right="30" top color="error">
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                v-if="formatAlert(item)"
                color="transparent"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="error">mdi-alert</v-icon>
              </v-chip>
            </template>
            {{ $t("object.misc.dueDatePast") }}
          </v-tooltip>
        </template>

        <template v-slot:[`item.stage`]="{ item }">
          <statusDisplayComponent
            :color="getInvoiceStages(item)?.color"
            :text="getInvoiceStages(item)?.value"
          />
        </template>

        <template v-slot:[`item.serviceProvider`]="{ item }">
          {{
            `${item.serviceProvider?.firstName ?? ""} ${
              item.serviceProvider?.lastName ?? ""
            }`
          }}
        </template>
      </v-data-table>
    </v-card>
  </span>
</template>

<script lang="ts">
import {
  formatAmount,
  formatTime,
  headerEnumFormatter,
} from "@/helpers/component-helper";
import { QueryParams, TypeStageFormatter } from "@/model";
import { ROUTE_INVOICEDETAILS } from "@/model/routes";
import authModule from "@/store/modules/auth-module";
import invoiceModule from "@/store/modules/invoice-module";
import {
  EnumInvoiceStage,
  EnumRoles,
  Invoice,
} from "@taskmanagement/taskapp-model/dist";
import moment from "moment";
import Vue from "vue";
import { PropValidator } from "vue/types/options";
import { DataTableHeader } from "vuetify";
import HeaderCardComponent from "../headercard-component.vue";
import StatusDisplayComponent from "../statusdisplay-component.vue";
import StatusFilterComponent from "../statusfilter-component.vue";

export default Vue.extend({
  name: "InvoiceListingComponent",
  data: () => ({
    dateRange: null as string[] | null,
    search: null as string | null,
  }),
  components: {
    statusDisplayComponent: StatusDisplayComponent,
    headerCardComponent: HeaderCardComponent,
    statusFilterComponent: StatusFilterComponent,
  },
  props: {
    maxWidth: { type: Number, default: 1000 },
    invoiceList: {
      type: Array,
      default: new Array<Invoice>(),
    } as PropValidator<Invoice[]>,
    invoiceStages: { type: Array } as PropValidator<
      TypeStageFormatter<EnumInvoiceStage>[]
    >,
  },
  computed: {
    isInvoiced() {
      return (this.invoiceList && this.invoiceList.length) > 0;
    },
    headers() {
      return [
        {
          text: "",
          value: "alert",
          align: "start",
          sortable: false,
          width: 50,
        },
        {
          value: "invoiceNumber",
          text: this.$i18n.t("object.invoice.invNumber"),
        },
        {
          value: "serviceProvider",
          text: this.$i18n.t("object.invoice.issuedTo"),
        },
        {
          value: "invoiceDate",
          text: this.$i18n.t("object.invoice.invoiceDate"),
        },
        {
          value: "paymentDue",
          text: this.$i18n.t("object.invoice.dueDate"),
        },
        {
          value: "stage",
          text: this.$i18n.t("object.allocation.decision"),
        },
        {
          value: "actions",
          text: "",
        },
      ] as DataTableHeader[];
    },
    canCreate() {
      return authModule.role === EnumRoles.AGENT;
    },
  },
  methods: {
    onSearch(text: string | null) {
      this.search = text;
    },
    onFilterTab(stage: EnumInvoiceStage) {
      this.onFilter({ stage });
    },
    onFilter(params: QueryParams<Invoice>) {
      invoiceModule.retrieveAllInvoice(params);
    },
    filterStage(item: EnumInvoiceStage) {
      this.$emit("stageFilter", item);
    },
    newInvoice() {
      this.$emit("newInvoice");
    },
    triggerPreview(item: Invoice) {
      this.$emit("triggerPreview", item);
    },
    formatAmount(item: string) {
      return formatAmount(Number(item), this.$route.params.locale);
    },
    formatDate(item: Date | undefined) {
      if (item) {
        return formatTime(
          moment(item).toISOString(),
          "MMM D, YYYY",
          this.$route.params.locale
        );
      }
      return null;
    },
    getInvoiceStages(item: Invoice) {
      const stage = item.stage;
      return headerEnumFormatter(stage, this.invoiceStages);
    },
    formatAlert(item: Invoice) {
      const invDueByDate = moment().isAfter(moment(item.paymentDue));
      const stage = item.stage ?? EnumInvoiceStage.draft;
      const isCreatedDate =
        stage > EnumInvoiceStage.draft && stage < EnumInvoiceStage.paid;
      return invDueByDate && isCreatedDate;
    },
    showMoreItem(item: Invoice) {
      if (item && item.invoiceNumber) {
        this.$router.push({
          name: ROUTE_INVOICEDETAILS,
          params: {
            locale: this.$route.params.locale,
            invoiceNumber: item.invoiceNumber,
          },
        });
      }
    },
  },
});
</script>

<style></style>
