<template>
  <v-dialog v-model="dialog" width="600">
    <v-card rounded="lg">
      <v-card-title>
        <strong>
          {{ $t("object.dialog.priceDialog.header") }}
        </strong>
      </v-card-title>
      <v-divider />
      <v-container>
        <v-form v-model="formValid">
          <v-row dense>
            <v-col cols="12" md="4">
              <strong>{{ $t("object.cos") }}</strong>
            </v-col>
            <v-col cols="12" md="8">
              <v-text-field
                outlined
                :label="$t('object.task.header.price')"
                v-model="price"
                :hint="$t('object.dialog.numbersOnly')"
                :rules="!priceOptional && formRules.default"
                type="number"
                inputmode="numeric"
                hide-spin-buttons
                dense
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" md="4">
              <strong>{{ $t("object.misc.comment") }}</strong>
            </v-col>
            <v-col cols="12" md="8">
              <v-textarea
                v-model="comment"
                outlined
                :label="$t('object.misc.comment')"
                dense
              />
            </v-col>
          </v-row>
        </v-form>
      </v-container>
      <v-card v-if="price" class="ma-4 pa-2" outlined>
        {{ $t("object.cos") }}: {{ formattedPrice(price) }}
      </v-card>
      <v-divider />

      <v-card-actions class="justify-space-between">
        <v-btn color="primary" large outlined @click="abort">
          {{ $t("site.btn.cancel") }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" large @click="submit" :disabled="!formValid">
          {{ $t("site.btn.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { formatAmount, FormRules, formRules } from "@/helpers/component-helper";
import Vue from "vue";

type DataType = {
  commentValue: string;
  priceValue: null | number;
  formValid: boolean;
};
type ComputedType = {
  dialog: boolean;
  price: string | null;
  formRules: FormRules;
  comment: string;
};
type MethodType = {
  submit(): void;
  abort(): void;
  formattedPrice(price: string): string;
};
type PropType = { value: boolean; priceOptional: boolean };

export default Vue.extend<DataType, MethodType, ComputedType, PropType>({
  name: "DecisionDialogComponent",
  data: () => ({
    priceValue: null,
    commentValue: "",
    formValid: false,
  }),
  props: {
    value: { type: Boolean },
    priceOptional: { type: Boolean },
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    price: {
      get() {
        // Format priceValue as currency for display
        return this.priceValue?.toString() ?? null;
      },
      set(value) {
        this.priceValue = value ? parseInt(value) : null;
        this.$emit("price", this.priceValue);
      },
    },
    comment: {
      get() {
        return this.commentValue ?? "";
      },
      set(value) {
        this.commentValue = value ?? "";
        this.$emit("comment", this.commentValue);
      },
    },
    formRules() {
      return formRules;
    },
  },
  methods: {
    submit() {
      this.$emit("submit");
    },
    abort() {
      this.$emit("abort");
    },
    formattedPrice(price: string) {
      return formatAmount(price, this.$i18n.locale, {
        maximumFractionDigits: 2,
      });
    },
  },
});
</script>
