<template>
  <loginComponent />
</template>

<script lang="ts">
import LoginComponent from "@/components/auth/auth-component.vue";
import Vue from "vue";
export default Vue.extend({
  name: "AuthView",
  components: {
    loginComponent: LoginComponent,
  },
  data: () => ({}),
});
</script>

<style></style>
