<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="8">
        <v-card rounded="xl" :height="height">
          <areaChartComponent
            :series="monthlySeries"
            :categories="labelList"
            :title="$t('object.task.listName')"
          />
        </v-card>
      </v-col>
      <v-col cols="4" v-if="!isMobile">
        <v-container rounded="xl" class="pa-0 fill-height">
          <v-row
            justify="start"
            align="start"
            v-for="(arr, i) in boxOverview"
            :key="i"
          >
            <v-col cols="6" v-for="(box, idx) in arr" :key="idx">
              <v-card rounded="xl" class="fill-height" :height="gridHeight">
                <v-container class="pl-2 d-flex flex-column">
                  <v-card-title>
                    <v-icon class="items-start" color="primary" left large>
                      {{ box.icon }}
                    </v-icon>
                  </v-card-title>
                  <strong class="text-h5 text-start font-weight-bold">
                    {{ box.cardTitle }}
                  </strong>

                  <strong class="text-start">
                    {{ box.cardSubtitle }}
                  </strong>
                </v-container>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>

    <v-card class="mt-6" rounded="xl">
      <v-card-title>
        <strong>{{ $t("object.statistics.ordermgt") }}</strong>
      </v-card-title>
      <v-container>
        <v-data-table :headers="tableHeader" :items="tableItems">
          <template v-slot:[`item.name`]="{ item }">
            {{ getFullName(item) }}
          </template>
          <template v-slot:[`item.totalRevenue`]="{ item }">
            {{ $n(item.totalRevenue) }}
          </template>
          <template v-slot:[`item.totalNetIncome`]="{ item }">
            {{ $n(item.totalNetIncome) }}
          </template>
          <template v-slot:[`item.lastPayAmount`]="{ item }">
            {{ $n(item.lastPayment.price) }}
          </template>
          <template v-slot:[`item.lastPayDate`]="{ item }">
            {{ formatDate(item.lastPayment.date) }}
          </template>
        </v-data-table>
      </v-container>
    </v-card>
  </v-container>
</template>

<script lang="ts">
import { getFullName } from "@/helpers/component-helper";
import { TypeCardOverview } from "@/model";
import statisticsModule from "@/store/modules/statistics-module";
import { Person } from "@taskmanagement/taskapp-model";
import { ApexOptions } from "apexcharts";
import moment from "moment";
import Vue from "vue";
import { DataTableHeader } from "vuetify";
import AreaChartComponent from "../charts/areachart-component.vue";

type ShowBox = Omit<TypeCardOverview, "rowItems">;

export default Vue.extend({
  components: { areaChartComponent: AreaChartComponent },
  name: "StatisticsSummaryComponent",
  data: () => ({
    height: 320,
  }),
  computed: {
    gridHeight() {
      // eslint-disable-next-line prettier/prettier
      return (this.height / 2) - 12;
    },
    labelList() {
      return statisticsModule.summary?.order?.period?.map((x) => x.month);
    },
    statSummary() {
      return statisticsModule.summary;
    },
    boxOverview() {
      const summary = statisticsModule.summary;
      return [
        [
          {
            cardTitle: summary?.order?.totalOrders,
            cardSubtitle: this.$t("object.statistics.totalOrders"),
            icon: "mdi-wrench-outline",
          },
          {
            cardTitle: this.$n(summary?.user?.total ?? 0),
            cardSubtitle: this.$t("object.user.totalUsrs"),
            icon: "mdi-account-group",
          },
        ] as ShowBox[],
        [
          {
            cardTitle: this.$n(summary?.order?.totalNetIncome ?? 0),
            cardSubtitle: this.$t("object.statistics.totalIncome"),
            icon: "mdi-currency-usd-off",
          },
          {
            cardTitle: this.$n(summary?.order?.totalRevenue ?? 0),
            cardSubtitle: this.$t("object.totalRevenue"),
            icon: "mdi-cash-multiple",
          },
        ] as ShowBox[],
      ] as ShowBox[][];
    },
    tableHeader() {
      return [
        { text: this.$t("object.serviceProvider.name"), value: "name" },
        {
          text: this.$t("object.statistics.totalOrders"),
          value: "totalOrders",
        },
        {
          text: this.$t("object.order.totalCompleted"),
          value: "totalCompletedOrder",
        },
        { text: this.$t("object.order.totalRevenue"), value: "totalRevenue" },
        { text: this.$t("object.totalNetIncm"), value: "totalNetIncome" },
        { text: this.$t("object.order.lastPaidAmt"), value: "lastPayAmount" },
        { text: this.$t("object.order.lastPmtDate"), value: "lastPayDate" },
      ] as DataTableHeader[];
    },
    tableItems() {
      return statisticsModule.summary?.serviceProvider;
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    monthlySeries() {
      const summary = statisticsModule.summary.order?.period;
      if (!summary) return [];
      return [
        {
          name: this.$t("object.statistics.totalOrders"),
          data: summary?.map((xx) => ({ x: xx.month, y: xx.totalOrders })),
        },
        {
          name: this.$t("object.statistics.completedOrders"),
          data: summary?.map((xx) => ({
            x: xx.month,
            y: xx.totalCompletedOrder,
          })),
        },
      ] as ApexOptions["series"];
    },
  },
  methods: {
    getFullName(sp: Person) {
      return getFullName(sp);
    },
    formatDate(date: Date | string | undefined) {
      if (!date) return "";
      return moment(date).format("DD-MM-YYYY HH:mm");
    },
  },
});
</script>

<style></style>
