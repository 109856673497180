<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="9">
        <v-card outlined>
          <detailoverview-component
            :value="items"
            :ID="invoice.invoiceNumber"
            :title="'Invoice Data'"
          >
            <template v-slot:top>
              <v-card
                flat
                outlined
                class="px-2 d-flex justify-space-between align-center"
              >
                <statusdisplay-component
                  :color="stageFormat?.color"
                  :text="stageFormat?.value"
                  outlined
                />

                <v-card-actions class="pa-4 d-flex justify-space-between">
                  <v-tooltip bottom v-if="editInStage">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="mx-1"
                        fab
                        v-bind="attrs"
                        small
                        v-on="on"
                        @click="edit"
                      >
                        <v-icon color="primary">mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t("object.label.edit") }}</span>
                  </v-tooltip>

                  <v-tooltip bottom v-if="safeStage">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="mx-1"
                        fab
                        small
                        v-bind="attrs"
                        v-on="on"
                        @click="saveOnly"
                      >
                        <v-icon color="primary">mdi-content-save</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t("site.btn.save") }}</span>
                  </v-tooltip>

                  <v-tooltip bottom v-if="invoice.stage !== stages.draft">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="mx-1"
                        fab
                        small
                        v-bind="attrs"
                        v-on="on"
                        @click="print"
                      >
                        <v-icon color="primary">mdi-printer</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t("object.invoice.btn.print") }}</span>
                  </v-tooltip>

                  <v-tooltip bottom v-if="invoice.stage == stages.draft">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="mx-1"
                        fab
                        small
                        v-bind="attrs"
                        v-on="on"
                        @click="sendDoc"
                      >
                        <v-icon color="primary">fa-paper-plane</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t("object.invoice.btn.svNSnd") }}</span>
                  </v-tooltip>

                  <v-tooltip bottom v-if="invoice.stage == stages.send">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="mx-1"
                        fab
                        small
                        v-bind="attrs"
                        v-on="on"
                        @click="markAsPaid"
                      >
                        <v-icon color="primary">mdi-cash-check</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t("object.invoice.btn.markPaid") }}</span>
                  </v-tooltip>

                  <v-tooltip bottom v-if="invoice.stage == stages.save">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="mx-1"
                        fab
                        small
                        v-bind="attrs"
                        v-on="on"
                        @click="sendDoc"
                      >
                        <v-icon color="primary">fa-paper-plane</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t("object.invoice.btn.send") }}</span>
                  </v-tooltip>
                </v-card-actions>
              </v-card>
            </template>
            <template v-slot:bottom>
              <v-card outlined class="text-start pa-2">
                <strong>{{ $t("object.label.orderList") }}</strong>
                <v-divider />

                <v-row dense>
                  <v-col
                    cols="12"
                    md="4"
                    v-for="(itm, i) in invoice.tasks"
                    :key="i"
                  >
                    <v-card flat outlined tile class="pa-1">
                      <v-card-text class="pa-0">
                        <v-icon small color="primary">mdi-tag-outline</v-icon>
                        <strong class="primary--text">
                          {{ itm.ticketNumber }} </strong
                        >|
                        {{ getFullName(itm) }}
                      </v-card-text>
                      <v-card-text class="py-0">
                        {{ getFullName(itm) }}
                      </v-card-text>
                      <v-card-text class="py-0">
                        <v-icon small color="primary">mdi-cash</v-icon>
                        <strong> €{{ itm.invoicedAmount }}</strong> | €{{
                          itm.price
                        }}
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card>
            </template>
          </detailoverview-component>
        </v-card>
      </v-col>
    </v-row>
    <InvoicepdflayoutComponent
      v-model="invoice"
      v-show="false"
      class="fill-width my-2"
      ref="docpdf"
    />
  </v-container>
</template>

<script lang="ts">
import {
  formatAmount,
  getFullName,
  gotoPathQuery,
} from "@/helpers/component-helper";
import {
  ComponentPDF,
  createInvoiceObj,
  markInvoiceAsPaid,
  sendInvoice,
} from "@/helpers/invoice-helper";
import { TypeCardOverview } from "@/model";
import formatterModule from "@/store/modules/formatter-module";
import invoiceModule from "@/store/modules/invoice-module";
import { EnumInvoiceStage, Person, Task } from "@taskmanagement/taskapp-model";
import Vue from "vue";
import detailoverviewComponent from "../detail/detailoverview-component.vue";
import StatusdisplayComponent from "../statusdisplay-component.vue";
import InvoicepdflayoutComponent from "./invoicepdflayout-component.vue";

export default Vue.extend({
  name: "InvoiceDetailComponent",
  components: {
    detailoverviewComponent,
    StatusdisplayComponent,
    InvoicepdflayoutComponent,
  },
  data: () => ({
    stages: EnumInvoiceStage,
  }),
  computed: {
    invoice() {
      return invoiceModule.invoice;
    },
    items() {
      const data = invoiceModule.invoice;
      const technician = data?.serviceProvider;
      return [
        {
          cardTitle: this.$t("object.misc.detailsName"),
          rowItems: [
            {
              icon: "mdi-clock-outline ",
              headerText: this.$t("object.invoice.invoiceDate"),
              title: data.invoiceDate ?? null,
            },
            {
              icon: "mdi-clock-end",
              headerText: this.$t("object.invoice.paymentDue"),
              title: data.paymentDue ?? null,
            },
          ],
        },
        {
          cardTitle: this.$t("object.misc.detailsName"),
          rowItems: [
            {
              icon: "mdi-account-hard-hat-outline",
              headerText: this.$t("object.misc.technician"),
              title: data.serviceProvider?.fullName ?? null,
              subTitle: technician?.address?.fullAddress,
            },
            {
              icon: "mdi-cash-multiple",
              headerText: this.$t("object.invoice.invoiceAmount"),
              title: data.amount ? formatAmount(data?.amount) : null,
            },
          ],
        },
      ] as TypeCardOverview[];
    },
    editInStage() {
      const invoice = invoiceModule.invoice;
      if (invoice && invoice.stage) {
        return invoice.stage < this.stages.send;
      }
      return true;
    },
    safeStage() {
      const invoice = invoiceModule.invoice;
      if (invoice && invoice.stage) {
        return invoice.stage < this.stages.save;
      }
      return false;
    },
    stageFormat() {
      return (
        formatterModule.invoiceStages.find(
          (x) => invoiceModule.invoice.stage === x.enum
        ) ?? null
      );
    },
  },
  methods: {
    amountFormat(price: string | number) {
      return price ? formatAmount(price) : null;
    },
    edit() {
      gotoPathQuery(this.$route, this.$router, { mode: "edit" });
      this.$emit("editInvoice", this.invoice);
    },
    getFullName(itm: Task) {
      const person: Person = {
        address: {},
      };
      person.firstName = itm.requesterFirstName;
      person.lastName = itm.requesterLastName;
      return getFullName(person);
    },
    markAsPaid() {
      // this.loading = true;
      markInvoiceAsPaid(this.invoice, () => {
        // this.loading = false;
        this.$emit("paid");
      });
    },
    sendDoc() {
      const component = this.$refs?.docpdf as Vue;
      this.invoice.file = component?.$el?.innerHTML;
      // this.loading = true;
      sendInvoice(this.invoice, () => {
        this.$emit("send");
        // this.loading = false;
      });
    },
    print() {
      this.$store.state.isLocalLoading = true;
      const component = this.$refs?.docpdf as Vue;
      new ComponentPDF(component).printPDF();
      // .finally(() => (this.loading = false));
    },
    saveOnly() {
      // this.loading = true;
      createInvoiceObj(this.invoice, () => {
        // this.loading = false;
        this.$emit("save");
      });
    },
  },
});
</script>

<style></style>
