import {
  Allocation,
  EnumStatus,
  EnumTaskStage,
} from "@taskmanagement/taskapp-model";
import spdecisionModule from "../store/modules/spdecision-module";

type QueryParam = { ticketNumber: string; id: string };
export function getAllocation(obj: QueryParam) {
  return spdecisionModule.retrieveAllocation(obj);
}

export function loadParams(
  ticketNumber: string,
  id: string,
  badRequest: () => void
) {
  return getAllocation({ ticketNumber, id })
    .then((val) => {
      if (!val) {
        badRequest();
      }
    })
    .catch((err) => {
      if (err) {
        badRequest();
      }
    });
}

export function setAcceptIdentity(allocation: Allocation) {
  const allocate = allocation;
  allocate.stage = EnumTaskStage.viewed;
  return spdecisionModule.updateAction(allocate);
}

export function setDeclineIdentity(
  allocation: Allocation,
  triggerFinish: () => void
) {
  allocation.stage = EnumTaskStage.declined;
  allocation.decision = EnumStatus.badRequest;
  return spdecisionModule.updateAction(allocation).finally(triggerFinish);
}

export function setAcceptTask(allocation: Allocation) {
  allocation.decision = EnumStatus.accepted;
  allocation.stage = EnumTaskStage.accepted;
  return spdecisionModule.updateAction(allocation);
}

export function setDeclineTask(
  allocation: Allocation,
  triggerFinish?: () => void
) {
  allocation.decision = EnumStatus.declined;
  return spdecisionModule.updateAction(allocation).finally(triggerFinish);
}

export function finishTask(allocation: Allocation, triggerFinish?: () => void) {
  allocation.stage = EnumTaskStage.done;
  allocation.task.stage = EnumTaskStage.done;
  return spdecisionModule.updateAction(allocation).then(triggerFinish);
}

export function unableToFinish(
  allocation: Allocation,
  triggerFinish?: () => void
) {
  allocation.decision = EnumStatus.declined;
  return spdecisionModule.updateAction(allocation).finally(triggerFinish);
}
