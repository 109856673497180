import { ServiceProvider } from "@taskmanagement/taskapp-model";
import {
  Identification,
  Person,
} from "@taskmanagement/taskapp-model/dist/model/base-model";
import { TranslateResult } from "vue-i18n";

export type LocalPerson = Person & Omit<ServiceProvider, keyof Person>;
export type TypeRequest = "success" | "badRequest";

/**
 * @param T enum type
 */
export type TypeStageFormatter<T = Record<string, number>> = {
  enum: T;
  icon?: string;
  count?: number;
  description?: string | TranslateResult;
  backgroundColor?: string;
  value: TranslateResult;
  color?: string;
};

export type QueryParams<T> = Partial<T> & {
  maxDate?: string;
  minDate?: string;
};

export type InformationType<T = string | null> = {
  icon?: T;
  iconColor?: T;
  title: TranslateResult | T;
  value?: T | null;
  condition?: boolean;
} & Identification;

export type InfoCardType<T = string | null> = {
  title: T;
  subtitle: T;
  type?: string | number;
  click(): void;
  items: InformationType[];
};

export type DefaultVueType = Record<string, string>;

export enum EnumOrgForm {
  DETAIL = "detail",
  TAX = "tax",
  BANK = "bank",
  ADDRESS = "address",
}

export type TypeCardOverview<T = string | TranslateResult> = {
  cardTitle: T;
  cardSubtitle?: T;
  icon?: string;
  editBtn?: boolean;
  rowItems: {
    icon: string;
    title: string | null;
    subTitle: string | null;
    headerText: T;
  }[];
};
